import React, { Suspense, useEffect, useLayoutEffect, useMemo } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useIdleTimer } from 'react-idle-timer';

import { Layout } from 'shared_components/src/components/layout';
import { Loading, NotMatch, NotificationBar } from 'shared_components/src/components/common';
import { clearGeneralCookies, setCookie, getCookie } from 'shared_components/src/service/common.service';
import AuthService from '../../service/auth.service';
import {
	GENERAL_COOKIES,
	AUTH_ROLE,
	NOTIFICATION_STATES,
	CONFIGURATION_COOKIES,
	APP_URL_PREFIX,
} from 'shared_components/src/common/constants';
import { TENANT_STATUS } from 'shared_components/src/service/models/tenant';

import { LOGIN_USER_TYPES } from '../constants';
import {
	_getMenuKey,
	_getLoadingState,
	_getIsLoggedIn,
	_getAuthInfo,
	_getAuthRoles,
	_getNotification,
	_getPlan,
	_getTenant,
} from '../../store/selectors';
import { logoutAuthUser, updateAuthUser } from '../../store/auth/actions';
import { setTenantAlias, setTenant, setPlan, setCountriesList } from '../../store/tenant/actions';
import { setProduct } from '../../store/product/actions';
import { setSettings, resetSettings } from '../../store/common/actions';
import { IAuthInfo } from '../../service/models/auth';
import kycApiService from '../../service/kycApi.service';
import { clearLoading, setLoading, setNotification } from '../../store/common/actions';
import routes from './routes';
import { DESTROY_SESSION } from '../../store/common/actionTypes';
import { getIdleTimeCountFromConfiguration } from 'shared_components/src/common/utils';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface CountryType {
	code: string;
	code2: string;
	name: string;
}

const Routers = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const menuKey = _getMenuKey();
	const loadings = _getLoadingState();
	const notification = _getNotification();
	const isLoggedIn = _getIsLoggedIn();
	const authRoles = _getAuthRoles();
	const authInfo = _getAuthInfo();
	const plan = _getPlan();
	const tenant = _getTenant();
	const featureFlags = useFlags();
	const userEmail = authInfo && JSON.parse(authInfo).email;
	const curToken = getCookie(GENERAL_COOKIES.token);
	const tenantAlias = getCookie(GENERAL_COOKIES.userTenant);
	const curPath = window.location.pathname;
	const roles = getCookie(GENERAL_COOKIES.userRoles);

	const showTenantData = useMemo(() => {
		return roles.split(',').includes(AUTH_ROLE.client) && featureFlags['Onboarding-client-v1'];
	}, [roles, featureFlags]);

	const showViewPlan = useMemo(() => {
		return showTenantData;
	}, [showTenantData]);

	const showChangePlan = false;
	// useMemo(() => {
	// 	return showTenantData && tenant?.status !== TENANT_STATUS.CANCELLED;
	// }, [showTenantData, tenant]);

	const { idleTimeCount, hasConfiguration } = useMemo(() => {
		const _idleTimecCount = getIdleTimeCountFromConfiguration();
		const tenant = getCookie(CONFIGURATION_COOKIES.tenant);

		return {
			idleTimeCount: _idleTimecCount,
			hasConfiguration: tenant ? true : false,
		};
	}, []);

	useEffect(() => {
		switch (curPath) {
			case APP_URL_PREFIX:
			case `${APP_URL_PREFIX}/`:
				clearSignInfo();
				break;

			case `${APP_URL_PREFIX}/login`:
				break;

			default:
				break;
		}
	}, [curPath]);

	const handleOnIdle = () => {
		// Clear Sign in info for pages other than relaunch
		if (!curPath.includes('launch')) {
			handleForceSignOut();
		}
	};

	useLayoutEffect(() => {
		checkToken();
	}, []);

	useIdleTimer({
		timeout: 1000 * 60 * idleTimeCount,
		onIdle: handleOnIdle,
		debounce: 300,
	});

	const checkToken = () => {
		if (curToken) {
			if (!isLoggedIn) {
				const loggedIn = getCookie(GENERAL_COOKIES.isLoggedIn);
				const _authInfo: IAuthInfo = {
					isLoggedin: loggedIn === 'true',
					token: getCookie(GENERAL_COOKIES.token),
					role: getCookie(GENERAL_COOKIES.userRole) as AUTH_ROLE,
					firstName: getCookie(GENERAL_COOKIES.userFirstName),
					lastName: getCookie(GENERAL_COOKIES.userLastName),
					info: getCookie(GENERAL_COOKIES.userInfo),
					session: getCookie(GENERAL_COOKIES.userSession),
					roles: getCookie(GENERAL_COOKIES.userRoles),
					tenant: getCookie(GENERAL_COOKIES.userTenant),
				};

				dispatch(updateAuthUser(_authInfo));
				dispatch(setTenantAlias(_authInfo.tenant));
				kycApiService.init(_authInfo.token);

				if (_authInfo.session) {
					const sessionInfo = JSON.parse(_authInfo.session);
					setExpiring(sessionInfo.payload.exp);
				}
			}
		}
		// else {
		// 	refreshToken();
		// }
	};

	const setExpiring = (expireIn: number) => {
		const delta = expireIn * 1000 - new Date().getTime();

		if (delta > 0) {
			setTimeout(() => {
				refreshToken();
			}, delta);
		} else {
			refreshToken();
		}
	};

	const clearSignInfo = () => {
		navigate('/login');
		clearGeneralCookies();
		dispatch(logoutAuthUser());
		dispatch({ type: DESTROY_SESSION });
	};

	const refreshToken = () => {
		AuthService.currentSession()
			.then((res: any) => {
				if (res) {
					const userInfo: IAuthInfo = setAuthInfo(res);

					if (checkUser(userInfo.roles)) {
						setCookies(userInfo);

						dispatch(updateAuthUser(userInfo));
						kycApiService.init(userInfo.token);

						setExpiring(res.idToken.payload.exp);
					} else {
						handleForceSignOut();
					}
				} else {
					handleForceSignOut();
				}
			})
			.catch((err) => {
				handleForceSignOut();
			});
	};

	const setAuthInfo = (auth: any) => {
		return {
			isLoggedin: true,
			token: auth?.idToken?.jwtToken,
			role: auth?.idToken?.payload['cognito:groups'][0],
			firstName: auth?.idToken?.payload?.given_name ?? '',
			lastName: auth?.idToken?.payload?.family_name ?? '',
			info: JSON.stringify(auth?.idToken?.payload ?? ''),
			session: JSON.stringify(auth.idToken),
			roles: auth?.idToken?.payload['custom:roles'],
			tenant: auth?.idToken?.payload['custom:tenant'],
		};
	};

	const setCookies = (authSessionInfo: IAuthInfo) => {
		setCookie(GENERAL_COOKIES.isLoggedIn, authSessionInfo.isLoggedin.toString(), 1);
		setCookie(GENERAL_COOKIES.token, authSessionInfo.token ?? '', 1);
		setCookie(GENERAL_COOKIES.userRole, authSessionInfo.role ?? 0, 1);
		setCookie(GENERAL_COOKIES.userFirstName, authSessionInfo.firstName ?? '', 1);
		setCookie(GENERAL_COOKIES.userLastName, authSessionInfo.lastName ?? '', 1);
		setCookie(GENERAL_COOKIES.userInfo, authSessionInfo.info ?? '', 1);
		setCookie(GENERAL_COOKIES.userSession, authSessionInfo.session ?? '', 1);
		setCookie(GENERAL_COOKIES.userRoles, authSessionInfo.roles ?? '', 1);
		setCookie(GENERAL_COOKIES.userTenant, authSessionInfo.tenant ?? '', 1);
	};

	const checkUser = (roles: string) => {
		const arrRoles = roles?.split(',') || [];

		let hasPermission = false;

		arrRoles.forEach((role) => {
			if (LOGIN_USER_TYPES.includes(role)) {
				hasPermission = true;
			}
		});

		return hasPermission;
	};

	const handleSignOut = () => {
		if (isLoggedIn) {
			AuthService.signOut()
				.then((res) => {
					clearSignInfo();
				})
				.catch((err) => handleSignOutError(err));
		} else {
			clearSignInfo();
		}
	};

	const handleForceSignOut = () => {
		AuthService.signOut()
			.then((res) => {
				clearSignInfo();
			})
			.catch((err) => handleSignOutError(err));
	};

	const handleSignOutError = (err: any) => {
		console.log('Sign Out Error', err);
		clearSignInfo();
		localStorage.clear();
		sessionStorage.clear();
	};

	const handleNotificationClose = () => {
		dispatch(setNotification({ message: '', type: NOTIFICATION_STATES.info }));
	};

	const handleReturn = () => {
		if (isLoggedIn) {
			navigate(`/tenant/${tenantAlias}/product-overview`);
		} else {
			navigate('/login');
		}
	};

	useEffect(() => {
		if (curToken && tenantAlias) {
			getProduct();
			getSettings();
			getTenant();
			getCountries();
		}
	}, [curToken, tenantAlias]);

	const getCountries = () => {
		dispatch(setLoading());
		return new Promise((resolve) => {
			kycApiService
				.getCountries()
				.then((res: any) => {
					if (res?.items?.length > 0) {
						const _listCountries: CountryType[] = res?.items?.map((item: any, index: number) => {
							return {
								name: item.name,
								code: item.alpha3Code,
								code2: item.alpha2Code,
							};
						});
						_listCountries.sort((a: any, b: any) => (a.name < b.name ? -1 : 1));
						dispatch(setCountriesList(_listCountries));
					}
				})
				.catch((err: any) => {
					console.log('country err => ', err);
				})
				.finally(() => {
					dispatch(clearLoading());
					return resolve({ status: 'success' });
				});
		});
	};

	const getProduct = () => {
		dispatch(setLoading());
		kycApiService
			.getProductDetail('KYC')
			.then((res) => {
				dispatch(setProduct(res));
			})
			.catch((err) => {
				console.log('errr=> ', err);
			})
			.finally(() => {
				dispatch(clearLoading());
			});
	};

	const getSettings = () => {
		dispatch(setLoading());
		kycApiService
			.getSettings()
			.then((res) => {
				const payload = {
					...res?.features,
				};
				dispatch(setSettings(payload));
			})
			.catch((err) => {
				dispatch(resetSettings());
			})
			.finally(() => {
				dispatch(clearLoading());
			});
	};

	const getTenant = () => {
		dispatch(setLoading());
		kycApiService
			.getTenantInfo(tenantAlias)
			.then((res) => {
				dispatch(setTenant(res));
				if (showViewPlan) {
					getPlanDetails(res?.product?.code, res?.product?.plan);
				}
			})
			.catch((err) => {
				console.log('tenant err => ', err);
			})
			.finally(() => {
				dispatch(clearLoading());
			});
	};

	const getPlanDetails = (productCode: string, planCode: string) => {
		dispatch(setLoading());
		return new Promise((resolve) => {
			kycApiService
				.getTenantPlanInfo(productCode, planCode)
				.then((res: any) => {
					if (!res.error) {
						dispatch(setPlan(res));
					}
				})
				.catch((err: any) => {
					console.log('get PlanDetails err', err);
				})
				.finally(() => {
					dispatch(clearLoading());
					return resolve({ status: 'success' });
				});
		});
	};

	const handleViewPlan = () => {
		const rootPath = `tenant/${tenantAlias}`;
		navigate(`/${rootPath}/plan`);
	};

	const handleChangePlan = () => {
		const rootPath = `tenant/${tenantAlias}/plan`;
		navigate(`/${rootPath}/change-plan`);
	};

	const showComp = (role, hasFlag, flag) => {
		const roles = authRoles.split(',');
		const hasAccess = role ? role.find((_role) => roles.includes(_role)) || false : false;
		if (hasFlag) {
			return hasAccess && featureFlags[flag];
		}
		return hasAccess;
	};

	const handleLogoAction = () => {
		navigate(`/tenant/${tenantAlias}/product-overview`);
	};

	return (
		<Suspense fallback={<Loading open={true} />}>
			<Loading open={loadings > 0} />
			<NotificationBar
				message={notification.message}
				type={notification.type}
				onClose={handleNotificationClose}
			/>
			<Routes>
				{routes.map((route, i) => {
					const { path, component: Component, hasAuth, exact, roles, hasFlag, flag } = route;
					return (
						<Route
							key={i}
							path={path}
							element={
								hasAuth ? (
									curToken ? (
										showComp(roles, hasFlag, flag) ? (
											<Layout
												handleSignOut={handleSignOut}
												handleViewPlan={handleViewPlan}
												handleChangePlan={handleChangePlan}
												menu={menuKey}
												role={authRoles}
												email={userEmail}
												showSubMenu={true}
												showViewPlanButton={showViewPlan}
												showChangePlanButton={showChangePlan}
												portal={'CLIENT'}
												plan={plan || {}}
												productCode={tenant?.product?.code}
												featureFlags={featureFlags}
												handleLogoAction={handleLogoAction}
											>
												<Component />
											</Layout>
										) : (
											<NotMatch
												hasConfiguration={hasConfiguration}
												handleReturn={handleReturn}
											/>
										)
									) : (
										<Navigate to='/login' />
									)
								) : path === '/login' && curToken ? (
									<Navigate to={`/tenant/${tenantAlias}/product-overview`} />
								) : (
									<Component />
								)
							}
						/>
					);
				})}
				<Route
					path='*'
					element={<NotMatch hasConfiguration={hasConfiguration} handleReturn={handleReturn} />}
				/>
			</Routes>
		</Suspense>
	);
};

export default Routers;
