import React, { useState, useEffect } from 'react';
import { Box, IconButton, Menu, MenuItem, MenuProps, Skeleton, Typography, Theme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { isEmpty } from 'lodash';
import moment from 'moment';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { DeleteIcon, PassIcon, FailIcon, WarningIconV2 } from 'shared_components/src/common/icons';
import {
	NEW,
	REPORT_PENDING,
	PROCESSING,
	IN_PROGRESS,
	VIEW_DETAIL,
	CONTACT_SUPPORT,
	DOWNLOAD_REPORTS,
	RESEND_EMAIL,
} from 'shared_components/src/components/verification/icons';

import CopyToClipboard from 'shared_components/src/components/verification/CopyToClipboard';
import { _getTenantAlias } from '../../store/selectors';

import VerificationList from 'shared_components/src/components/verification/VerificationList';

const StyledMenu = styled((props: MenuProps) => (
	<Menu
		elevation={0}
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'right',
		}}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'right',
		}}
		{...props}
	/>
))(({ theme }) => ({
	'& .MuiPaper-root': {
		borderRadius: 6,
		marginTop: theme.spacing(1),
		width: 240,
		color: '#470D82',
		border: '1px solid #E1E1EA',
		boxShadow: '0px 4px 4px #0020330A',

		'& .MuiMenu-list': {
			padding: '4px 0',
		},
		'& .MuiMenuItem-root': {
			fontSize: '16px',
			lineHeight: '20px',
			fontWeight: '400',
			'& svg': {
				marginRight: '14px',
			},
		},
	},
}));

interface IProps {
	data: any;
	order?: any;
	orderBy?: string;
	showEmptyTable?: boolean;
	handleView?: any;
	handleDownloadReport?: any;
	handleDelete?: any;
	handleResendInvite?: any;
	handleContactSupport?: any;
	sortVerifications?: any;
	setOrder?: any;
}

const VerificationListPage = ({
	data,
	order,
	orderBy,
	sortVerifications,
	setOrder,
	showEmptyTable,
	handleView,
	handleDownloadReport,
	handleDelete,
	handleResendInvite,
	handleContactSupport,
}: IProps) => {
	const [loading, setLoading] = useState(true);
	const [listData, setListData] = useState([] as any);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [actions, setAction] = useState([] as any);

	const tenantAlis = _getTenantAlias();

	useEffect(() => {
		prepareData();
	}, [data]);

	const prepareData = () => {
		if (!isEmpty(data)) {
			const items = data.map((el, i) => ({
				email: el.inviteeDetails.email || el.inviteeDetails.mobileNumber,
				verificationId: el.verificationId,
				name: [el.inviteeDetails.givenName, el.inviteeDetails.familyName],
				status: el.status,
				lastModified: moment(el.updatedAt).fromNow(), //el.updatedAt
				agentName: [el.agentDetails.givenName, el.agentDetails.familyName],
				externalRefId: el.externalRefId,
				proofingResults: el.results
					? el.results.proofingOutcomes.map((proofing) => ({
							proofingStatus: proofing.proofingStatus,
							...proofing.proofingTag,
					  }))
					: [],
				otherCheckOutcomes: el.results ? el.results.otherCheckOutcomes : [],
				actions: actionItems[el.status].map((item) => ({
					...item,
					id: el.verificationId,
				})),
			}));
			setListData(items);
			setLoading(false);
		}
	};

	const headerData = [
		{
			label: 'EMAIL / Mob no',
			id: 'EMAIL',
			sortable: true,
			size: {
				//xxlSize: '235px',
				xxlSize: '20%',
				// xlSize: '200px',
				xlSize: '18%',
				lgSize: '22%',
				mdSize: '200px',
				smSize: '200px',
			},
			shouldHaveMinWidth: true,
		},
		{
			label: 'NAME',
			id: 'NAME',
			sortable: true,
			size: {
				//xxlSize: '184px',
				xxlSize: '12%',
				//xlSize: '126px',
				xlSize: '11%',
				lgSize: '14.5%',
				mdSize: '126px',
				smSize: '126px',
			},
			shouldHaveMinWidth: true,
		},
		{
			label: 'VERIFICATION',
			id: 'VERIFICATION',
			size: {
				//xxlSize: '200px',
				xxlSize: '12.5%',
				// xlSize: '176px',
				xlSize: '16%',
				lgSize: '20%',
				mdSize: '165px',
				smSize: '165px',
			},
			shouldHaveMinWidth: true,
		},
		{
			label: 'LAST MODIFIED',
			id: 'LAST_MODIFIED',
			sortable: true,
			size: {
				//xxlSize: '184px',
				xxlSize: '11.5%',
				// xlSize: '130px',
				xlSize: '13%',
				lgSize: '17.5%',
				mdSize: '130px',
				smSize: '130px',
			},
			shouldHaveMinWidth: true,
		},
		{
			label: 'AGENT',
			id: 'AGENT',
			sortable: true,
			size: {
				//xxlSize: '184px',
				xxlSize: '11.5%',
				// xlSize: '100px',
				xlSize: '9%',
				lgSize: '16%',
				mdSize: '100px',
				smSize: '100px',
			},
			disappearanceBreakPoint: {
				xxl: false,
				xl: false,
				lg: true,
				md: false,
				sm: false,
				xs: false,
			},
			shouldHaveMinWidth: true,
		},
		{
			label: 'CUSTOMER REF',
			id: 'CUSTOMER_REF',
			size: {
				//xxlSize: '184px',
				xxlSize: '11.5%',
				// xlSize: '130px',
				xlSize: '11%',
				lgSize: '16%',
				mdSize: '130px',
				smSize: '130px',
			},
			disappearanceBreakPoint: {
				xxl: false,
				xl: false,
				lg: true,
				md: true,
				sm: false,
				xs: false,
			},
			shouldHaveMinWidth: true,
		},
		{
			label: 'RESULT',
			id: 'PROOFING_RESULT',
			size: {
				//xxlSize: '250px',
				xxlSize: '16%',
				//xlSize: '215px',
				xlSize: '20%',
				lgSize: '24%',
				mdSize: '215px',
				smSize: '215px',
			},
			shouldHaveMinWidth: true,
		},
		{
			label: '',
			id: '',
			size: {
				xxlSize: '50px',
				xlSize: '50px',
				lgSize: '50px',
				mdSize: '50px',
				smSize: '50px',
			},
			shouldHaveMinWidth: true,
		},
	];

	const renaderIcon = (type) => {
		switch (type) {
			case 'NEW':
				return <NEW />;
				break;
			case 'DONE':
				return <PassIcon width={16} height={16} />;
				break;
			case 'COMPLETE_REPORTS_PENDING':
				return <REPORT_PENDING />;
				break;
			case 'PROCESSING':
				return <PROCESSING />;
				break;
			case 'IN_PROGRESS':
				return <IN_PROGRESS />;
				break;
			case 'PASS':
				return <PassIcon width={16} height={16} />;
				break;
			case 'FAIL':
				return <FailIcon width={16} height={16} />;
				break;
			case 'WARNING':
				return <WarningIconV2 width={16} height={16} />;
				break;
			case 'DELETE':
				return <DeleteIcon width={16} height={16} fill={'#470D82'} />;
				break;
			case 'VIEW_DETAIL':
				return <VIEW_DETAIL />;
				break;
			case 'CONTACT_SUPPORT':
				return <CONTACT_SUPPORT />;
				break;
			case 'RESEND_EMAIL':
				return <RESEND_EMAIL />;
				break;
			case 'DOWNLOAD_REPORTS':
				return <DOWNLOAD_REPORTS />;
				break;
			default:
				break;
		}
	};

	const actionItems = {
		DONE: [
			{
				label: 'View Detail',
				key: 'VIEW_DETAIL',
				icon: renaderIcon('VIEW_DETAIL'),
			},
			// { label: 'Download Reports', key: 'DOWNLOAD_REPORTS', icon: renaderIcon('DOWNLOAD_REPORTS') },
			{ label: 'Delete', key: 'DELETE', icon: renaderIcon('DELETE') },
		],
		PROCESSING: [
			// { label: 'Contact Support', key: 'CONTACT_SUPPORT', icon: renaderIcon('CONTACT_SUPPORT') },
			{ label: 'Delete', key: 'DELETE', icon: renaderIcon('DELETE') },
		],
		COMPLETE_REPORTS_PENDING: [
			{
				label: 'View Detail',
				key: 'VIEW_DETAIL',
				icon: renaderIcon('VIEW_DETAIL'),
			},
			{ label: 'Delete', key: 'DELETE', icon: renaderIcon('DELETE') },
		],
		NEW: [
			{
				label: 'Resend Email',
				key: 'RESEND_EMAIL',
				icon: renaderIcon('RESEND_EMAIL'),
			},
			// { label: 'Contact Support', key: 'CONTACT_SUPPORT', icon: renaderIcon('CONTACT_SUPPORT') },
			{ label: 'Delete', key: 'DELETE', icon: renaderIcon('DELETE') },
		],
		IN_PROGRESS: [
			{
				label: 'Resend Email',
				key: 'RESEND_EMAIL',
				icon: renaderIcon('RESEND_EMAIL'),
			},
			// { label: 'Contact Support', key: 'CONTACT_SUPPORT', icon: renaderIcon('CONTACT_SUPPORT') },
			{ label: 'Delete', key: 'DELETE', icon: renaderIcon('DELETE') },
		],
	};

	function StyledVerificationIDContainer(theme: Theme) {
		return {
			display: 'flex',
			alignItems: 'center',
		};
	}

	const statusLabel = (status) => {
		switch (status) {
			case 'NEW':
				return 'New';
				break;
			case 'DONE':
				return 'Done';
				break;
			case 'COMPLETE_REPORTS_PENDING':
				return 'Report Pending';
				break;
			case 'PROCESSING':
				return 'Processing';
				break;
			case 'IN_PROGRESS':
				return 'In Progress';
				break;
			case 'DELETE':
				return 'Delete';
				break;
			case 'ERROR':
				return 'Error';
				break;
			default:
				break;
		}
	};

	const getOtherChecksStatus = (otherCheckOutcomes) => {
		if (otherCheckOutcomes.some((check) => check.status === 'FAIL')) return 'FAIL';
		if (otherCheckOutcomes.some((check) => check.status === 'WARNING')) return 'WARNING';
		if (otherCheckOutcomes.every((check) => check.status === 'PASS')) return 'PASS';
		return 'PASS'; // Default to PASS if array is empty
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	const handleAnchorElClick = (id, status, event: React.MouseEvent<HTMLButtonElement>) => {
		event.persist();
		event.preventDefault();
		setAction(
			actionItems[status].map((item) => ({
				...item,
				id,
			}))
		);
		setAnchorEl(event.currentTarget);
	};

	const createSortHandler = (property: string, sortable: boolean) => (event: React.MouseEvent<unknown>) => {
		if (sortable) {
			const isInc = orderBy === property && order === 'inc';
			setOrder(isInc ? 'desc' : 'inc');
			sortVerifications(property);
		}
	};

	const handleMenuAction = (type, verificationId) => {
		if (type === 'VIEW_DETAIL') {
			handleView(tenantAlis, verificationId);
		} else if (type === 'DOWNLOAD_REPORTS') {
			//SANCTIONS_AND_PEP_REPORT, AUS_BUSINESS_VERIFICATION_REPORT, IDV_VERIFICATION_REPORT
			handleDownloadReport(tenantAlis, verificationId);
		} else if (type === 'DELETE') {
			handleDelete(verificationId, tenantAlis);
		} else if (type === 'CONTACT_SUPPORT') {
			handleContactSupport(verificationId);
		} else if (type === 'RESEND_EMAIL') {
			handleResendInvite(tenantAlis, verificationId);
		}
		setAnchorEl(null);
	};

	const renderMenu = (id, status, actions, addMargin = false) => {
		return (
			<Box display='flex' mr={addMargin ? 1 : 0}>
				<IconButton
					edge='start'
					color='inherit'
					aria-label='open detail'
					aria-haspopup='true'
					onClick={(event) => handleAnchorElClick(id, status, event)}
				>
					<MoreHorizIcon />
				</IconButton>
				<StyledMenu
					id={'menu'}
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleMenuClose}
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
				>
					{actions.map((action) => {
						return (
							<MenuItem
								key={`${action.key}-${action.id}`}
								onClick={() => handleMenuAction(action.key, action.id)}
							>
								{renaderIcon(action.key)}
								{action.label}
							</MenuItem>
						);
					})}
				</StyledMenu>
			</Box>
		);
	};

	const bodyBuilder = (refreshing: boolean) => {
		const myArr = Array.from(
			{
				length: 10,
			},
			() => ''
		);
		if (!refreshing) {
			return listData.map(
				({
					email,
					verificationId,
					name,
					status,
					lastModified,
					agentName,
					externalRefId,
					proofingResults,
					otherCheckOutcomes,
				}) => {
					return {
						items: [
							{
								size: {
									//xxlSize: '235px',
									xxlSize: '20%',
									// xlSize: '200px',
									xlSize: '18%',
									lgSize: '22%',
									mdSize: '200px',
									smSize: '200px',
								},
								shouldHaveMinWidth: true,
								alignment: 'flex-start',
								content: (
									<>
										<Box
											sx={{
												maxWidth: '95%',
												overflow: 'hidden',
												whiteSpace: 'nowrap',
												display: 'flex',
												flexDirection: 'column',
											}}
										>
											<Typography
												sx={{
													fontSize: '14px',
													lineHeight: '16px',
													color: '#4F4F4F',
													overflow: 'hidden',
													textOverflow: 'ellipsis',
												}}
											>
												{email}
											</Typography>
											<Box sx={(theme) => StyledVerificationIDContainer(theme)}>
												<Typography
													sx={{
														fontSize: '14px',
														lineHeight: '16px',
														color: '#4F4F4F',
														overflow: 'hidden',
														textOverflow: 'ellipsis',
													}}
												>
													{verificationId}
												</Typography>
												<CopyToClipboard
													text={verificationId}
													copyMessage='Verification ID Copied'
												/>
											</Box>
										</Box>
									</>
								),
							},
							{
								size: {
									//xxlSize: '184px',
									xxlSize: '12%',
									//xlSize: '126px',
									xlSize: '11%',
									lgSize: '14.5%',
									mdSize: '126px',
									smSize: '126px',
								},
								shouldHaveMinWidth: true,
								divider: true,
								alignment: 'flex-start',
								content: (
									<>
										<Box
											sx={{
												maxWidth: '95%',
												overflow: 'hidden',
												whiteSpace: 'nowrap',
												display: 'flex',
												flexDirection: 'column',
											}}
										>
											{name.map((name, i) => (
												<Typography
													sx={{
														fontSize: '14px',
														lineHeight: '16px',
														color: '#515170',
														overflow: 'hidden',
														textOverflow: 'ellipsis',
													}}
													key={`${name}-${i}`}
												>
													{name}
												</Typography>
											))}
										</Box>
									</>
								),
							},
							{
								size: {
									//xxlSize: '200px',
									xxlSize: '12.5%',
									// xlSize: '176px',
									xlSize: '16%',
									lgSize: '20%',
									mdSize: '165px',
									smSize: '165px',
								},
								shouldHaveMinWidth: true,
								divider: true,
								alignment: 'flex-start',
								content: (
									<>
										<Box
											sx={{
												maxWidth: '95%',
												display: 'flex',
												flexDirection: 'row',
												overflow: 'hidden',
												whiteSpace: 'nowrap',
												alignItems: 'center',
											}}
										>
											{renaderIcon(status)}
											<Typography
												sx={{
													fontSize: '14px',
													lineHeight: '16px',
													color: '#515170',
													marginLeft: '8px',
													overflow: 'hidden',
													textOverflow: 'ellipsis',
												}}
											>
												{statusLabel(status)}
											</Typography>
										</Box>
									</>
								),
							},
							{
								size: {
									//xxlSize: '184px',
									xxlSize: '11.5%',
									// xlSize: '130px',
									xlSize: '13%',
									lgSize: '17.5%',
									mdSize: '130px',
									smSize: '130px',
								},
								shouldHaveMinWidth: true,
								divider: true,
								alignment: 'flex-start',
								content: (
									<>
										<Box
											sx={{
												maxWidth: '95%',
												display: 'flex',
												whiteSpace: 'nowrap',
												flexDirection: 'column',
											}}
										>
											<Typography
												sx={{
													fontSize: '14px',
													lineHeight: '16px',
													color: '#515170',
													overflow: 'hidden',
													textOverflow: 'ellipsis',
												}}
											>
												{lastModified}
											</Typography>
										</Box>
									</>
								),
							},
							{
								size: {
									//xxlSize: '184px',
									xxlSize: '11.5%',
									// xlSize: '100px',
									xlSize: '9%',
									lgSize: '16%',
									mdSize: '100px',
									smSize: '100px',
								},
								disappearanceBreakPoint: {
									xxl: false,
									xl: false,
									lg: true,
									md: false,
									sm: false,
									xs: false,
								},
								shouldHaveMinWidth: true,
								divider: true,
								alignment: 'flex-start',
								content: (
									<>
										<Box
											sx={{
												maxWidth: '95%',
												overflow: 'hidden',
												whiteSpace: 'nowrap',
												display: 'flex',
												flexDirection: 'column',
											}}
										>
											{agentName.map((name, i) => (
												<Typography
													sx={{
														fontSize: '14px',
														lineHeight: '16px',
														color: '#515170',
														overflow: 'hidden',
														textOverflow: 'ellipsis',
													}}
													key={`${name}-${i}`}
												>
													{name}
												</Typography>
											))}
										</Box>
									</>
								),
							},
							{
								size: {
									//xxlSize: '184px',
									xxlSize: '11.5%',
									// xlSize: '130px',
									xlSize: '11%',
									lgSize: '16%',
									mdSize: '130px',
									smSize: '130px',
								},
								disappearanceBreakPoint: {
									xxl: false,
									xl: false,
									lg: true,
									md: true,
									sm: false,
									xs: false,
								},
								shouldHaveMinWidth: true,
								divider: true,
								alignment: 'flex-start',
								content: (
									<>
										<Box
											sx={{
												maxWidth: '95%',
												display: 'flex',
												flexDirection: 'column',
											}}
										>
											<Typography
												sx={{
													fontSize: '14px',
													lineHeight: '16px',
													color: '#515170',
													overflow: 'hidden',
													textOverflow: 'ellipsis',
												}}
											>
												{externalRefId}
											</Typography>
										</Box>
									</>
								),
							},
							{
								size: {
									//xxlSize: '250px',
									xxlSize: '16%',
									//xlSize: '215px',
									xlSize: '20%',
									lgSize: '24%',
									mdSize: '215px',
									smSize: '215px',
								},
								shouldHaveMinWidth: true,
								divider: true,
								alignment: 'flex-start',
								content: (
									<>
										<Box
											sx={{
												maxWidth: '95%',
												overflow: 'hidden',
												whiteSpace: 'nowrap',
												display: 'flex',
												flexDirection: 'column',
											}}
										>
											{isEmpty(proofingResults) && isEmpty(otherCheckOutcomes) ? (
												''
											) : (
												<>
													{proofingResults.map((proofing, i) => (
														<Box
															sx={{
																display: 'flex',
																flexDirection: 'row',
																alignItems: 'center',
																padding: '4px 0',
															}}
															key={`${proofing.code}-${i}`}
														>
															{renaderIcon(proofing?.proofingStatus)}
															<Typography
																sx={{
																	fontSize: '14px',
																	lineHeight: '16px',
																	color: '#515170',
																	marginLeft: '8px',
																	overflow: 'hidden',
																	textOverflow: 'ellipsis',
																}}
															>
																{proofing.name}
															</Typography>
														</Box>
													))}
													{!isEmpty(otherCheckOutcomes) && (
														<Box
															sx={{
																display: 'flex',
																flexDirection: 'row',
																alignItems: 'center',
																padding: '4px 0',
															}}
														>
															{renaderIcon(
																getOtherChecksStatus(otherCheckOutcomes)
															)}
															<Typography
																sx={{
																	fontSize: '14px',
																	lineHeight: '16px',
																	color: '#515170',
																	marginLeft: '8px',
																	overflow: 'hidden',
																	textOverflow: 'ellipsis',
																}}
															>
																Other Checks
															</Typography>
														</Box>
													)}
												</>
											)}
										</Box>
									</>
								),
							},
							{
								size: {
									xxlSize: '50px',
									xlSize: '50px',
									lgSize: '50px',
									mdSize: '50px',
									smSize: '50px',
								},
								alignment: 'flex-end',
								shouldHaveMinWidth: true,
								content: renderMenu(verificationId, status, actions, true),
							},
						],
						mobileItems: {
							firstRow: [
								{
									content: (
										<>
											<Box
												sx={{
													maxWidth: '95%',
													overflow: 'hidden',
													whiteSpace: 'nowrap',
													display: 'flex',
													flexDirection: 'column',
												}}
											>
												<Typography
													sx={{
														fontSize: '14px',
														lineHeight: '16px',
														color: '#4F4F4F',
														overflow: 'hidden',
														textOverflow: 'ellipsis',
													}}
												>
													{email}
												</Typography>
												<Box sx={(theme) => StyledVerificationIDContainer(theme)}>
													<Typography
														sx={{
															fontSize: '14px',
															lineHeight: '16px',
															color: '#4F4F4F',
															overflow: 'hidden',
															textOverflow: 'ellipsis',
														}}
													>
														{verificationId}
													</Typography>
													<CopyToClipboard
														text={verificationId}
														copyMessage='Verification ID Copied'
													/>
												</Box>
											</Box>
										</>
									),
								},
								{
									disappearanceBreakPoint: {
										sm: true,
										xs: true,
									},
									content: (
										<>
											<Box
												sx={{
													maxWidth: '95%',
													overflow: 'hidden',
													whiteSpace: 'nowrap',
													display: 'flex',
													flexDirection: 'column',
												}}
											>
												{name.map((name, i) => (
													<Typography
														sx={{
															fontSize: '14px',
															lineHeight: '16px',
															color: '#515170',
															overflow: 'hidden',
															textOverflow: 'ellipsis',
														}}
														key={`${name}-${i}`}
													>
														{name}
													</Typography>
												))}
											</Box>
										</>
									),
								},
								{
									content: (
										<>
											<Box
												sx={{
													maxWidth: '95%',
													display: 'flex',
													flexDirection: 'row',
													overflow: 'hidden',
													whiteSpace: 'nowrap',
													alignItems: 'center',
												}}
											>
												{renaderIcon(status)}
												<Typography
													sx={{
														fontSize: '14px',
														lineHeight: '16px',
														color: '#515170',
														marginLeft: '8px',
														overflow: 'hidden',
														textOverflow: 'ellipsis',
													}}
												>
													{statusLabel(status)}
												</Typography>
											</Box>
										</>
									),
								},
								{
									disappearanceBreakPoint: {
										xs: true,
									},
									content: (
										<>
											<Box
												sx={{
													maxWidth: '95%',
													display: 'flex',
													whiteSpace: 'nowrap',
													flexDirection: 'column',
												}}
											>
												<Typography
													sx={{
														fontSize: '14px',
														lineHeight: '16px',
														color: '#515170',
														overflow: 'hidden',
														textOverflow: 'ellipsis',
													}}
												>
													{lastModified}
												</Typography>
											</Box>
										</>
									),
								},
							],
							secondRow: [
								{
									disappearanceBreakPoint: {
										xs: true,
									},
									content: (
										<>
											<Box
												sx={{
													maxWidth: '95%',
													overflow: 'hidden',
													whiteSpace: 'nowrap',
													display: 'flex',
													flexDirection: 'column',
												}}
											>
												{agentName.map((name, i) => (
													<Typography
														sx={{
															fontSize: '14px',
															lineHeight: '16px',
															color: '#515170',
															overflow: 'hidden',
															textOverflow: 'ellipsis',
														}}
														key={`${name}-${i}`}
													>
														{name}
													</Typography>
												))}
											</Box>
										</>
									),
								},
								{
									disappearanceBreakPoint: {
										xs: true,
									},
									content: (
										<>
											<Box
												sx={{
													maxWidth: '95%',
													display: 'flex',
													flexDirection: 'column',
												}}
											>
												<Typography
													sx={{
														fontSize: '14px',
														lineHeight: '16px',
														color: '#515170',
														overflow: 'hidden',
														textOverflow: 'ellipsis',
													}}
												>
													{externalRefId}
												</Typography>
											</Box>
										</>
									),
								},
								{
									content: (
										<>
											<Box
												sx={{
													maxWidth: '95%',
													overflow: 'hidden',
													whiteSpace: 'nowrap',
													display: 'flex',
													flexDirection: 'column',
												}}
											>
												{isEmpty(proofingResults) && isEmpty(otherCheckOutcomes) ? (
													''
												) : (
													<>
														{proofingResults.map((proofing, i) => (
															<Box
																sx={{
																	display: 'flex',
																	flexDirection: 'row',
																	alignItems: 'center',
																	padding: '4px 0',
																}}
																key={`${proofing.code}-${i}`}
															>
																{renaderIcon(proofing?.proofingStatus)}
																<Typography
																	sx={{
																		fontSize: '14px',
																		lineHeight: '16px',
																		color: '#515170',
																		marginLeft: '8px',
																		overflow: 'hidden',
																		textOverflow: 'ellipsis',
																	}}
																>
																	{proofing.name}
																</Typography>
															</Box>
														))}
														{!isEmpty(otherCheckOutcomes) && (
															<Box
																sx={{
																	display: 'flex',
																	flexDirection: 'row',
																	alignItems: 'center',
																	padding: '4px 0',
																}}
															>
																{renaderIcon(
																	getOtherChecksStatus(otherCheckOutcomes)
																)}
																<Typography
																	sx={{
																		fontSize: '14px',
																		lineHeight: '16px',
																		color: '#515170',
																		marginLeft: '8px',
																		overflow: 'hidden',
																		textOverflow: 'ellipsis',
																	}}
																>
																	Other Checks
																</Typography>
															</Box>
														)}
													</>
												)}
											</Box>
										</>
									),
								},
							],
							action: { content: renderMenu(verificationId, status, actions) },
						},
					};
				}
			);
		}
		const arr = myArr?.map((item, idx) => {
			return {
				id: idx,
				items: [
					{
						size: {
							//xxlSize: '235px',
							xxlSize: '20%',
							// xlSize: '200px',
							xlSize: '18%',
							lgSize: '22%',
							mdSize: '200px',
							smSize: '200px',
						},
						shouldHaveMinWidth: true,
						alignment: 'flex-start',
						content: (
							<Box display='flex' width={'100%'}>
								<Skeleton sx={{ mr: '16px' }} variant='circular' width={16} height={16} />{' '}
								<Skeleton variant='rectangular' sx={{ width: '80%', height: '16px' }} />
							</Box>
						),
					},
					{
						size: {
							//xxlSize: '184px',
							xxlSize: '12%',
							//xlSize: '126px',
							xlSize: '11%',
							lgSize: '14.5%',
							mdSize: '126px',
							smSize: '126px',
						},
						alignment: 'flex-start',
						shouldHaveMinWidth: true,
						divider: true,
						content: (
							<Box display='flex' width={'100%'}>
								<Skeleton sx={{ mr: '16px' }} variant='circular' width={16} height={16} />{' '}
								<Skeleton variant='rectangular' sx={{ width: '80%', height: '16px' }} />
							</Box>
						),
					},
					{
						size: {
							//xxlSize: '200px',
							xxlSize: '12.5%',
							// xlSize: '176px',
							xlSize: '16%',
							lgSize: '20%',
							mdSize: '165px',
							smSize: '165px',
						},
						alignment: 'flex-start',
						shouldHaveMinWidth: true,
						divider: true,
						content: (
							<Box display='flex' width={'100%'}>
								<Skeleton sx={{ mr: '16px' }} variant='circular' width={16} height={16} />{' '}
								<Skeleton variant='rectangular' sx={{ width: '80%', height: '16px' }} />
							</Box>
						),
					},
					{
						size: {
							//xxlSize: '184px',
							xxlSize: '11.5%',
							// xlSize: '130px',
							xlSize: '13%',
							lgSize: '17.5%',
							mdSize: '130px',
							smSize: '130px',
						},
						alignment: 'flex-start',
						shouldHaveMinWidth: true,
						divider: true,
						content: (
							<Box display='flex' width={'100%'}>
								<Skeleton variant='rectangular' sx={{ width: '100%', height: '16px' }} />
							</Box>
						),
					},
					{
						size: {
							//xxlSize: '184px',
							xxlSize: '11.5%',
							// xlSize: '100px',
							xlSize: '9%',
							lgSize: '16%',
							mdSize: '100px',
							smSize: '100px',
						},
						disappearanceBreakPoint: {
							xxl: false,
							xl: false,
							lg: true,
							md: false,
							sm: false,
							xs: false,
						},
						shouldHaveMinWidth: true,
						alignment: 'flex-start',
						divider: true,
						content: (
							<Box display='flex' width={'100%'}>
								{idx % 2 === 0 ? (
									<Skeleton variant='rectangular' sx={{ width: '80%', height: '16px' }} />
								) : (
									<Skeleton variant='rectangular' sx={{ width: '60%', height: '16px' }} />
								)}
							</Box>
						),
					},
					{
						size: {
							//xxlSize: '184px',
							xxlSize: '11.5%',
							// xlSize: '130px',
							xlSize: '11%',
							lgSize: '16%',
							mdSize: '130px',
							smSize: '130px',
						},
						disappearanceBreakPoint: {
							xxl: false,
							xl: false,
							lg: true,
							md: true,
							sm: false,
							xs: false,
						},
						shouldHaveMinWidth: true,
						alignment: 'flex-start',
						divider: true,
						content: (
							<Box display='flex' width={'100%'}>
								{idx % 2 === 1 ? (
									<Skeleton variant='rectangular' sx={{ width: '80%', height: '16px' }} />
								) : (
									<Skeleton variant='rectangular' sx={{ width: '60%', height: '16px' }} />
								)}
							</Box>
						),
					},
					{
						size: {
							//xxlSize: '250px',
							xxlSize: '16%',
							//xlSize: '215px',
							xlSize: '20%',
							lgSize: '24%',
							mdSize: '215px',
							smSize: '215px',
						},
						shouldHaveMinWidth: true,
						alignment: 'flex-start',
						divider: true,
						content: (
							<Box display='flex' width={'100%'}>
								<Skeleton sx={{ mr: '16px' }} variant='circular' width={16} height={16} />{' '}
								{idx % 2 === 1 ? (
									<Skeleton variant='rectangular' sx={{ width: '80%', height: '16px' }} />
								) : (
									<Skeleton variant='rectangular' sx={{ width: '60%', height: '16px' }} />
								)}
							</Box>
						),
					},
					{
						size: {
							xxlSize: '50px',
							xlSize: '50px',
							lgSize: '50px',
							mdSize: '50px',
							smSize: '50px',
						},
						alignment: 'center',
						shouldHaveMinWidth: true,
						divider: true,
						content: (
							<Box display='flex'>
								<Skeleton variant='circular' width={16} height={16} />
							</Box>
						),
					},
				],
				mobileItems: {
					firstRow: [
						{
							content: (
								<Box display='flex' width={'100%'} sx={{ padding: '12px 16px' }}>
									<Skeleton variant='rectangular' sx={{ width: '80%', height: '16px' }} />
								</Box>
							),
						},
						{
							content: (
								<Box display='flex' width={'100%'} sx={{ padding: '12px 16px' }}>
									<Skeleton variant='rectangular' sx={{ width: '80%', height: '16px' }} />
								</Box>
							),
						},
						{
							content: (
								<Box display='flex' width={'100%'} sx={{ padding: '12px 16px' }}>
									<Skeleton sx={{ mr: '16px' }} variant='circular' width={16} height={16} />{' '}
									<Skeleton variant='rectangular' sx={{ width: '100%', height: '16px' }} />
								</Box>
							),
						},
						{
							content: (
								<Box display='flex' width={'100%'} sx={{ padding: '12px 16px' }}>
									<Skeleton variant='rectangular' sx={{ width: '80%', height: '16px' }} />
								</Box>
							),
						},
					],
					secondRow: [
						{
							content: (
								<Box display='flex' width={'100%'} sx={{ padding: '12px 16px' }}>
									<Skeleton variant='rectangular' sx={{ width: '80%', height: '16px' }} />
								</Box>
							),
						},
						{
							content: (
								<Box display='flex' width={'100%'} sx={{ padding: '12px 16px' }}>
									<Skeleton variant='rectangular' sx={{ width: '80%', height: '16px' }} />
								</Box>
							),
						},
						{
							content: (
								<Box display='flex' width={'100%'} sx={{ padding: '12px 16px' }}>
									<Skeleton sx={{ mr: '16px' }} variant='circular' width={16} height={16} />{' '}
									<Skeleton variant='rectangular' sx={{ width: '100%', height: '16px' }} />
								</Box>
							),
						},
					],
					action: {
						content: (
							<Box display='flex'>
								<Skeleton variant='circular' width={16} height={16} />
							</Box>
						),
					},
				},
			};
		});
		return arr;
	};

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				width: '100%',
				minHeight: '50px',
			}}
		>
			<VerificationList
				headerData={headerData}
				orderBy={orderBy}
				order={order}
				bodyItems={bodyBuilder(loading)}
				rowHeight={'75px'}
				showEmptyTable={showEmptyTable}
				createSortHandler={createSortHandler}
			/>
		</Box>
	);
};

export default VerificationListPage;
