import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';

import { _getAuthRoles } from '../../store/selectors';

import VerificationDetailsV1 from './VerificationDetailPage';
import VerificationDetailsV2 from './VerificationDetailV2';

/**
 * MAIN COMPONENT
 */
const VerificationDetails = () => {
	const featureFlags = useFlags();
	const navigate = useNavigate();
	const { tenantAlias: alias, verId } = useParams<{ tenantAlias: string; verId: string }>();

	const [tenantAlias, setTenantAlias] = useState('');
	const authRoles = _getAuthRoles();

	const isDocumentProofingEnabled = featureFlags['document-proofing-feature'];

	useEffect(() => {
		// Get editable via URL
		if (verId === undefined) {
			navigate(`/tenant/${tenantAlias}/verification`);
		} else {
			setTenantAlias(verId);
		}
	}, [window.location.pathname]);

	if (isDocumentProofingEnabled === true) {
		return <VerificationDetailsV2 />;
	} else if (isDocumentProofingEnabled === false) {
		return <VerificationDetailsV1 isDocumentProofingEnabled={isDocumentProofingEnabled} />;
	} else return null;
};

export default VerificationDetails;
