import * as ActionTypes from './actionTypes';
import { ITenantDocument } from 'shared_components/src/service/models/tenant';

export const setTenants = (list: ITenantDocument[]) => ({
    type: ActionTypes.SET_TENANT_LIST,
    payload: list
});

export const setTenant = (tenant: ITenantDocument) => ({
    type: ActionTypes.SET_TENANT,
    payload: tenant
});

export const setTenantAlias = (id: string) => ({
    type: ActionTypes.SET_TENANT_ALIAS,
    payload: id
});

export const setTenantFilter = (filter: string) => ({
    type: ActionTypes.SET_TENANT_FILTER,
    payload: filter
});

export const setTenantKycState = (kycState: string) => ({
    type: ActionTypes.SET_TENANT_KYCSTATE,
    payload: kycState
});

export const setPlan = (plan: string) => ({
    type: ActionTypes.SET_PLAN,
    payload: plan
});

export const setCountriesList = (countryList: any) => ({
    type: ActionTypes.SET_TENANT_COUNTRY_LIST,
    payload: countryList,
});

export type TenantAction =
    | ReturnType<typeof setTenants>
    | ReturnType<typeof setTenant>
    | ReturnType<typeof setTenantAlias>
    | ReturnType<typeof setTenantFilter>
    | ReturnType<typeof setTenantKycState>
    | ReturnType<typeof setPlan>
    | ReturnType<typeof setCountriesList>;
