import React, { useState, useEffect, useLayoutEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { Grid, Card, CardHeader, Avatar, Divider } from '@mui/material';
import AutorenewIcon from '@mui/icons-material/Autorenew';

import {
	VerificationTable,
	VerificationInviteModal,
	VerificationTableV2,
} from 'shared_components/src/components/verification';
import {
	CustomSelect,
	SearchBar,
	CustomPagination,
	CustomButton,
} from 'shared_components/src/components/common';
import { getIsMobile } from 'shared_components/src/common/utils';
import { AUTH_ROLE, NOTIFICATION_STATES, GENERAL_COOKIES } from 'shared_components/src/common/constants';

import {
	IVerificationList,
	IVerificationInviteRequest,
	IVerificationDocumentV2,
	IProofingFramework,
	VERIFICATION_STATES,
	IVERIFICATION_DETAIL_EDIT_STATES,
} from 'shared_components/src/service/models/verification';
import { TENANT_STATUS } from 'shared_components/src/service/models/tenant';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import KycApiService from '../../service/kycApi.service';
import { setLoading, clearLoading, setNotification } from '../../store/common/actions';
import { MENU } from '../../common/routes/menu';
import { updateMenu } from '../../store/menu/actions';
import {
	_getAuth,
	_getTenantFilter,
	_getTenantKycState,
	_getVerifications,
	_getTenantData,
	_getVerificationPage,
	_getTenantAlias,
	_getTenant,
	_getSettings,
	_getVerificationsPerPage,
} from '../../store/selectors';
import { setTenantFilter, setTenantKycState } from '../../store/tenant/actions';
import {
	setVerifications,
	resetVerifications,
	setVerificationPageIndex,
	setVerificationEditStates,
	setVerificationRowsPerPage,
} from '../../store/verification/actions';
import { setSettings, resetSettings } from '../../store/common/actions';
import kycApiService from '../../service/kycApi.service';
import { getCookie } from 'shared_components/src/service/common.service';
import VerificationInviteV2 from './VerificationInviteV2';

import { Pagination, VerificationSearch } from 'shared_components/src/components/verification';
import VerificationList from './VerificationList';
import VerificationFilter from './VerificationFilterPage';
/*
 * Styles
 */
const useStyles = makeStyles(() => ({
	root: {
		'&.MuiPaper-root': {
			boxShadow: 'none',
			backgroundColor: 'transparent',
			padding: '0',

			'& .MuiCardHeader-root': {
				padding: '16px 0',
				flexDirection: 'row',

				'@media screen and (max-width: 600px)': {
					flexDirection: 'column',
					alignItems: 'start',
				},
			},

			'& .MuiCardHeader-title': {
				color: '#343451',
				fontSize: '26px',
				fontWeight: 600,
				lineHeight: '32px',
			},

			'& .MuiCardHeader-content': {
				'@media screen and (max-width: 600px)': {
					marginBottom: '16px',
				},
			},

			'& .MuiCardHeader-action': {
				textAlign: 'right',
				margin: 0,
			},
		},

		'& .card-header-bottom': {
			'&.MuiDivider-root': {
				backgroundColor: '#E8E8EE',
				height: '1px',
				width: '100%',
			},
		},
	},

	kycState: {
		'&.MuiFormControl-root': {
			minWidth: '300px',
			'@media screen and (max-width: 1408px)': {
				marginRight: 0,
			},
			'@media screen and (max-width: 1280px)': {
				minWidth: '150px',
			},
			'@media screen and (max-width: 999px)': {
				minWidth: '100%',
				width: '100%',
				marginLeft: 0,
				marginRight: 0,
			},
			'@media screen and (max-width: 600px)': {
				minWidth: '100%',
				margin: 0,
				marginTop: 17,
			},
		},
	},

	kycSearch: {
		'&.MuiGrid-root': {
			maxWidth: '590px',
			marginLeft: 'auto',
			width: '43%',
			'@media screen and (max-width: 1440px)': {
				maxWidth: '100%',
				width: '40%',
			},
			'@media screen and (max-width: 1280px)': {
				maxWidth: '100%',
				width: '40%',
			},
			'@media screen and (max-width: 999px)': {
				maxWidth: '100%',
				width: '100%',
			},
			'& .search-bar': {
				width: '100%',
			},
		},
	},

	table: {
		marginTop: 24,
		flexDirection: 'column',

		'& .tableHeader': {
			marginBottom: 16,
			'@media screen and (max-width: 1440px)': {
				marginBottom: 20,
			},

			'@media screen and (max-width: 1280px)': {
				marginBottom: 15,
			},

			'@media screen and (max-width: 600px)': {
				flexWrap: 'wrap',
				flexFlow: 'column-reverse',
				marginBottom: 18.6,
			},
		},

		overflow: 'auto',
		'@media screen and (max-width: 600px)': {
			minWidth: 0,
			marginTop: 14.5,
		},
	},

	verificationTable: {},

	pagination: {
		marginTop: 25,
		marginBottom: 15,
		'@media screen and (max-width: 1280px)': {
			marginTop: 15,
			marginBottom: 5,
		},
	},

	refreshButton: {
		'&.MuiButtonBase-root': {
			padding: '9px 23px',
			'& .MuiButton-startIcon': {
				marginRight: -4,
			},

			'@media screen and (max-width: 600px)': {
				marginRight: '0px',
			},
		},
	},
}));

/**
 * Main Component
 */
const VerificationPage = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const featureFlags = useFlags();

	const authInfo = _getAuth();
	const tenantData = _getTenantData();
	const verifications = _getVerifications();
	const tenantAlias = _getTenantAlias();
	const tenantFilter = _getTenantFilter();
	const tenantKycState = _getTenantKycState();
	const verificationPage = _getVerificationPage();
	const settings = _getSettings();
	const tenant = _getTenant();
	const verificationsPerPage = _getVerificationsPerPage();

	const classes = useStyles();

	const SORTING_KEYS = {
		EMAIL: 'email',
		NAME: 'lastname',
		AGENT: 'agentfamilyname',
		LAST_MODIFIED: 'updatedAt',
	};

	const [isInited, setIsInited] = useState(false);
	const [showEmptyTable, setShowEmptyTable] = useState(false);
	const [isMobile, setIsMobile] = useState(getIsMobile());
	const [sortOrder, setSortOrder] = useState('desc' as any);
	const [columnSort, setColumnSort] = useState('LAST_MODIFIED');
	const [showV2InviteScreen, setShowV2InviteScreen] = useState(false);
	const [proofingFrameworks, setProofingFrameworks] = useState<IProofingFramework[]>([]);
	const [showAdvanceFilters, setShowAdvanceFilters] = useState(false);
	const [filters, setFilters] = useState({} as any);
	const [searchString, setSearchString] = useState('');
	const [showRecordsCount, setShowRecordsCount] = useState(false);

	const isV2Enabled = useMemo(() => {
		return featureFlags?.['document-proofing-feature'];
	}, [featureFlags]);

	useEffect(() => {
		window.addEventListener('resize', () => {
			setIsMobile(getIsMobile());
		});

		dispatch(updateMenu(MENU.verificaton));
	}, []);

	useLayoutEffect(() => {
		setIsInited(true);

		try {
			const filterStatus = sessionStorage.getItem('verificationFilterStatus');
			const filters = sessionStorage.getItem('verificationFilters');

			if (filterStatus) {
				const parsedFilters = JSON.parse(filterStatus);
				setShowAdvanceFilters(!parsedFilters);
			}

			if (filters) {
				const parsedFilters = JSON.parse(filters);
				setFilters(parsedFilters);
			}
		} catch (error) {
			console.error('Error retrieving filter data from session storage:', error);
		}
	}, []);

	useEffect(() => {
		isInited && loadTable();
	}, [
		verificationPage,
		tenantAlias,
		tenantKycState,
		tenantFilter,
		isInited,
		verificationsPerPage,
		columnSort,
		sortOrder,
		filters,
	]);

	useEffect(() => {
		return () => {
			dispatch(setTenantFilter(''));
		};
	}, []);

	useEffect(() => {
		KycApiService.getSettings()
			.then((res) => {
				const payload = {
					...res?.features,
				};
				setProofingFrameworks(res.proofingFrameworks || []);
				dispatch(setSettings(payload));
			})
			.catch((err) => {
				dispatch(resetSettings());
			});
	}, [verifications?.count]);

	const loadTable = async () => {
		if (!tenantAlias) {
			return;
		}
		const selectdStatus = VERIFICATION_STATES.find(
			(item: { value: any }) => item.value === tenantKycState
		);

		const defaultFilters = {
			search: tenantFilter,
			status: selectdStatus?.subfix,
		};

		dispatch(setLoading());
		KycApiService.getVerificationList(
			verificationPage,
			verificationsPerPage,
			isV2Enabled
				? `${sortOrder === 'desc' ? '-' : ''}${SORTING_KEYS[columnSort]}`
				: `-${SORTING_KEYS[columnSort]}`,
			isV2Enabled ? { search: searchString, ...filters } : defaultFilters
		)
			.then((res: IVerificationList) => {
				if (res.count >= verificationPage - 1) {
					console.log('refresh table');
					dispatch(setVerifications(res));
				} else {
					console.log('change page');
					handleChangePageIndex(verificationPage - 1);
				}
				setShowEmptyTable(res.count === 0);
			})
			.catch((err) => {
				setShowEmptyTable(true);
				dispatch(resetVerifications());
			})
			.finally(() => {
				dispatch(clearLoading());
			});
	};

	const handleSendInvite = (inviteData: IVerificationInviteRequest) => {
		return new Promise(async (resolve, reject) => {
			dispatch(setLoading());
			const req = {
				...inviteData,
			};

			KycApiService.sendInviteToCustomer(req)
				.then((res) => {
					dispatch(clearLoading());
					if (res.error) {
						displayNotificatoinError('Failed to send');
						return reject({ status: 'error' });
					} else {
						loadTable();
						displayNotificatoinSuccess('Sent Invite Successfully');
						return resolve({ status: 'success' });
					}
				})
				.catch((err) => {
					dispatch(clearLoading());
					displayNotificatoinError('Failed to send');
					return reject({ status: 'error' });
				});
		});
	};

	const generateInviteUrl = (data: IVerificationInviteRequest) => {
		const _userInfo = getCookie(GENERAL_COOKIES.userInfo);
		const userInfo = _userInfo ? JSON.parse(_userInfo) : undefined;
		const req = {
			...data,
			organisation: userInfo['custom:organisation'],
		};

		return new Promise(async (resolve, reject) => {
			dispatch(setLoading());
			KycApiService.generateInviteUrl(req)
				.then((res) => {
					if (!res.err && !res.error) {
						dispatch(clearLoading());
						displayNotificatoinSuccess('Generated URL successfully.');
						loadTable();

						return resolve({
							status: 'success',
							res,
						});
					} else {
						dispatch(clearLoading());
						displayNotificatoinError('Failed Generate Invite URL.');
						return reject(res.err);
					}
				})
				.catch((err) => {
					dispatch(clearLoading());
					displayNotificatoinError('Failed Generate Invite URL.');
					return reject(err);
				});
		});
	};

	const sendCopyToClipboard = () => {
		displayNotificatoinSuccess('URL is successfully copied.');
	};

	const initPageIndex = () => {
		dispatch(setVerificationPageIndex(1));
	};

	const handleKYCState = (event: React.ChangeEvent<{ value: unknown }>) => {
		dispatch(setTenantKycState(event.target.value as string));
		initPageIndex();
	};

	const handleKYCSearch = (event: React.ChangeEvent<{ value: unknown }>) => {
		dispatch(setTenantFilter(event.target.value as string));
		initPageIndex();
	};

	const handleChangePageIndex = (curPage: number) => {
		dispatch(setVerificationPageIndex(curPage + 1));
	};

	const handleResendInvite = (subId: string, verId: string) => {
		const _userInfo = getCookie(GENERAL_COOKIES.userInfo);
		const userInfo = _userInfo ? JSON.parse(_userInfo) : undefined;
		const curVerification = verifications.items.find((_item) => _item.verificationId === verId);
		const req = {
			...curVerification?.inviteeDetails,
			externalRefId: userInfo?.externalRefId ?? '',
			organisation: userInfo['custom:organisation'],
		};

		dispatch(setLoading());
		kycApiService
			.sendInviteToCustomer(req, verId)
			.then((res) => {
				dispatch(clearLoading());

				if (!res.error) {
					loadTable();
					displayNotificatoinSuccess('Successfully Resend Invite');
				} else {
					displayNotificatoinError('Failed to send');
				}
			})
			.catch((err) => {
				dispatch(clearLoading());
				displayNotificatoinError('Failed to send');
			});
	};

	const handleView = (subId: string, verId: string) => {
		const editState: IVERIFICATION_DETAIL_EDIT_STATES = {
			result: false,
			detail: false,
		};

		dispatch(setVerificationEditStates(editState));
		navigate(`/tenant/${tenantAlias}/verification-detail/${verId}`);
	};

	const handleEdit = (subId: string, verId: string) => {
		const editState: IVERIFICATION_DETAIL_EDIT_STATES = {
			result: true,
			detail: true,
		};

		dispatch(setVerificationEditStates(editState));
		navigate(`/tenant/${tenantAlias}/verification-detail/${verId}`);
	};

	const handleDelete = (verId: string, subId: string) => {
		dispatch(setLoading());
		kycApiService
			.deleteVerification(verId)
			.then((res) => {
				dispatch(clearLoading());
				if (!res.error) {
					displayNotificatoinSuccess('Successfully Delete Verification');
					if (verifications.page > 1 && verifications.count % verifications.limit === 1) {
						dispatch(setVerificationPageIndex(verificationPage - 1));
					} else {
						loadTable();
					}
				} else {
					displayNotificatoinError('Failed Delete Verification');
				}
			})
			.catch((err) => {
				dispatch(clearLoading());
				displayNotificatoinError('Failed Delete Verification');
			});
	};

	const handleDownloadReport = (tenantAlis, verificationId) => {
		console.log('handleDownloadReport', tenantAlis, verificationId);
	};

	const handleContactSupport = (verificationId) => {
		console.log('handleContactSupport', verificationId);
	};

	const displayNotificatoinSuccess = (msg: string) => {
		dispatch(
			setNotification({
				message: msg,
				type: NOTIFICATION_STATES.success,
			})
		);
	};

	const displayNotificatoinError = (msg: string) => {
		dispatch(
			setNotification({
				message: msg,
				type: NOTIFICATION_STATES.error,
			})
		);
	};

	const handleRefreshTable = () => {
		loadTable();
	};

	const captureRowsPerPageChange = (rowsPerPage: number) => {
		dispatch(setVerificationRowsPerPage(rowsPerPage));
	};

	const disableInvite = () => {
		return (
			tenant.status === TENANT_STATUS.CANCELLED ||
			tenant.status === TENANT_STATUS['AWAITING-PAYMENT-DETAILS']
		);
	};

	const sortVerifications = (value) => {
		setColumnSort(value);
	};

	const updateFilters = (filters) => {
		initPageIndex();
		setFilters(filters);
		setShowRecordsCount(true);
	};

	const resetFilters = () => {
		initPageIndex();
		setFilters({});
		setShowRecordsCount(false);

		try {
			sessionStorage.removeItem('verificationFilters');
		} catch (error) {
			console.error('Error saving verification filters to session storage:', error);
		}

		try {
			sessionStorage.removeItem('verificationFilterStatus');
		} catch (error) {
			console.error('Error saving verification filters status to session storage:', error);
		}
	};

	const handleSearch = (value) => {
		initPageIndex();
		handleRefreshTable();
	};

	const toggleFilters = (value) => {
		if (value) {
			resetFilters();
		} else {
			try {
				sessionStorage.setItem('verificationFilterStatus', value);
			} catch (error) {
				console.error('Error saving verification filters status to session storage:', error);
			}
		}
		setShowAdvanceFilters(!value);
	};

	const svgIcon = <AutorenewIcon />;

	if (showV2InviteScreen) {
		return (
			<VerificationInviteV2
				settings={settings}
				proofingFrameworks={proofingFrameworks}
				setShowV2InviteScreen={setShowV2InviteScreen}
				sendInvite={handleSendInvite}
				generateInviteUrl={generateInviteUrl}
				sendCopyToClipboard={sendCopyToClipboard}
			/>
		);
	}

	return (
		<Card className={classes.root} elevation={0}>
			<CardHeader
				action={
					<>
						{!isV2Enabled && (
							<CustomButton
								variant="outlined"
								startIcon={svgIcon}
								className={classes.refreshButton}
								onClick={handleRefreshTable}
							/>
						)}
						{!authInfo?.roles.includes(AUTH_ROLE.admin) && (
							<VerificationInviteModal
								isMobile={isMobile}
								disableInvite={disableInvite()}
								sendInvite={handleSendInvite}
								generateInviteUrl={generateInviteUrl}
								sendCopyToClipboard={sendCopyToClipboard}
								settings={settings}
								isV2Enabled={isV2Enabled}
								setShowV2InviteScreen={setShowV2InviteScreen}
							/>
						)}
					</>
				}
				title="Verifications"
				subheader="Overview of the customer’s verification"
			/>
			<Grid container className={classes.table}>
				{isV2Enabled ? (
					<Grid container className="tableHeader">
						<VerificationSearch
							searchString={searchString}
							setSearchString={setSearchString}
							showAdvanceFilters={showAdvanceFilters}
							onSearch={handleSearch}
							toggleFilters={toggleFilters}
							handleRefreshTable={handleRefreshTable}
						/>
					</Grid>
				) : (
					<Grid container className="tableHeader">
						<CustomSelect
							id="KYC-States"
							title="KYC Status"
							selectList={VERIFICATION_STATES}
							value={tenantData?.kycState}
							handleChange={handleKYCState}
							className={classes.kycState}
						/>
						<Grid container item className={classes.kycSearch}>
							<SearchBar
								id="kyc-search"
								placeholder="Search by Email, First Name, Last Name..."
								handleSearchbar={handleKYCSearch}
								className="search-bar"
								defaultValue={tenantData?.filter}
							/>
						</Grid>
					</Grid>
				)}
				{isV2Enabled && showAdvanceFilters && (
					<>
						<Divider className="card-header-bottom" />
						<VerificationFilter setFilters={updateFilters} resetFilters={resetFilters} />
					</>
				)}
				<Divider className="card-header-bottom" style={{ marginBottom: '16px' }} />
				{isV2Enabled ? (
					<>
						{showRecordsCount && (
							<div
								style={{
									color: '#515170',
									lineHeight: '24px',
									marginBottom: '16px',
								}}
							>
								{verifications.count} results are found
							</div>
						)}
						<VerificationList
							data={verifications.items as IVerificationDocumentV2[]}
							orderBy={columnSort}
							order={sortOrder}
							showEmptyTable={showEmptyTable}
							handleView={handleView}
							handleDownloadReport={handleDownloadReport}
							handleDelete={handleDelete}
							handleResendInvite={handleResendInvite}
							handleContactSupport={handleContactSupport}
							sortVerifications={sortVerifications}
							setOrder={setSortOrder}
						/>
					</>
				) : (
					<VerificationTable
						className={classes.verificationTable}
						data={verifications?.items}
						tenantAlias={tenantAlias}
						roles={authInfo?.roles}
						handleResendInvite={handleResendInvite}
						handleView={handleView}
						handleEdit={handleEdit}
						handleDelete={handleDelete}
					/>
				)}
				{verifications?.count > 0 && (
					<Pagination
						page={verifications?.page - 1 || 0}
						rowsPerPage={verificationsPerPage}
						total={verifications?.count}
						handleRowsPerPageChange={captureRowsPerPageChange}
						handlePageChange={handleChangePageIndex}
					/>
				)}
			</Grid>
		</Card>
	);
};

export default VerificationPage;
