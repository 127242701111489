import * as ActionTypes from './actionTypes';
import { FeatureFlagsAction } from './actions';

type FeatureFlags = {};

const initialState: FeatureFlags = {};

const featureFlagsReducer = (state: FeatureFlags = initialState, action: FeatureFlagsAction) => {
	switch (action.type) {
		case ActionTypes.SET_FEATURE_FLAGS:
			return {
				...state,
				...action.payload,
			};

		default:
			return state;
	}
};

export default featureFlagsReducer;
