import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty, map } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { updateMenu } from '../../store/menu/actions';
import { MENU } from '../../common/routes/menu';
import { Plan, ConfirmDialog } from 'shared_components/src/components/tenants';
import { NOTIFICATION_STATES } from 'shared_components/src/common/constants';
import { TENANT_STATUS, TENANT_PLAN } from 'shared_components/src/service/models/tenant';
import { _getTenant, _getPlan, _getSettings } from '../../store/selectors';
import { setTenant } from '../../store/tenant/actions';
import { setLoading, clearLoading, setNotification } from '../../store/common/actions';
import kycApiService from '../../service/kycApi.service';
import { getImageUrl } from 'shared_components/src/common/utils';

const PlanPage = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const featureFlags = useFlags();
	const tenant = _getTenant();
	const plan = _getPlan();

	const [images, setImages] = useState([] as any);
	const [addOns, setAddOns] = useState([] as any);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
	const [subscriptionEndDate, setSubscriptionEndDate] = useState('');

	const cancelSubscriptionFlag = useMemo(() => {
		return featureFlags['cancel-subscription'];
	}, [featureFlags]);

	useEffect(() => {
		dispatch(updateMenu(MENU.plan));
	}, [dispatch]);

	useEffect(() => {
		const fetchPlanDescriptionImages = (planCode) => {
			if (planCode === TENANT_PLAN.PLAN_FREE) {
				setImages(() => [getImageUrl('PLAN_FREE-1.png'), getImageUrl('PLAN_FREE-2.png')]);
			} else {
				setImages(() => [getImageUrl(`${planCode}.png`)]);
			}
		};

		if (!isEmpty(plan)) {
			fetchPlanDescriptionImages(plan?.code);
		}
	}, [plan]);

	useEffect(() => {
		function getTenantSettings() {
			dispatch(setLoading());
			return new Promise((resolve) => {
				kycApiService
					.getSettings()
					.then((res: any) => {
						if (res) {
							setAddOnsData(res.features);
						}
					})
					.catch((err: any) => {
						console.log('plan features error', err);
					})
					.finally(() => {
						dispatch(clearLoading());
						return resolve({ status: 'success' });
					});
			});
		}

		if (plan.code !== TENANT_PLAN.PLAN_FREE) {
			getTenantSettings();
		}
	}, [plan]);

	useEffect(() => {
		const getBillingInfo = (alias) => {
			dispatch(setLoading());
			return new Promise((resolve) => {
				kycApiService
					.getTenantBilling(alias)
					.then((res: any) => {
						if (!res.error) {
							setSubscriptionEndDate(res.expireAt);
						}
					})
					.catch((err: any) => {
						console.log('getBillingInfo err', err);
					})
					.finally(() => {
						dispatch(clearLoading());
						return resolve({ status: 'success' });
					});
			});
		};

		if (tenant?.alias) {
			getBillingInfo(tenant?.alias);
		}
	}, [dispatch, tenant.alias]);

	const hideCancelSubscription = true;
	// useMemo(() => {
	// 	return cancelSubscriptionFlag ? tenant.status === TENANT_STATUS.CANCELLED : true;
	// }, [cancelSubscriptionFlag, tenant]);

	const hideChangePlan = true;
	// useMemo(() => {
	// 	return tenant?.status === TENANT_STATUS.CANCELLED;
	// }, [tenant]);

	const setAddOnsData = (features) => {
		const products = map(features, (feature, key) => ({ key, ...feature }));
		const addOns = products.filter((product) => product.type === 'addOns');
		setAddOns(addOns);
	};

	const handleChangePlan = () => {
		navigate(`/tenant/${tenant?.alias}/plan/change-plan`);
	};

	const handleAddOnSetting = () => {
		navigate(`/tenant/${tenant}/add-ons`);
	};

	const handleCancelSubcription = () => {
		if (tenant.status === TENANT_STATUS['AWAITING-PAYMENT-DETAILS']) {
			setOpenConfirmDialog(true);
		} else {
			navigate(`/tenant/${tenant?.alias}/plan/cancel-subscription`);
		}
	};

	const handleCancelSubscription = () => {
		setOpenConfirmDialog(false);
		const request = {
			tenantAlias: tenant.alias,
			planCode: plan.code,
			productCode: tenant.product.code,
		};
		dispatch(setLoading());
		return new Promise((resolve) => {
			kycApiService
				.cancelTenant(request)
				.then((res: any) => {
					if (!res.error) {
						displayNotificatoinSuccess(
							'Your subscription is cancelled successfully. Your status is cancelled now.'
						);
						getTenant();
					} else {
						displayNotificatoinError('Failed to cancel your subscription.');
					}
				})
				.catch((err: any) => {
					displayNotificatoinError('Failed to cancel your subscription.');
				})
				.finally(() => {
					dispatch(clearLoading());
					return resolve({ status: 'success' });
				});
		});
	};

	const getTenant = () => {
		dispatch(setLoading());
		kycApiService
			.getTenantInfo(tenant.alias)
			.then((res) => {
				dispatch(setTenant(res));
			})
			.catch((err) => {
				console.log('tenant err => ', err);
			})
			.finally(() => {
				dispatch(clearLoading());
			});
	};

	const displayNotificatoinSuccess = (msg: string) => {
		dispatch(
			setNotification({
				message: msg,
				type: NOTIFICATION_STATES.success,
			})
		);
	};

	const displayNotificatoinError = (msg: string) => {
		dispatch(
			setNotification({
				message: msg,
				type: NOTIFICATION_STATES.error,
			})
		);
	};

	return (
		<>
			<Plan
				planData={plan}
				productCode={tenant?.product?.code}
				images={images}
				addOns={addOns}
				currency={tenant.currency}
				subscriptionEndDate={subscriptionEndDate}
				hideCancelSubscription={hideCancelSubscription}
				hideChangePlan={hideChangePlan}
				handleChangePlan={handleChangePlan}
				handleCancelSubcription={handleCancelSubcription}
				handleAddOnSetting={handleAddOnSetting}
			/>

			<ConfirmDialog
				open={openConfirmDialog}
				title='Cancel Subscription'
				titleImageType=''
				subTitle='Are you sure you want to cancel subscription ?'
				subTitle2='You will not be able to use Truuth services until you join a paid plan.'
				cancelButtonLabel='No'
				submitButtonLabel='Yes, Cancel'
				onClose={() => setOpenConfirmDialog(false)}
				onConfirm={handleCancelSubscription}
			/>
		</>
	);
};

export default PlanPage;
