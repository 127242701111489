import React from 'react';
import { isEmpty, uniqBy } from 'lodash';
import {
	Box,
	Button,
	Grid,
	MenuItem,
	Popper,
	Stack,
	TextField,
	styled,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Theme,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { parseISO, format } from 'date-fns';
import PhoneInput, { isValidPhoneNumber, Value } from 'react-phone-number-input';

import 'react-phone-number-input/style.css';

import { TruuthTabs, TruuthTab, TruuthChip } from 'shared_components/src/components/common';
import {
	CancelIcon,
	SendInviteIcon,
	GenerateInviteIcon,
	BackIcon,
	CopyIcon,
} from 'shared_components/src/common/icons';
import {
	IProofingFramework,
	IVerificationInviteRequest,
	IdentityOwnerCheckSource,
} from 'shared_components/src/service/models/verification';
import { getCookie } from 'shared_components/src/service/common.service';
import { isValidMobile } from 'shared_components/src/common/validation';
import { Console } from 'console';

/**
 * STYLES
 */
const Container = styled(Box)({
	padding: '4px 12px 4px 4px',
});

const PageHeader = styled('p')({
	margin: 0,
	padding: 0,
	fontFamily: 'Inter, sans-serif',
	fontWeight: 600,
	fontSize: '26px',
	lineHeight: '32px',
	color: '#343451',
	marginLeft: '20px',
});

const TruuthTextField = withStyles((theme: Theme) => ({
	root: {
		// width: '100%',
		// maxWidth: 550,
		marginTop: '16px',
		maxWidth: 400,

		'& .MuiInputBase-input': {
			color: '#20203A',
			padding: '10px 0 8px 0px',
			fontSize: 16,
			lineHeight: '20px',
		},

		'& .MuiInputLabel-root': {
			fontSize: 15,
			lineHeight: '24px',
		},

		'& .MuiFormLabel-root.Mui-focused': {
			color: '#ab47bc',
		},

		'& .MuiInput-root:after': {
			borderBottom: '2px solid #8C07DD',
		},
	},
}))(TextField);

const TextButton = styled(Button)({
	'&.MuiButtonBase-root.MuiButton-root': {
		background: '#FFFFFF',
		color: '#470D82',
		textTransform: 'none',
		padding: '4px 16px',
		fontSize: '14px',

		'&:hover': {
			color: '#8C07DD',
			background: '#F8EFFB',
		},
		'&:active': {
			color: '#5D0DA1',
			background: '#F3E2F8',
		},
		'&:disabled': {
			color: '#9885AD',
		},
	},
});

const FullWidthTruuthTextField = (params) => (
	<TruuthTextField {...params} onKeyDown={(e) => e.preventDefault()} variant='standard' fullWidth />
);

const CustomPhoneInput = React.forwardRef<any, any>((props, ref) => {
	return (
		<TruuthTextField {...props} inputRef={ref} variant='standard' fullWidth helperText={props.error} />
	);
});

/**
 * TYPES
 */

export interface ISettings {
	sanctionsAndPepVerification: {
		manualRequired: boolean;
		matchThreshold: number;
		required: boolean;
		requiredOverridable: boolean;
	};
	businessVerification: {
		manualRequired: boolean;
		required: boolean;
		requiredOverridable: boolean;
	};
	currentAddressProofing: {
		manualRequired: boolean;
		required: boolean;
		requiredOverridable: boolean;
	};
	reviewEdit: {
		required: boolean;
	};
	identityOwnerCheck?: {
		required: boolean;
		requiredOverridable: boolean;
		sources: {
			code: string;
			default: boolean;
			description: string;
		}[];
	};
}

interface VerificationInviteV2Props {
	sendCopyToClipboard: () => void;
	sendInvite: (inviteData: IVerificationInviteRequest) => Promise<unknown>;
	generateInviteUrl: (inviteData: IVerificationInviteRequest) => Promise<unknown>;
	setShowV2InviteScreen: React.Dispatch<React.SetStateAction<boolean>>;
	proofingFrameworks: IProofingFramework[];
	settings?: ISettings;
}

const VerificationInviteV2 = ({
	setShowV2InviteScreen,
	settings,
	proofingFrameworks,
	sendInvite,
	generateInviteUrl,
	sendCopyToClipboard,
}: VerificationInviteV2Props) => {
	const [currentTab, setCurrentTab] = React.useState<number>(0);

	const handleTabChange = (e: React.SyntheticEvent, newValue: number) => {
		setCurrentTab(newValue);
	};

	const renderTabContent = () => {
		if (currentTab === 0) {
			return (
				<EmailInvitation
					settings={settings}
					proofingFrameworks={proofingFrameworks}
					setShowV2InviteScreen={setShowV2InviteScreen}
					sendInvite={sendInvite}
				/>
			);
		} else if (currentTab === 1) {
			return (
				<InviteLink
					settings={settings}
					proofingFrameworks={proofingFrameworks}
					setShowV2InviteScreen={setShowV2InviteScreen}
					generateInviteUrl={generateInviteUrl}
					sendCopyToClipboard={sendCopyToClipboard}
				/>
			);
		}
	};

	return (
		<Container>
			<Stack direction='row' alignItems='center'>
				<span
					style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
					onClick={() => setShowV2InviteScreen(false)}
				>
					<BackIcon />
				</span>
				<PageHeader>New Invite</PageHeader>
			</Stack>
			<TruuthTabs
				value={currentTab}
				onChange={handleTabChange}
				variant='scrollable'
				scrollButtons={false}
				aria-label='scrollable tabs'
				TabIndicatorProps={{
					style: { display: 'none' },
				}}
				sx={{ marginTop: '1rem' }}
			>
				<TruuthTab key={0} label={'Email Invitation'} value={0} />
				<TruuthTab key={1} label={'Invite Link'} value={1} />
			</TruuthTabs>
			{renderTabContent()}
		</Container>
	);
};

export default VerificationInviteV2;

/* ***************************************************************************** */

/**
 * STYLES
 */
const EmailInviteContainer = styled(Box)({
	padding: '24px',
	background: '#FFFFFF',
	marginTop: '8px',
	border: '1px solid #E7E7EF',
	borderRadius: '8px',
	color: '#515170',
	lineHeight: '24px',
});

const SectionHeader = styled('span')({
	fontFamily: 'Inter, sans-serif',
	fontSize: '16px',
	fontWeight: 600,
	lineHeight: '24px',
});

/**
 * TYPES
 */
interface EmailInvitationProps {
	settings?: ISettings;
	proofingFrameworks: IProofingFramework[];
	setShowV2InviteScreen: React.Dispatch<React.SetStateAction<boolean>>;
	sendInvite: (inviteData: IVerificationInviteRequest) => Promise<unknown>;
}

type InviteData = {
	identityOwner: {
		email: string;
		familyName: string;
		givenName: string;
		middleName: string;
		gender: string;
		mobileNumber: string;
		dateOfBirth: string;
	};
	externalRefId?: string;
	organisation?: string;
	options: {
		sanctionsAndPepCheckRequired: boolean;
		businessVerificationRequired: boolean;
		currentAddressProofingRequired: boolean;
		proofingFrameworks: IProofingFramework[];
		identityOwnerCheckSource: IdentityOwnerCheckSource;
	};
};

const EmailInvitation = ({
	settings,
	proofingFrameworks,
	setShowV2InviteScreen,
	sendInvite,
}: EmailInvitationProps) => {
	const region = JSON.parse(getCookie('region'));

	const [inviteData, setInviteData] = React.useState<InviteData>({
		identityOwner: {
			email: '',
			familyName: '',
			givenName: '',
			middleName: '',
			gender: '',
			mobileNumber: '',
			dateOfBirth: '',
		},
		externalRefId: '',
		organisation: '',
		options: {
			sanctionsAndPepCheckRequired: settings?.sanctionsAndPepVerification?.required || false,
			businessVerificationRequired: settings?.businessVerification?.required || false,
			currentAddressProofingRequired: settings?.currentAddressProofing?.required || false,
			proofingFrameworks: [],
			identityOwnerCheckSource: settings?.identityOwnerCheck?.sources.find((source) => source.default)
				?.code as IdentityOwnerCheckSource,
		},
	});
	const [errors, setErrors] = React.useState<Record<string, boolean>>({});
	const [formSubmitted, setFormSubmitted] = React.useState(false);
	const [selectedFrameworks, setSelectedFrameworks] = React.useState<
		Record<string, IProofingFramework | null>
	>({});

	React.useEffect(() => {
		setInviteData({
			...inviteData,
			options: {
				...inviteData.options,
				sanctionsAndPepCheckRequired: settings?.sanctionsAndPepVerification?.required || false,
				businessVerificationRequired: settings?.businessVerification?.required || false,
				currentAddressProofingRequired: settings?.currentAddressProofing?.required || false,
			},
		});
	}, [
		settings?.sanctionsAndPepVerification?.required,
		settings?.businessVerification?.required,
		settings?.currentAddressProofing?.required,
	]);

	React.useEffect(() => {
		if (formSubmitted) {
			validate();
		}
	}, [inviteData]);

	/* All unique proofing types */
	const proofingTypes = uniqBy(
		proofingFrameworks.map((f) => f.proofingTag),
		function (e) {
			return e.code;
		}
	);

	/* proofing types other than IDENTITY_PROOFING and ADDRESS_PROOFING */
	const otherProofingTypes = proofingTypes.filter(
		(t) => t.code !== 'IDENTITY_PROOFING' && t.code !== 'ADDRESS_PROOFING'
	);

	/* Record of all Identity frameworks */
	const identityFrameworks: IProofingFramework[] = proofingFrameworks.filter(
		(f) => f.proofingTag.code === 'IDENTITY_PROOFING'
	);

	/* Record of all Current Address frameworks */
	const currentAddressFrameworks: IProofingFramework[] = proofingFrameworks.filter(
		(f) => f.proofingTag.code === 'ADDRESS_PROOFING'
	);

	/* Key value pair of other proofing types and their frameworks*/
	const otherFrameworks: Record<string, IProofingFramework[]> = {};
	otherProofingTypes.forEach((p) => {
		otherFrameworks[p.name] = proofingFrameworks.filter((f) => f.proofingTag.code === p.code);
	});

	React.useEffect(() => {
		const defaultIdentityFramework = getDefaultProofingFramework('IDENTITY_PROOFING');

		const defaultCurrentAddressFramework =
			settings?.currentAddressProofing?.requiredOverridable &&
			settings?.currentAddressProofing?.required
				? getDefaultProofingFramework('ADDRESS_PROOFING')
				: null;

		const selectedProofingFrameworks = {
			IDENTITY_PROOFING: defaultIdentityFramework,
			ADDRESS_PROOFING: defaultCurrentAddressFramework,
		};

		otherProofingTypes.forEach((type) => {
			selectedProofingFrameworks[type.code] = getDefaultProofingFramework(type.code);
		});

		setSelectedFrameworks(selectedProofingFrameworks);
	}, [JSON.stringify(proofingFrameworks)]);

	React.useEffect(() => {
		setInviteData((prev) => ({
			...prev,
			options: {
				...prev.options,
				proofingFrameworks: Object.keys(selectedFrameworks)
					.map((f) => selectedFrameworks[f])
					.filter((f) => Boolean(f)) as IProofingFramework[],
			},
		}));
	}, [selectedFrameworks]);

	const getDefaultProofingFramework = (proofingCode: string) => {
		switch (proofingCode) {
			case 'IDENTITY_PROOFING':
				return identityFrameworks.find((f) => Boolean(f.isDefault)) || identityFrameworks[0];
			case 'ADDRESS_PROOFING':
				return (
					currentAddressFrameworks.find((f) => Boolean(f.isDefault)) || currentAddressFrameworks[0]
				);
			default:
				const frameworks = proofingFrameworks.filter((f) => f.proofingTag.code === proofingCode);
				return frameworks.find((f) => Boolean(f.isDefault)) || frameworks[0];
		}
	};

	const isSelected = (f: IProofingFramework) => {
		const proofingType = f.proofingTag.code;
		return Boolean(selectedFrameworks[proofingType]?.code === f.code);
	};

	const handleProofingTypeChange = (proofingCode: string) => {
		if (proofingCode === 'ADDRESS_PROOFING') {
			setInviteData((prev) => ({
				...prev,
				options: {
					...prev.options,
					currentAddressProofingRequired: !prev.options.currentAddressProofingRequired,
				},
			}));
		}

		if (selectedFrameworks[proofingCode]) {
			setSelectedFrameworks((prev) => ({
				...prev,
				[proofingCode]: null,
			}));
		} else {
			setSelectedFrameworks((prev) => ({
				...prev,
				[proofingCode]: getDefaultProofingFramework(proofingCode),
			}));
		}
	};

	const handleFrameworkClick = (proofingCode: string, framework: IProofingFramework) => {
		if (selectedFrameworks[proofingCode]?.code !== framework.code) {
			setSelectedFrameworks((prev) => ({
				...prev,
				[proofingCode]: framework,
			}));
		}
	};

	const validate = () => {
		const validEmail = new RegExp(
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		);
		const validAlphabets = new RegExp(/^[a-zA-Z ]+$/);
		const validAlphaNumeric = new RegExp(/^[a-zA-Z0-9 ]+$/);
		const errors: Record<string, boolean> = {};

		if (!inviteData.identityOwner?.email) {
			errors.email = true;
		} else if (!inviteData.identityOwner?.email?.match(validEmail)) {
			errors.email = true;
		}

		if (
			inviteData.identityOwner.givenName &&
			!inviteData.identityOwner.givenName?.match(validAlphabets)
		) {
			errors.givenName = true;
		}

		if (
			inviteData.identityOwner.middleName &&
			!inviteData.identityOwner.middleName?.match(validAlphabets)
		) {
			errors.middleName = true;
		}

		if (
			inviteData.options.identityOwnerCheckSource === 'IDENTITY_OWNER' &&
			!inviteData.identityOwner.familyName
		) {
			errors.familyName = true;
		} else if (
			inviteData.identityOwner.familyName &&
			!inviteData.identityOwner.familyName?.match(validAlphabets)
		) {
			errors.familyName = true;
		}

		if (
			inviteData.options.identityOwnerCheckSource === 'IDENTITY_OWNER' &&
			!inviteData.identityOwner.dateOfBirth
		) {
			errors.dateOfBirth = true;
		}

		if (inviteData.externalRefId && !inviteData.externalRefId?.match(validAlphaNumeric)) {
			errors.externalRefId = true;
		}

		if (
			inviteData.identityOwner.mobileNumber &&
			!isValidPhoneNumber(inviteData.identityOwner.mobileNumber || '')
		) {
			errors.mobileNumber = true;
		}

		setErrors(errors);
		return isEmpty(errors);
	};

	const handleSubmit = () => {
		setFormSubmitted(true);
		submitData();
	};

	const submitData = () => {
		if (validate()) {
			sendInvite({
				...inviteData,
				options: {
					...inviteData.options,
					proofingFrameworks: Object.keys(selectedFrameworks)
						.map((f) => selectedFrameworks[f])
						.filter((f) => Boolean(f))
						.map((f) => f?.code as string),
				},
			})
				.then((res: any) => {
					closeInviteScreen();
				})
				.catch((err: any) => {
					console.error('invite for kyc error', err);
				});
		}
	};

	const closeInviteScreen = () => {
		setShowV2InviteScreen(false);
	};

	const handleReportSelect = (props: any) => {
		setInviteData({
			...inviteData,
			options: {
				...inviteData.options,
				[props.code]: !inviteData.options[props.code],
			},
		});
	};

	const handleIdentityOwnerCheckSourceChange = (source: IdentityOwnerCheckSource) => {
		setInviteData({
			...inviteData,
			options: {
				...inviteData.options,
				identityOwnerCheckSource: source,
			},
		});
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		if (['email', 'familyName', 'givenName', 'middleName', 'gender', 'dateOfBirth'].includes(name)) {
			// Update fields within identityOwner
			setInviteData((prev) => ({
				...prev,
				identityOwner: {
					...prev.identityOwner,
					[name]: value,
				},
			}));
		} else {
			// Update other fields directly on inviteData
			setInviteData((prev) => ({
				...prev,
				[name]: value,
			}));
		}
	};

	const handleInputPhoneNumberChange = (val: any) => {
		setInviteData((prev) => ({
			...prev,
			identityOwner: {
				...prev.identityOwner,
				mobileNumber: val,
			},
		}));
	};

	return (
		<EmailInviteContainer>
			<Stack
				direction='row'
				alignItems='center'
				justifyContent='space-between'
				sx={{ marginBottom: '1rem' }}
			>
				<SectionHeader>Customer Information</SectionHeader>
				<Stack direction='row' alignItems='center' justifyContent='space-between'>
					<Stack
						direction='row'
						alignItems='center'
						sx={{ cursor: 'pointer' }}
						onClick={closeInviteScreen}
					>
						<TextButton startIcon={<CancelIcon />}>Cancel</TextButton>
					</Stack>
					<Stack
						direction='row'
						alignItems='center'
						justifyContent='space-between'
						onClick={handleSubmit}
						sx={{ marginLeft: '24px', cursor: 'pointer' }}
					>
						<TextButton startIcon={<SendInviteIcon />}>Send Invite</TextButton>
					</Stack>
				</Stack>
			</Stack>

			<Grid container spacing={2} sx={{ paddingBottom: '24px', borderBottom: '1px solid #EDEDF3' }}>
				<Grid item xs={12} sm={6} md={4}>
					<Box>
						<TruuthTextField
							id={`given-name`}
							type='text'
							name='givenName'
							label='Given Name'
							onChange={handleChange}
							variant='standard'
							fullWidth
							error={errors.givenName}
							helperText={errors.givenName ? 'Enter only Alphabetic characters and space' : ''}
							inputProps={{
								maxLength: 30,
								pattern: '^[a-zA-Z][a-zA-Z ]*[a-zA-Z]',
								title: 'Enter only Alphabetic characters and space',
							}}
						/>
					</Box>
				</Grid>

				<Grid item xs={12} sm={6} md={4}>
					<Box>
						<TruuthTextField
							id={`middle-name`}
							type='text'
							name='middleName'
							label='Middle Name'
							onChange={handleChange}
							variant='standard'
							fullWidth
							error={errors.middleName}
							helperText={errors.middleName ? 'Enter only Alphabetic characters and space' : ''}
							inputProps={{
								maxLength: 30,
								pattern: '^[a-zA-Z][a-zA-Z ]*[a-zA-Z]',
								title: 'Enter only Alphabetic characters and space',
							}}
						/>
					</Box>
				</Grid>

				<Grid item xs={12} sm={6} md={4}>
					<Box>
						<TruuthTextField
							id={`family-name`}
							type='text'
							name='familyName'
							label='Family Name'
							onChange={handleChange}
							variant='standard'
							fullWidth
							error={errors.familyName}
							helperText={
								errors.familyName
									? !inviteData.identityOwner.familyName
										? 'Please enter the family name'
										: 'Enter only Alphabetic characters and space'
									: ''
							}
							inputProps={{
								maxLength: 35,
								pattern: '^[a-zA-Z][a-zA-Z ]*[a-zA-Z]',
								title: 'Enter only Alphabetic characters and space',
							}}
						/>
					</Box>
				</Grid>

				<Grid item xs={12} sm={6} md={4}>
					<Box>
						<TruuthTextField
							id={`customer-email-id`}
							type='email'
							name='email'
							label='Customer Email ID'
							required={true}
							onChange={handleChange}
							variant='standard'
							fullWidth
							error={errors.email}
							helperText={
								errors.email
									? !inviteData.identityOwner.email
										? 'Email address must be provided for an email invite'
										: 'Please enter a valid email'
									: ''
							}
							autoFocus
							inputProps={{
								maxLength: 319,
								pattern:
									'(^(?=.{1,64}@)([_A-Za-z0-9-+!#$%&‘*+–/=?^_`{|}~]+(.[_A-Za-z0-9-+!#$%&‘*+–/=?^_`{|}~]+)*))@[A-Za-z0-9-]+(.[A-Za-z0-9]+)*(.[A-Za-z]{2,})$',
							}}
						/>
					</Box>
				</Grid>

				<Grid item xs={12} sm={6} md={4}>
					<Box>
						<TruuthTextField
							id={`customer-reference`}
							type='text'
							name='externalRefId'
							label='Customer Reference'
							onChange={handleChange}
							variant='standard'
							fullWidth
							error={errors.externalRefId}
							helperText={errors.externalRefId ? 'Enter only Alphanumeric characters' : ''}
							inputProps={{
								maxLength: 40,
								pattern: '[a-zA-Z0-9 ]+',
								title: 'Enter only AlphabeticNumeric characters',
							}}
						/>
					</Box>
				</Grid>

				<Grid item xs={12} sm={6} md={4}>
					<Box>
						<TruuthTextField
							id={`gender`}
							select
							name='gender'
							label='Gender'
							value={inviteData.identityOwner.gender}
							onChange={handleChange}
							variant='standard'
							fullWidth
							error={errors.gender}
							helperText={errors.gender ? 'Select a gender' : ''}
						>
							<MenuItem value='male'>Male</MenuItem>
							<MenuItem value='female'>Female</MenuItem>
							<MenuItem value='other'>Other</MenuItem>
						</TruuthTextField>
					</Box>
				</Grid>

				<Grid item xs={12} sm={6} md={4}>
					<Box>
						<LocalizationProvider dateAdapter={AdapterDateFns}>
							<DatePicker
								label='Date of Birth'
								value={
									inviteData.identityOwner.dateOfBirth
										? parseISO(inviteData.identityOwner.dateOfBirth)
										: null
								}
								maxDate={new Date()}
								onChange={(newValue) => {
									setInviteData((prev) => ({
										...prev,
										identityOwner: {
											...prev.identityOwner,
											dateOfBirth: newValue ? format(newValue, 'yyyy-MM-dd') : '',
										},
									}));
								}}
								slots={{
									textField: FullWidthTruuthTextField,
									popper: (props) => <Popper {...props} placement='bottom-start' />,
								}}
								slotProps={{
									textField: {
										error: Boolean(errors.dateOfBirth),
										helperText: errors.dateOfBirth
											? 'Please enter a valid date of birth'
											: '',
									},
								}}
							/>
						</LocalizationProvider>
					</Box>
				</Grid>

				<Grid item xs={12} sm={6} md={4}>
					<Box>
						<PhoneInput
							id='mobile-number'
							name='mobileNumber'
							label='Mobile Number'
							defaultCountry={region?.countryCode}
							value={inviteData.identityOwner.mobileNumber as Value}
							onChange={(value) => {
								return setInviteData((prev) => ({
									...prev,
									identityOwner: {
										...prev.identityOwner,
										mobileNumber: value || '',
									},
								}));
							}}
							error={errors.mobileNumber ? 'Please enter a valid mobile number' : ''}
							inputComponent={CustomPhoneInput}
						/>
					</Box>
				</Grid>
			</Grid>

			{settings?.identityOwnerCheck?.requiredOverridable && (
				<Stack
					direction='column'
					sx={{ padding: '16px 0 24px 0', borderBottom: '1px solid #EDEDF3' }}
				>
					<SectionHeader sx={{ marginBottom: '1rem' }}>Identity Owner Rules</SectionHeader>
					<Stack direction='row' spacing={2}>
						{settings?.identityOwnerCheck?.sources.map((source) => (
							<TruuthChip
								code={source.code}
								label={source.description}
								key={source.code}
								selected={inviteData?.options?.identityOwnerCheckSource === source.code}
								isMultipleSelection={false}
								variant='outlined'
								background='color'
								onClick={() =>
									handleIdentityOwnerCheckSourceChange(
										source.code as IdentityOwnerCheckSource
									)
								}
							/>
						))}
					</Stack>
				</Stack>
			)}

			{(settings?.sanctionsAndPepVerification?.requiredOverridable ||
				settings?.businessVerification?.requiredOverridable) && (
				<Stack
					direction='column'
					sx={{ padding: '16px 0 24px 0', borderBottom: '1px solid #EDEDF3' }}
				>
					<SectionHeader sx={{ marginBottom: '1rem' }}>Reports Including</SectionHeader>
					<Stack direction='row' spacing={2}>
						{settings?.sanctionsAndPepVerification?.requiredOverridable && (
							<TruuthChip
								code='sanctionsAndPepCheckRequired'
								label='Pep and Sanctions Report'
								key='sanctionsAndPepCheckRequired'
								selected={inviteData?.options?.sanctionsAndPepCheckRequired}
								isMultipleSelection={true}
								variant='outlined'
								background='color'
								onClick={handleReportSelect}
							/>
						)}
						{settings?.businessVerification?.requiredOverridable && (
							<TruuthChip
								code='businessVerificationRequired'
								label='Company Enquiry Report'
								key='businessVerificationRequired'
								selected={inviteData?.options?.businessVerificationRequired}
								isMultipleSelection={true}
								variant='outlined'
								background='color'
								onClick={handleReportSelect}
							/>
						)}
					</Stack>
				</Stack>
			)}
			<Stack direction='column' sx={{ padding: '16px 0 24px 0' }}>
				<SectionHeader>Proofing Type & Framework</SectionHeader>
				<span
					style={{
						marginTop: '4px',
						fontFamily: 'Inter, sans-serif',
						fontWeight: 400,
						fontSize: '14px',
						lineHeight: '24px',
						color: '#515170',
						display: 'block',
					}}
				>
					Select proofing type and its framework
				</span>
				<Table sx={{ marginTop: '24px' }}>
					<TableHead>
						<TableRow>
							<TableCell sx={{ borderRight: '1px solid #E7E7EF' }}>Proofing Type</TableCell>
							<TableCell align='left' colSpan={5}>
								Framework
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<TableRow
							key='identity'
							sx={{ '&:last-child td, &:last-child th': { borderBottom: 0 } }}
						>
							<TableCell sx={{ borderRight: '1px solid #E7E7EF' }}>
								{identityFrameworks[0]?.proofingTag?.name || 'Identity Proofing'}
							</TableCell>
							<TableCell>
								<Stack direction='row' spacing={2} sx={{ flexWrap: 'wrap' }}>
									{identityFrameworks.map((f, index) => (
										<TruuthChip
											code={f.code}
											label={f.name}
											key={f.code}
											selected={isSelected(f)}
											isMultipleSelection={false}
											variant='outlined'
											background='color'
											onClick={() => handleFrameworkClick('IDENTITY_PROOFING', f)}
										/>
									))}
								</Stack>
							</TableCell>
						</TableRow>

						{settings?.currentAddressProofing.requiredOverridable && (
							<TableRow
								key='currentAddress'
								sx={{ '&:last-child td, &:last-child th': { borderBottom: 0 } }}
							>
								<TableCell sx={{ borderRight: '1px solid #E7E7EF' }}>
									<TruuthChip
										code='currentAddress'
										label={
											currentAddressFrameworks[0]?.proofingTag?.name ||
											'Current Address Proofing'
										}
										selected={inviteData?.options?.currentAddressProofingRequired}
										isMultipleSelection={true}
										variant='outlined'
										background='color'
										onClick={() => handleProofingTypeChange('ADDRESS_PROOFING')}
									/>
								</TableCell>
								<TableCell>
									<Stack direction='row' spacing={2} sx={{ flexWrap: 'wrap' }}>
										{currentAddressFrameworks.map((f, index) => (
											<TruuthChip
												code={f.code}
												label={f.name}
												key={f.code}
												selected={isSelected(f)}
												disabled={!selectedFrameworks['ADDRESS_PROOFING']}
												isMultipleSelection={false}
												variant='outlined'
												background='color'
												onClick={() => handleFrameworkClick('ADDRESS_PROOFING', f)}
												sx={{ flex: '1 1 auto', margin: '10px' }}
											/>
										))}
									</Stack>
								</TableCell>
							</TableRow>
						)}

						{Object.keys(otherFrameworks)?.map((type) => (
							<TableRow sx={{ '&:last-child td, &:last-child th': { borderBottom: 0 } }}>
								<TableCell>
									<TruuthChip
										code={type}
										label={type}
										key={type}
										selected={inviteData?.options?.currentAddressProofingRequired}
										isMultipleSelection={true}
										variant='outlined'
										background='color'
										onClick={() => handleProofingTypeChange(type)}
									/>
								</TableCell>

								<TableCell>
									<Stack direction='row' spacing={2} sx={{ flexWrap: 'wrap' }}>
										{otherFrameworks[type].map((f) => (
											<TruuthChip
												code={f.code}
												label={f.name}
												key={f.code}
												selected={isSelected(f)}
												disabled={!selectedFrameworks[type]}
												isMultipleSelection={false}
												variant='outlined'
												background='color'
												onClick={() => handleFrameworkClick(type, f)}
											/>
										))}
									</Stack>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</Stack>
		</EmailInviteContainer>
	);
};

/* ***************************************************************************** */

/**
 * STYLES
 */

/**
 * TYPES
 */
interface InviteLinkProps {
	settings?: ISettings;
	proofingFrameworks: IProofingFramework[];
	setShowV2InviteScreen: React.Dispatch<React.SetStateAction<boolean>>;
	generateInviteUrl: (inviteData: IVerificationInviteRequest) => Promise<unknown>;
	sendCopyToClipboard: () => void;
}

const InviteLink = ({
	settings,
	proofingFrameworks,
	setShowV2InviteScreen,
	generateInviteUrl,
	sendCopyToClipboard,
}: InviteLinkProps) => {
	const region = JSON.parse(getCookie('region'));
	const scrollRef = React.useRef<HTMLDivElement>(null);
	const [inviteData, setInviteData] = React.useState<InviteData>({
		identityOwner: {
			email: '',
			familyName: '',
			givenName: '',
			middleName: '',
			gender: '',
			mobileNumber: '',
			dateOfBirth: '',
		},
		externalRefId: '',
		organisation: '',
		options: {
			sanctionsAndPepCheckRequired: settings?.sanctionsAndPepVerification?.required || false,
			businessVerificationRequired: settings?.businessVerification?.required || false,
			currentAddressProofingRequired: settings?.currentAddressProofing?.required || false,
			proofingFrameworks: [],
			identityOwnerCheckSource: settings?.identityOwnerCheck?.sources.find((source) => source.default)
				?.code as IdentityOwnerCheckSource,
		},
	});
	const [errors, setErrors] = React.useState<Record<string, boolean>>({});
	const [formSubmitted, setFormSubmitted] = React.useState(false);
	const [generatedUrl, setGeneratedUrl] = React.useState<string | null>(null);
	const [selectedFrameworks, setSelectedFrameworks] = React.useState<
		Record<string, IProofingFramework | null>
	>({});

	React.useEffect(() => {
		setInviteData({
			...inviteData,
			options: {
				...inviteData.options,
				sanctionsAndPepCheckRequired: settings?.sanctionsAndPepVerification?.required || false,
				businessVerificationRequired: settings?.businessVerification?.required || false,
				currentAddressProofingRequired: settings?.currentAddressProofing?.required || false,
			},
		});
	}, [
		settings?.sanctionsAndPepVerification?.required,
		settings?.businessVerification?.required,
		settings?.currentAddressProofing?.required,
	]);

	React.useEffect(() => {
		if (formSubmitted) {
			validate();
		}
	}, [inviteData]);

	/* All unique proofing types */
	const proofingTypes = uniqBy(
		proofingFrameworks.map((f) => f.proofingTag),
		function (e) {
			return e.code;
		}
	);

	/* proofing types other than IDENTITY_PROOFING and ADDRESS_PROOFING */
	const otherProofingTypes = proofingTypes.filter(
		(t) => t.code !== 'IDENTITY_PROOFING' && t.code !== 'ADDRESS_PROOFING'
	);

	/* Record of all Identity frameworks */
	const identityFrameworks: IProofingFramework[] = proofingFrameworks.filter(
		(f) => f.proofingTag.code === 'IDENTITY_PROOFING'
	);

	/* Record of all Current Address frameworks */
	const currentAddressFrameworks: IProofingFramework[] = proofingFrameworks.filter(
		(f) => f.proofingTag.code === 'ADDRESS_PROOFING'
	);

	/* Key value pair of other proofing types and their frameworks*/
	const otherFrameworks: Record<string, IProofingFramework[]> = {};
	otherProofingTypes.forEach((p) => {
		otherFrameworks[p.name] = proofingFrameworks.filter((f) => f.proofingTag.code === p.code);
	});

	React.useEffect(() => {
		const defaultIdentityFramework = getDefaultProofingFramework('IDENTITY_PROOFING');

		const defaultCurrentAddressFramework =
			settings?.currentAddressProofing?.requiredOverridable &&
			settings?.currentAddressProofing?.required
				? getDefaultProofingFramework('ADDRESS_PROOFING')
				: null;

		const selectedProofingFrameworks = {
			IDENTITY_PROOFING: defaultIdentityFramework,
			ADDRESS_PROOFING: defaultCurrentAddressFramework,
		};

		otherProofingTypes.forEach((type) => {
			selectedProofingFrameworks[type.code] = getDefaultProofingFramework(type.code);
		});

		setSelectedFrameworks(selectedProofingFrameworks);
	}, [JSON.stringify(proofingFrameworks)]);

	React.useEffect(() => {
		setInviteData((prev) => ({
			...prev,
			options: {
				...prev.options,
				proofingFrameworks: Object.keys(selectedFrameworks)
					.map((f) => selectedFrameworks[f])
					.filter((f) => Boolean(f)) as IProofingFramework[],
			},
		}));
	}, [selectedFrameworks]);

	const getDefaultProofingFramework = (proofingCode: string) => {
		switch (proofingCode) {
			case 'IDENTITY_PROOFING':
				return identityFrameworks.find((f) => Boolean(f.isDefault)) || identityFrameworks[0];
			case 'ADDRESS_PROOFING':
				return (
					currentAddressFrameworks.find((f) => Boolean(f.isDefault)) || currentAddressFrameworks[0]
				);
			default:
				const frameworks = proofingFrameworks.filter((f) => f.proofingTag.code === proofingCode);
				return frameworks.find((f) => Boolean(f.isDefault)) || frameworks[0];
		}
	};

	const isSelected = (f: IProofingFramework) => {
		const proofingType = f.proofingTag.code;
		return Boolean(selectedFrameworks[proofingType]?.code === f.code);
	};

	const handleProofingTypeChange = (proofingCode: string) => {
		if (proofingCode === 'ADDRESS_PROOFING') {
			setInviteData((prev) => ({
				...prev,
				options: {
					...prev.options,
					currentAddressProofingRequired: !prev.options.currentAddressProofingRequired,
				},
			}));
		}

		if (selectedFrameworks[proofingCode]) {
			setSelectedFrameworks((prev) => ({
				...prev,
				[proofingCode]: null,
			}));
		} else {
			setSelectedFrameworks((prev) => ({
				...prev,
				[proofingCode]: getDefaultProofingFramework(proofingCode),
			}));
		}
	};

	const handleFrameworkClick = (proofingCode: string, framework: IProofingFramework) => {
		if (selectedFrameworks[proofingCode]?.code !== framework.code) {
			setSelectedFrameworks((prev) => ({
				...prev,
				[proofingCode]: framework,
			}));
		}
	};

	const validate = () => {
		const validEmail = new RegExp(
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		);
		const validAlphabets = new RegExp(/^[a-zA-Z ]+$/);
		const validAlphaNumeric = new RegExp(/^[a-zA-Z0-9 ]+$/);
		const errors: Record<string, boolean> = {};

		// Check if either email or mobileNumber is provided
		if (!inviteData.identityOwner.email && !inviteData.identityOwner.mobileNumber) {
			errors.email = true; // Indicate that at least one of them is required
			errors.mobileNumber = true; // Indicate that at least one of them is required
		} else {
			if (inviteData.identityOwner.email && !inviteData.identityOwner.email.match(validEmail)) {
				errors.email = true;
			}

			if (
				inviteData.identityOwner.mobileNumber &&
				!isValidMobile(inviteData.identityOwner.mobileNumber)
			) {
				errors.mobileNumber = true;
			}
		}

		if (inviteData.identityOwner.givenName && !inviteData.identityOwner.givenName.match(validAlphabets)) {
			errors.givenName = true;
		}

		if (
			inviteData.identityOwner.middleName &&
			!inviteData.identityOwner.middleName.match(validAlphabets)
		) {
			errors.middleName = true;
		}
		if (
			inviteData.options.identityOwnerCheckSource === 'IDENTITY_OWNER' &&
			!inviteData.identityOwner.familyName
		) {
			errors.familyName = true;
		} else if (
			inviteData.identityOwner.familyName &&
			!inviteData.identityOwner.familyName?.match(validAlphabets)
		) {
			errors.familyName = true;
		}

		if (
			inviteData.options.identityOwnerCheckSource === 'IDENTITY_OWNER' &&
			!inviteData.identityOwner.dateOfBirth
		) {
			errors.dateOfBirth = true;
		}

		if (inviteData.externalRefId && !inviteData.externalRefId.match(validAlphaNumeric)) {
			errors.externalRefId = true;
		}

		setErrors(errors);
		return isEmpty(errors);
	};
	const handleSubmit = () => {
		setFormSubmitted(true);
		submitData();
	};

	const submitData = () => {
		if (validate()) {
			generateInviteUrl({
				...inviteData,
				options: {
					...inviteData.options,
					proofingFrameworks: Object.keys(selectedFrameworks)
						.map((f) => selectedFrameworks[f])
						.filter((f) => Boolean(f))
						.map((f) => f?.code as string),
				},
			})
				.then((res: any) => {
					if (res.status === 'success') {
						setGeneratedUrl(res.res.inviteUrl);
						if (scrollRef?.current) {
							scrollRef?.current?.scrollIntoView({ behavior: 'smooth' });
						}
					} else {
						console.error('generate invite url error');
					}
				})
				.catch((err: any) => {
					console.error('invite for kyc error', err);
				});
		}
	};

	const closeInviteScreen = () => {
		setShowV2InviteScreen(false);
	};

	const handleCopyToClipboard = () => {
		if (generatedUrl) {
			const elem = document.createElement('p');
			elem.textContent = generatedUrl;
			document.body.appendChild(elem);

			const selection = document.getSelection() as any;
			const range = document.createRange();

			range.selectNode(elem);
			selection.removeAllRanges();
			selection.addRange(range);
			document.execCommand('copy');

			selection.removeAllRanges();
			document.body.removeChild(elem);
			sendCopyToClipboard();
		}
	};

	const handleReportSelect = (props: any) => {
		setInviteData({
			...inviteData,
			options: {
				...inviteData.options,
				[props.code]: !inviteData.options[props.code],
			},
		});
	};

	const handleIdentityOwnerCheckSourceChange = (source: IdentityOwnerCheckSource) => {
		setInviteData({
			...inviteData,
			options: {
				...inviteData.options,
				identityOwnerCheckSource: source,
			},
		});
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		if (['email', 'familyName', 'givenName', 'middleName', 'gender', 'dateOfBirth'].includes(name)) {
			// Update fields within identityOwner
			setInviteData((prev) => ({
				...prev,
				identityOwner: {
					...prev.identityOwner,
					[name]: value,
				},
			}));
		} else {
			// Update other fields directly on inviteData
			setInviteData((prev) => ({
				...prev,
				[name]: value,
			}));
		}
	};

	const handleInputPhoneNumberChange = (val: any) => {
		setInviteData((prev) => ({
			...prev,
			identityOwner: {
				...prev.identityOwner,
				mobileNumber: val,
			},
		}));
	};

	return (
		<EmailInviteContainer>
			<Stack
				direction='row'
				alignItems='center'
				justifyContent='space-between'
				sx={{ marginBottom: '1rem' }}
			>
				<SectionHeader>Customer Information</SectionHeader>
				<Stack direction='row' alignItems='center' justifyContent='space-between'>
					<Stack
						direction='row'
						alignItems='center'
						sx={{ cursor: 'pointer' }}
						onClick={closeInviteScreen}
					>
						<TextButton startIcon={<CancelIcon />}>Cancel</TextButton>
					</Stack>
					{!generatedUrl && (
						<Stack
							direction='row'
							alignItems='center'
							justifyContent='space-between'
							onClick={handleSubmit}
							sx={{ marginLeft: '24px', cursor: 'pointer' }}
						>
							<TextButton startIcon={<GenerateInviteIcon />}>Generate Link</TextButton>
						</Stack>
					)}
				</Stack>
			</Stack>
			<Grid container spacing={2} sx={{ paddingBottom: '24px', borderBottom: '1px solid #EDEDF3' }}>
				<Grid item xs={12} sm={6} md={4}>
					<Box>
						<TruuthTextField
							id={`given-name`}
							type='text'
							name='givenName'
							label='Given Name'
							onChange={handleChange}
							variant='standard'
							fullWidth
							error={errors.givenName}
							helperText={errors.givenName ? 'Enter only Alphabetic characters and space' : ''}
							inputProps={{
								maxLength: 30,
								pattern: '^[a-zA-Z][a-zA-Z ]*[a-zA-Z]',
								title: 'Enter only Alphabetic characters and space',
							}}
						/>
					</Box>
				</Grid>

				<Grid item xs={12} sm={6} md={4}>
					<Box>
						<TruuthTextField
							id={`middle-name`}
							type='text'
							name='middleName'
							label='Middle Name'
							onChange={handleChange}
							variant='standard'
							fullWidth
							error={errors.middleName}
							helperText={errors.middleName ? 'Enter only Alphabetic characters and space' : ''}
							inputProps={{
								maxLength: 30,
								pattern: '^[a-zA-Z][a-zA-Z ]*[a-zA-Z]',
								title: 'Enter only Alphabetic characters and space',
							}}
						/>
					</Box>
				</Grid>

				<Grid item xs={12} sm={6} md={4}>
					<Box>
						<TruuthTextField
							id={`family-name`}
							type='text'
							name='familyName'
							label='Family Name'
							onChange={handleChange}
							variant='standard'
							fullWidth
							error={errors.familyName}
							helperText={
								errors.familyName
									? !inviteData.identityOwner.familyName
										? 'Please enter the family name'
										: 'Enter only Alphabetic characters and space'
									: ''
							}
							inputProps={{
								maxLength: 35,
								pattern: '^[a-zA-Z][a-zA-Z ]*[a-zA-Z]',
								// title: 'Enter only Alphabetic characters and space',
							}}
						/>
					</Box>
				</Grid>

				<Grid item xs={12} sm={6} md={4}>
					<Box>
						<TruuthTextField
							id={`customer-email-id`}
							type='email'
							name='email'
							label='Customer Email ID'
							required={true}
							onChange={handleChange}
							variant='standard'
							fullWidth
							error={errors.email}
							helperText={
								errors.email
									? !inviteData.identityOwner.email
										? 'Email address or mobile number of identity owner must be provided'
										: 'Please enter a valid email'
									: ''
							}
							autoFocus
							inputProps={{
								maxLength: 319,
								pattern:
									'(^(?=.{1,64}@)([_A-Za-z0-9-+!#$%&‘*+–/=?^_`{|}~]+(.[_A-Za-z0-9-+!#$%&‘*+–/=?^_`{|}~]+)*))@[A-Za-z0-9-]+(.[A-Za-z0-9]+)*(.[A-Za-z]{2,})$',
							}}
						/>
					</Box>
				</Grid>

				<Grid item xs={12} sm={6} md={4}>
					<Box>
						<TruuthTextField
							id={`customer-reference`}
							type='text'
							name='externalRefId'
							label='Customer Reference'
							onChange={handleChange}
							variant='standard'
							fullWidth
							error={errors.externalRefId}
							helperText={errors.externalRefId ? 'Enter only Alphanumeric characters' : ''}
							inputProps={{
								maxLength: 40,
								pattern: '[a-zA-Z0-9 ]+',
								title: 'Enter only AlphabeticNumeric characters',
							}}
						/>
					</Box>
				</Grid>

				<Grid item xs={12} sm={6} md={4}>
					<Box>
						<TruuthTextField
							id={`gender`}
							select
							name='gender'
							label='Gender'
							value={inviteData.identityOwner.gender}
							onChange={handleChange}
							variant='standard'
							error={errors.gender}
							helperText={errors.gender ? 'Select a gender' : ''}
							fullWidth
						>
							<MenuItem value='male'>Male</MenuItem>
							<MenuItem value='female'>Female</MenuItem>
							<MenuItem value='other'>Other</MenuItem>
						</TruuthTextField>
					</Box>
				</Grid>

				<Grid item xs={12} sm={6} md={4}>
					<Box>
						<LocalizationProvider dateAdapter={AdapterDateFns}>
							<DatePicker
								label='Date of Birth'
								value={
									inviteData.identityOwner.dateOfBirth
										? parseISO(inviteData.identityOwner.dateOfBirth)
										: null
								}
								maxDate={new Date()}
								onChange={(newValue) => {
									setInviteData((prev) => ({
										...prev,
										identityOwner: {
											...prev.identityOwner,
											dateOfBirth: newValue ? format(newValue, 'yyyy-MM-dd') : '',
										},
									}));
								}}
								slots={{
									textField: FullWidthTruuthTextField,
									popper: (props) => <Popper {...props} placement='bottom-start' />,
								}}
								slotProps={{
									textField: {
										error: Boolean(errors.dateOfBirth),
										helperText: errors.dateOfBirth
											? 'Please enter a valid date of birth'
											: '',
									},
								}}
							/>
						</LocalizationProvider>
					</Box>
				</Grid>

				<Grid item xs={12} sm={6} md={4}>
					<Box>
						<PhoneInput
							id='mobile-number'
							name='mobileNumber'
							label='Mobile Number'
							defaultCountry={region?.countryCode}
							value={inviteData.identityOwner.mobileNumber as Value}
							onChange={(value) =>
								setInviteData((prev) => ({
									...prev,
									identityOwner: {
										...prev.identityOwner,
										mobileNumber: value || '',
									},
								}))
							}
							error={
								errors.mobileNumber
									? !inviteData.identityOwner.mobileNumber
										? 'Email address or mobile number of identity owner must be provided'
										: 'Please enter a valid mobile number'
									: ''
							}
							inputComponent={CustomPhoneInput}
						/>
					</Box>
				</Grid>
			</Grid>

			{settings?.identityOwnerCheck?.requiredOverridable && (
				<Stack
					direction='column'
					sx={{ padding: '16px 0 24px 0', borderBottom: '1px solid #EDEDF3' }}
				>
					<SectionHeader sx={{ marginBottom: '1rem' }}>Identity Owner Rules</SectionHeader>
					<Stack direction='row' spacing={2}>
						{settings?.identityOwnerCheck?.sources.map((source) => (
							<TruuthChip
								code={source.code}
								label={source.description}
								key={source.code}
								selected={inviteData?.options?.identityOwnerCheckSource === source.code}
								isMultipleSelection={false}
								variant='outlined'
								background='color'
								onClick={() =>
									handleIdentityOwnerCheckSourceChange(
										source.code as IdentityOwnerCheckSource
									)
								}
							/>
						))}
					</Stack>
				</Stack>
			)}

			{(settings?.sanctionsAndPepVerification?.requiredOverridable ||
				settings?.businessVerification?.requiredOverridable) && (
				<Stack
					direction='column'
					sx={{ padding: '16px 0 24px 0', borderBottom: '1px solid #EDEDF3' }}
				>
					<SectionHeader sx={{ marginBottom: '1rem' }}>Reports Including</SectionHeader>

					<Stack direction='row' spacing={2}>
						{settings?.sanctionsAndPepVerification?.requiredOverridable && (
							<TruuthChip
								code='sanctionsAndPepCheckRequired'
								label='Pep and Sanctions Report'
								key='sanctionsAndPepCheckRequired'
								selected={inviteData?.options?.sanctionsAndPepCheckRequired}
								isMultipleSelection={true}
								variant='outlined'
								background='color'
								onClick={handleReportSelect}
							/>
						)}
						{settings?.businessVerification?.requiredOverridable && (
							<TruuthChip
								code='businessVerificationRequired'
								label='Company Enquiry Report'
								key='businessVerificationRequired'
								selected={inviteData?.options?.businessVerificationRequired}
								isMultipleSelection={true}
								variant='outlined'
								background='color'
								onClick={handleReportSelect}
							/>
						)}
					</Stack>
				</Stack>
			)}

			<Stack direction='column' sx={{ padding: '16px 0 24px 0' }}>
				<SectionHeader>Proofing Type & Framework</SectionHeader>
				<span
					style={{
						marginTop: '4px',
						fontFamily: 'Inter, sans-serif',
						fontWeight: 400,
						fontSize: '14px',
						lineHeight: '24px',
						color: '#515170',
						display: 'block',
					}}
				>
					Select proofing type and its framework
				</span>
				<Table sx={{ marginTop: '24px' }}>
					<TableHead>
						<TableRow>
							<TableCell sx={{ borderRight: '1px solid #E7E7EF' }}>Proofing Type</TableCell>
							<TableCell align='left' colSpan={5}>
								Framework
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<TableRow
							key='identity'
							sx={{ '&:last-child td, &:last-child th': { borderBottom: 0 } }}
						>
							<TableCell sx={{ borderRight: '1px solid #E7E7EF' }}>
								{identityFrameworks[0]?.proofingTag?.name || 'Identity Proofing'}
							</TableCell>
							<TableCell>
								<Stack direction='row' spacing={2} sx={{ flexWrap: 'wrap' }}>
									{identityFrameworks.map((f, index) => (
										<TruuthChip
											code={f.code}
											label={f.name}
											key={f.code}
											selected={isSelected(f)}
											isMultipleSelection={false}
											variant='outlined'
											background='color'
											onClick={() => handleFrameworkClick('IDENTITY_PROOFING', f)}
										/>
									))}
								</Stack>
							</TableCell>
						</TableRow>

						{settings?.currentAddressProofing.requiredOverridable && (
							<TableRow
								key='currentAddress'
								sx={{ '&:last-child td, &:last-child th': { borderBottom: 0 } }}
							>
								<TableCell sx={{ borderRight: '1px solid #E7E7EF' }}>
									<TruuthChip
										code='currentAddress'
										label={
											currentAddressFrameworks[0]?.proofingTag?.name ||
											'Current Address Proofing'
										}
										selected={inviteData?.options?.currentAddressProofingRequired}
										isMultipleSelection={true}
										variant='outlined'
										background='color'
										onClick={() => handleProofingTypeChange('ADDRESS_PROOFING')}
									/>
								</TableCell>
								<TableCell>
									<Stack direction='row' spacing={2} sx={{ flexWrap: 'wrap' }}>
										{currentAddressFrameworks.map((f, index) => (
											<TruuthChip
												code={f.code}
												label={f.name}
												key={f.code}
												selected={isSelected(f)}
												disabled={!selectedFrameworks['ADDRESS_PROOFING']}
												isMultipleSelection={false}
												variant='outlined'
												background='color'
												onClick={() => handleFrameworkClick('ADDRESS_PROOFING', f)}
												sx={{ flex: '1 1 auto', margin: '10px' }}
											/>
										))}
									</Stack>
								</TableCell>
							</TableRow>
						)}

						{Object.keys(otherFrameworks)?.map((type) => (
							<TableRow sx={{ '&:last-child td, &:last-child th': { borderBottom: 0 } }}>
								<TableCell>
									<TruuthChip
										code={type}
										label={type}
										key={type}
										selected={inviteData?.options?.currentAddressProofingRequired}
										isMultipleSelection={true}
										variant='outlined'
										background='color'
										onClick={() => handleProofingTypeChange(type)}
									/>
								</TableCell>

								<TableCell>
									<Stack direction='row' spacing={2} sx={{ flexWrap: 'wrap' }}>
										{otherFrameworks[type].map((f) => (
											<TruuthChip
												code={f.code}
												label={f.name}
												key={f.code}
												selected={isSelected(f)}
												disabled={!selectedFrameworks[type]}
												isMultipleSelection={false}
												variant='outlined'
												background='color'
												onClick={() => handleFrameworkClick(type, f)}
											/>
										))}
									</Stack>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</Stack>
			{generatedUrl && (
				<Stack direction='row' alignItems='center' spacing={2}>
					<TruuthTextField
						id={`invite-url`}
						type='inviteUrl'
						name='inviteUrl'
						label='Link'
						variant='standard'
						autoFocus
						value={generatedUrl}
						sx={{ minWidth: 400 }}
					/>
					<Stack
						direction='row'
						alignItems='center'
						spacing={1}
						sx={{ cursor: 'pointer' }}
						onClick={handleCopyToClipboard}
					>
						<TextButton startIcon={<CopyIcon />}>Copy</TextButton>
					</Stack>
				</Stack>
			)}
			<div ref={scrollRef}></div>
		</EmailInviteContainer>
	);
};
