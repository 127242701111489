/**
 * Tenant List
 */
export const SET_TENANT_LIST = 'tenant/SET_TENANT_LIST' as const;
export const SET_TENANT_ALIAS = 'tenant/SET_TENANT_ALIAS' as const;
export const SET_TENANT = 'tenant/SET_TENANT' as const;
export const SET_PLAN = 'tenant/SET_PLAN' as const;
export const SET_TENANT_COUNTRY_LIST = 'tenant/SET_TENANT_COUNTRY_LIST' as const;

/**
 * Tenant Filter
 */
export const SET_TENANT_FILTER = 'tenant/SET_TENANT_FILTER' as const;
export const SET_TENANT_KYCSTATE = 'tenant/SET_TENANT_KYCSTATE' as const;
