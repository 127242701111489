import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { Card, CardHeader, Avatar, IconButton, Theme } from '@mui/material';
import _ from 'lodash';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';

import { ContentText, ContentBreak } from 'shared_components/src/components/common';
import {
	VerificationDetailInformation,
	VerifictionDetailCustomerData,
	VerifictionDetailDocumentData,
	VerificationReopenModal,
	VerificationReports,
} from 'shared_components/src/components/verification';
import { TEXT_COLOR, cardStyles } from 'shared_components/src/common/styles';
import { getIsMobile } from 'shared_components/src/common/utils';
import {
	IVerificationDocument,
	IVerificationDetail,
	IIdentityDocumentItem,
	IIdentityDocumentMetaData,
	VERIFICATION_DOCUMENT_TYPES,
	VERIFICATION_RESULT_VALUE,
} from 'shared_components/src/service/models/verification';

import { updateMenu } from '../../store/menu/actions';
import { MENU } from '../../common/routes/menu';
import { setLoading, clearLoading, setNotification } from '../../store/common/actions';
import KycApiService from '../../service/kycApi.service';
import {
	setVerificationEditDetailState,
	setVerificationEditResultState,
} from '../../store/verification/actions';
import { _getAuthRoles, _getVerificationDetailStates } from '../../store/selectors';
import { NOTIFICATION_STATES, VERIFICATION_IMAGE_SIDES } from 'shared_components/src/common/constants';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { _getSettings } from '../../store/selectors';

const download = require('downloadjs');

/**
 * Types and Constants
 */
interface ParamTypes {
	tenantAlias: string;
	verId: string;
}

/*
 * Styles
 */
const useStyles = makeStyles((theme: Theme) => ({
	root: {
		padding: 0,
		'& > .MuiCardHeader-root': {
			padding: '20px 45px 20px 45px',
			marginBottom: 0,
			alignItems: 'center',

			'@media screen and (max-width: 600px)': {
				padding: '14px 20px 14px 20px',
				minHeight: 70,
			},
		},

		'& .MuiCardHeader-title ': {
			marginTop: 30,

			'& .title': {
				fontSize: 24,
				lineHeight: 1.33,
			},

			'& .verification-id': {
				fontSize: 16,
				lineHeight: 1.31,
				fontWeight: 500,
			},

			'@media screen and (max-width: 600px)': {
				textAlign: 'center',
				fontFamily: 'Roboto',
				fontSize: 17,
				fontWeight: 600,
				lineHeight: 1.35,

				marginTop: 25,
			},
		},

		'& .MuiCardHeader-avatar': {
			marginRight: 0,
		},

		'& .MuiCardHeader-action': {
			'@media screen and (max-width: 600px)': {
				width: 'auto',
			},
		},
	},

	avatar: {
		'&.MuiAvatar-root': {
			backgroundColor: 'transparent',
			width: 'fit-content',
			height: 'fit-content',
			marginLeft: -12,

			'& button': {
				marginLeft: 0,
			},

			'& svg': {
				color: '#000000',
				width: 30,
				height: 'auto',
			},

			'@media screen and (max-width: 600px)': {
				marginLeft: 0,

				'& button': {
					padding: 0,
				},

				'& svg': {
					width: 24,
					height: 24,
				},
			},
		},
	},

	item: {
		marginBottom: 28,
		'& .name': {
			fontSize: 14,
			lineHeight: 1.3125,
			color: TEXT_COLOR.graylight,
			marginBottom: 4,
		},
		'& .value': {
			fontSize: 16,
			lineHeight: 1.5,
			color: theme.palette.grey[900],
		},
	},

	content: {
		padding: '32px 35px 20px 35px',
		flexWrap: 'nowrap',
	},

	detail: {
		marginBottom: '4px !important',

		'& > .MuiCardHeader-root': {
			padding: 0,
		},

		'& .MuiAvatar-root': {
			width: 80,
			height: 80,
		},

		'& .MuiCardHeader-title': {
			fontSize: 20,
			lineHeight: 1.3,
			color: theme.palette.grey[900],
		},

		'& .MuiCardHeader-subheader': {
			fontSize: 18,
			lineHeight: 1.333333,
			color: theme.palette.grey[900],
		},
	},

	values: {
		'&> .title': {
			marginBottom: 35,
			marginTop: 35,
			fontSize: 26,
			lineHeight: 1.3077,
			color: theme.palette.grey[900],
		},
	},

	rightValues: {
		paddingLeft: 22.5,
	},

	editButton: {
		width: '44.03%',
		marginLeft: '7.863%',
		maxWidth: 201,

		'@media screen and (max-width: 1280px)': {
			paddingTop: 11,
			paddingBottom: 11,
			maxWidth: 148,
		},
		'@media screen and (max-width: 600px)': {
			width: 'auto',
		},
	},

	saveButton: {
		width: '44.03%',
		marginLeft: '7.863%',
		maxWidth: 201,
		fontSize: 16,
		lineHeight: 1.36,

		'@media screen and (max-width: 1280px)': {
			paddingTop: 11,
			paddingBottom: 11,
			maxWidth: 148,
			fontSize: 14,
		},
		'@media screen and (max-width: 600px)': {
			padding: 20,
		},
	},

	cancelButton: {
		width: '44.03%',
		maxWidth: 201,
		fontSize: 16,
		lineHeight: 1.36,

		'@media screen and (max-width: 1280px)': {
			paddingTop: 11,
			paddingBottom: 11,
			maxWidth: 148,
			fontSize: 14,
		},
		'@media screen and (max-width: 600px)': {
			padding: 20,
		},
	},

	buttons: {
		padding: '20px 20px 40px 20px',
		justifyContent: 'space-evenly',
	},
}));

/**
 * Main Component
 */
const VerificationDetailPage = ({ isDocumentProofingEnabled }: { isDocumentProofingEnabled: boolean }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { tenantAlias: alias, verId } = useParams();
	// const featureFlags = useFlags();
	// const ldClient = useLDClient();
	const settings = _getSettings();

	// const isDocumentProofingEnabled = featureFlags['document-proofing-feature'];

	/**
	 * Use Styles
	 */
	const classes = useStyles();
	const cardClasses = cardStyles();

	/**
	 * States
	 */
	const [isMobile, setIsMobile] = useState(getIsMobile());
	const [isEditable, setIsEditable] = useState(false);
	const [tenantAlias, setTenantAlias] = useState('');
	const [verificationID, setVerificationID] = useState('');
	const [verificationData, setVerificationData] = useState({} as IVerificationDocument);
	const [tempVerificationData, setTempVerificationData] = useState({} as IVerificationDocument);
	const [informationData, setInformationData] = useState({});
	const [customerData, setCustomerData] = useState({} as IVerificationDetail);
	const [openReopen, setOpenReopen] = useState(false);
	const [documentFields, setDocumentFields] = useState<IIdentityDocumentMetaData[]>([]);

	const authRoles = _getAuthRoles();
	const editStates = _getVerificationDetailStates();

	/**
	 * Initialize when didMount and updating
	 */
	useEffect(() => {
		// Set function for window resize
		window.addEventListener('resize', () => {
			setIsMobile(getIsMobile());
		});
	}, []);

	useEffect(() => {
		// Get editable via URL
		if (alias == undefined || verId == undefined) {
			navigate(`/tenant/${tenantAlias}/verification`);
		} else {
			setVerificationID(verId);
			setTenantAlias(alias);
		}

		// Set menu key
		dispatch(updateMenu(MENU.verificaton));
	}, [window.location.pathname]);

	// useEffect(() => {
	// 	ldClient && ldClient.identify({ key: tenantAlias });
	// }, [tenantAlias]);

	useEffect(() => {
		setIsEditable(false);
	}, [authRoles]);

	/**
	 * Get information for verification detail via API
	 */
	useEffect(() => {
		getVerificationData();
	}, [tenantAlias, verificationID]);

	useEffect(() => {
		if (
			isDocumentProofingEnabled &&
			verificationID &&
			verificationData?.results?.identityDocuments?.length
		) {
			getDocumentFields();
		}
	}, [verificationData?.results?.identityDocuments?.length]);

	const getVerificationData = () => {
		if (verificationID) {
			dispatch(setLoading());
			KycApiService.getVerificationDetail(verificationID)
				.then(async (res: any) => {
					dispatch(clearLoading());

					if (res && !res?.error) {
						await processData(res);
						setTempVerificationData(res);
					} else {
						navigate('/not-match');
					}
				})
				.catch((err: any) => {
					dispatch(clearLoading());
					navigate('/not-match');
					console.log('verification detail error', err);
				});
		}
	};

	const getDocumentFields = async () => {
		dispatch(setLoading());

		await Promise.all(
			verificationData?.results?.identityDocuments?.map(async (doc) => {
				try {
					if (doc.documentClassificationCode) {
						const metaData = await KycApiService.getDocumentFields(
							doc?.documentType,
							doc?.documentClassificationCode
						);

						setDocumentFields((prev) => [...prev, metaData]);
						dispatch(clearLoading());
					}
				} catch (err) {
					console.dir(err);
					dispatch(clearLoading());
				}
			}) || []
		);
	};

	const processData = async (docData: IVerificationDocument, reload = true) => {
		const detailData: IVerificationDocument = docData;
		let _details: IVerificationDetail[] = [];

		await Promise.all(
			detailData.results?.identityDocuments?.map(async (item: IVerificationDetail, index: number) => {
				const _images: any = item.images;

				let images: any = {
					frontImageUrl: null,
					backImageUrl: null,
				};

				if (reload) {
					await Promise.all(
						Object.keys(_images).map(async (key: string) => {
							let _imgData = '';
							if (_images[key] !== 'N/A') {
								_imgData = (await getImageData(
									docData.verificationId,
									item.documentId || item.documentType,
									VERIFICATION_IMAGE_SIDES[key].query
								)) as string;
							} else {
								_imgData = 'N/A';
							}
							images[key] = _imgData;
						})
					);
				} else {
					const imageUrls = verificationData?.results?.identityDocuments?.[index]?.images;
					images.frontImageUrl = imageUrls?.frontImageUrl ?? '';
					images.backImageUrl = imageUrls?.backImageUrl ?? '';
				}

				_details.push({
					...item,
					images,
				});
			}) || []
		);

		// Store total data
		const data = {
			...detailData,
			results: {
				...detailData.results,
				identityDocuments: _details,
			},
		} as IVerificationDocument;

		setVerificationData(data);

		let _customerData;

		if (!isDocumentProofingEnabled) {
			// Store customer data
			_customerData = docData.results?.identityDocuments?.find(
				(item: IVerificationDetail) =>
					item.documentType === VERIFICATION_DOCUMENT_TYPES.DRIVERS_LICENCE
			);
			_customerData =
				!_customerData || !_customerData?.documentData
					? docData.results?.identityDocuments?.find(
							(item: IVerificationDetail) =>
								item.documentType === VERIFICATION_DOCUMENT_TYPES.PASSPORT
					  )
					: _customerData;
			_customerData =
				!_customerData || !_customerData?.documentData
					? docData.results?.identityDocuments?.find(
							(item: IVerificationDetail) =>
								item.documentType === VERIFICATION_DOCUMENT_TYPES.MEDICARE_CARD
					  )
					: _customerData;
			_customerData =
				!_customerData || !_customerData?.documentData
					? docData.results?.identityDocuments?.find(
							(item: IVerificationDetail) =>
								item.documentType === VERIFICATION_DOCUMENT_TYPES.PROOF_OF_AGE_CARD
					  )
					: _customerData;
			_customerData =
				!_customerData || !_customerData?.documentData
					? docData.results?.identityDocuments?.find(
							(item: IVerificationDetail) =>
								item.documentType === VERIFICATION_DOCUMENT_TYPES.IDENTITY_CARD
					  )
					: _customerData;
		} else {
			const documents = docData?.results?.identityDocuments;

			if (documents?.length) {
				for (let i = 0; i < documents?.length; i++) {
					const documentData = documents[i].documentData as IIdentityDocumentItem[];

					if (documentData?.length) {
						_customerData = documents[i];
					}
				}
			}
		}

		const _informationData: any = {
			firstName: '',
			lastName: '',
			email: docData.inviteeDetails.email ?? '',
			externalRefId: docData.externalRefId,
			verificationResult: docData.results?.verificationStatus,
			verificationReason: docData.results?.failureReasons,
			verificationStatus: docData?.status,
			face: '',
			livenessResult: docData.results?.liveness,
		};

		if (_customerData) {
			const faceUrl =
				reload &&
				(docData?.results?.face?.imageUrl ? await getFaceImageData(docData.verificationId) : '');

			if (
				!isDocumentProofingEnabled &&
				_customerData?.documentData &&
				('dateOfBirth' in _customerData?.documentData || 'givenName' in _customerData?.documentData)
			) {
				_informationData.firstName = _customerData?.documentData?.firstName?.value ?? '';
				_informationData.lastName = _customerData?.documentData?.lastName?.value ?? '';
			} else if (
				isDocumentProofingEnabled &&
				_customerData?.documentData &&
				!('dateOfBirth' in _customerData?.documentData || 'givenName' in _customerData?.documentData)
			) {
				_informationData.firstName =
					_customerData?.documentData?.find((d) => d.key === 'firstName' || d.key === 'givenName')
						?.value ?? '';
				_informationData.lastName =
					_customerData?.documentData.find((d) => d.key === 'lastName' || d.key === 'familyName')
						?.value ?? '';
			}

			_informationData.face = reload ? faceUrl : _.get(informationData, 'face');
			setCustomerData(_customerData);
		} else {
			const faceUrl =
				reload &&
				(docData?.results?.face?.imageUrl ? await getFaceImageData(docData.verificationId) : '');

			_informationData.face = reload ? faceUrl : _.get(informationData, 'face');
		}

		setIsEditable(false);
		setInformationData(_informationData);

		return data;
	};

	/**
	 * Get document images
	 */
	const getImageData = (verId: string, docId: string, side: string) => {
		return new Promise((resolve) => {
			dispatch(setLoading());
			KycApiService.getVerificationImageByUrl(verId, docId, side)
				.then((res) => {
					dispatch(clearLoading());
					if (res.error) {
						resolve('');
					}
					resolve(res.image);
				})
				.catch((err) => {
					dispatch(clearLoading());
					resolve('');
				});
		});
	};

	/**
	 * Get face image
	 */
	const getFaceImageData = (id: string) => {
		return new Promise((resolve) => {
			dispatch(setLoading());
			KycApiService.getFaceImageByUrl(id)
				.then((res) => {
					dispatch(clearLoading());
					if (res.error) {
						resolve('');
					}
					resolve(res.image);
				})
				.catch((err) => {
					dispatch(clearLoading());
					resolve('');
				});
		});
	};

	/**
	 * Different handles
	 */
	const handleEditResult = () => {
		dispatch(setVerificationEditResultState(true));
	};

	const handleEditDetail = () => {
		dispatch(setVerificationEditDetailState(true));
	};

	const handleSaveResult = () => {
		const _data = { ...verificationData };
		const req = {
			verificationStatus: _data?.results?.identityDocuments ?? '',
		};

		dispatch(setLoading());
		KycApiService.updateVerificationDetails(verificationID, req)
			.then((res) => {
				if (!res?.errror) {
					dispatch(
						setNotification({
							message: 'Failed to update verification result',
							type: NOTIFICATION_STATES.error,
						})
					);
				} else {
					getVerificationData();
					dispatch(
						setNotification({
							message: 'Details updated successfully',
							type: NOTIFICATION_STATES.success,
						})
					);
				}
			})
			.catch((err) => {
				dispatch(
					setNotification({
						message: 'Failed to update verification result',
						type: NOTIFICATION_STATES.error,
					})
				);
			})
			.finally(() => {
				dispatch(clearLoading());
				dispatch(setVerificationEditResultState(false));
			});
	};

	const handleSaveDetail = () => {
		const _data = { ...verificationData };
		const detailData = _data.results?.identityDocuments?.map(
			(item: IVerificationDetail, index: number) => {
				return {
					documentType: item.documentType,
					documentData: item.documentData,
					verificationStatus: item.verificationStatus,
					security: item.security,
				};
			}
		);
		const req = {
			verificationDetails: detailData,
		};

		dispatch(setLoading());
		KycApiService.updateVerificationDetails(verificationID, req)
			.then((res) => {
				if (!res?.error) {
					dispatch(
						setNotification({
							message: 'Details updated successfully',
							type: NOTIFICATION_STATES.success,
						})
					);
				} else {
					dispatch(
						setNotification({
							message: 'Details update failed',
							type: NOTIFICATION_STATES.error,
						})
					);
				}
			})
			.catch((err) => {
				dispatch(
					setNotification({
						message: 'Details update failed',
						type: NOTIFICATION_STATES.error,
					})
				);
			})
			.finally(() => {
				dispatch(setVerificationEditDetailState(false));
				dispatch(clearLoading());
			});
	};

	const handleCancelResult = () => {
		processData(tempVerificationData, false);
		dispatch(setVerificationEditResultState(false));
	};

	const handleCancelDetail = () => {
		processData(tempVerificationData, false);
		dispatch(setVerificationEditDetailState(false));
	};

	const handleReturn = () => {
		navigate(`/tenant/${tenantAlias}/verification`);
	};

	const handleDownloadReport = (report) => {
		const { reportUrl, type } = report;
		const tempArr = reportUrl.split('/') || [];
		const id = tempArr[tempArr.length - 2] || '';
		return new Promise((resolve) => {
			dispatch(setLoading());
			KycApiService.downloadReport(id, type)
				.then((res) => {
					dispatch(clearLoading());
					if (res.error) {
						resolve('');
					}
					resolve(res);

					let fileName = '';
					switch (type) {
						case 'IDV_VERIFICATION_REPORT':
							fileName = 'Identity Verification Report.pdf';
							break;

						case 'AUS_BUSINESS_VERIFICATION_REPORT':
							fileName = 'Company Enquiry Report.pdf';
							break;

						case 'SANCTIONS_AND_PEP_REPORT':
							fileName = 'Pep And Sanctions Report.pdf';
							break;

						default:
							break;
					}
					download(`data:application/pdf;base64,${res?.content}`, fileName);
				})
				.catch((err) => {
					dispatch(clearLoading());
					resolve('');
				});
		});
	};

	const handleChangeDetail = (val: string, documentType: string, key: string) => {
		const _data = { ...verificationData };
		const index = (_data?.results?.identityDocuments ?? []).findIndex(
			(item: IVerificationDetail) => item.documentType === documentType
		);

		if (index != -1) {
			if (_data?.results?.identityDocuments?.[index]) {
				_.set(_data?.results?.identityDocuments?.[index], key, val);
			}
		}

		setVerificationData(_data);
	};

	const handleChangeResult = (val: string) => {
		const _data = { ...verificationData };

		_.set(_data, 'results.identityDocuments', val);
		setVerificationData(_data);
		setInformationData({
			...informationData,
			verificationResult: val,
		});
	};

	const openReopenVerification = () => {
		setOpenReopen(true);
	};

	const handleReopenVerification = () => {
		const req = {
			status: 'PROCESSING',
		};

		dispatch(setLoading());
		KycApiService.updateVerificationDetails(verificationID, req)
			.then((res) => {
				dispatch(clearLoading());
				getVerificationData();
				dispatch(
					setNotification({
						message: 'Re-open this verification successfully',
						type: NOTIFICATION_STATES.success,
					})
				);
				setOpenReopen(false);
			})
			.catch((err) => {
				dispatch(clearLoading());
				dispatch(
					setNotification({
						message: 'Failed to re-open this verification',
						type: NOTIFICATION_STATES.error,
					})
				);
				console.log('reopen verification error', err);
			});
	};

	const handleVerificationDisabled = (res: boolean) => {};

	function formatAMPM(date) {
		var hours = date.getHours();
		var minutes = date.getMinutes();
		var ampm = hours >= 12 ? 'PM' : 'AM';
		hours = hours % 12;
		hours = hours ? hours : 12; // the hour '0' should be '12'
		minutes = minutes < 10 ? '0' + minutes : minutes;

		return `${hours}:${minutes} ${ampm}`;
	}

	const formatDate = (date) => {
		const monthNames = [
			'January',
			'February',
			'March',
			'April',
			'May',
			'June',
			'July',
			'August',
			'September',
			'October',
			'November',
			'December',
		];

		const newDate = new Date(date);

		return `${
			monthNames[newDate.getMonth()]
		} ${newDate.getDate()}, ${newDate.getFullYear()}, ${formatAMPM(newDate)}`;
	};

	/**
	 * Main HTML part
	 */
	return (
		<Card className={clsx(cardClasses.root, classes.root)}>
			<CardHeader
				avatar={
					<Avatar aria-label="recipe" className={classes.avatar}>
						<IconButton
							edge="start"
							color="inherit"
							aria-label="open detail"
							aria-haspopup="true"
							onClick={handleReturn}
						>
							<ArrowBackIcon />
						</IconButton>
					</Avatar>
				}
				title={
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<div>
							<ContentText className="title">KYC Verification Details</ContentText>
							<ContentBreak />
							<ContentText className="verification-id">ID: {verificationID}</ContentText>
						</div>
						{verificationData?.completedAt && (
							<span style={{ fontSize: '1rem' }}>
								Verification completed on {formatDate(verificationData?.completedAt)}
							</span>
						)}
					</div>
				}
			/>
			<VerificationDetailInformation
				data={informationData}
				roles={authRoles}
				editable={isEditable}
				isEdit={editStates.result}
				handleChange={handleChangeResult}
				handleEdit={handleEditResult}
				handleSave={handleSaveResult}
				handleCancel={handleCancelResult}
				handleReopenVerification={openReopenVerification}
				handleVerificationDisabled={handleVerificationDisabled}
			/>
			<VerificationReopenModal
				open={openReopen}
				onClose={() => setOpenReopen(false)}
				handleReopen={handleReopenVerification}
			/>
			<VerifictionDetailCustomerData
				data={customerData}
				isDocumentProofingEnabled={isDocumentProofingEnabled}
			/>
			<VerificationReports
				reports={verificationData?.results?.reports || []}
				handleDownload={handleDownloadReport}
			/>
			<VerifictionDetailDocumentData
				editable={isEditable}
				roles={authRoles}
				isEdit={editStates.detail}
				showOCRData={settings?.reviewEdit?.required}
				data={verificationData?.results?.identityDocuments ?? []}
				handleChange={handleChangeDetail}
				handleEdit={handleEditDetail}
				handleSave={handleSaveDetail}
				handleCancel={handleCancelDetail}
				faceImage={_.get(informationData, 'face') ?? ''}
				documentFields={documentFields}
				isDocumentProofingEnabled={isDocumentProofingEnabled}
			/>
		</Card>
	);
};

export default VerificationDetailPage;
