import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import {
	Box,
	Card,
	CardHeader,
	CardContent,
	FormHelperText,
	FormControlLabel,
	Grid,
	Switch,
	styled,
	Typography,
	TextField,
	Theme,
	Tabs,
	Tab,
} from '@mui/material';

import { IDHealthCheck } from 'shared_components/src/components/tenants';
import { IProofingFramework, ISupportedDocumentMap } from 'shared_components/src/service/models';

import KycApiService from '../../service/kycApi.service';
import { setSettings, resetSettings, setNotification } from '../../store/common/actions';
import { _getSettings } from '../../store/selectors';
import kycApiService from '../../service/kycApi.service';
import { NOTIFICATION_STATES } from 'shared_components/src/common/constants';

/**
 * Styles
 **/

const useStyles = makeStyles(() => ({
	container: {
		'& .MuiPaper-rounded': {
			backgroundColor: '#fafafa',
			padding: 0,
		},

		'& .MuiCardHeader-root': {
			padding: '16px 0',
		},

		'& .MuiCardHeader-title': {
			color: '#343451',
			fontSize: '26px',
			fontWeight: '600',
			lineHeight: '32px',
		},

		'& .MuiCardHeader-subheader': {
			color: '#515170',
			fontSize: '14px',
			fontWeight: '400',
			lineHeight: '24px',
		},

		'& .MuiTabs-root': {
			borderBottom: '2px solid #5D0DA1',
			margin: '0 5px',

			'& .MuiTabs-flexContainer': {
				paddingTop: '5px',
			},

			'& .MuiTab-root': {
				minWidth: '120px',
				padding: '10px 24px',
				marginRight: '4px',
				background: '#FFF',
				borderTopLeftRadius: '5px',
				borderTopRightRadius: '5px',
				textTransform: 'none',
				boxShadow: '1px -1px 15px 0px #20203A14',
				border: '1px solid #E7E7EF',
			},

			'& .Mui-selected': {
				color: '#FFF',
				background: '#5D0DA1',
				border: '1px solid #5D0DA1',
			},

			'& .MuiTabs-indicator': {
				display: 'none',
			},
		},
	},
}));

/**
 * Types
 **/

/**
 * Component
 **/

const IDHealthCheckPage = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const settings = _getSettings();

	const [selectedTab, setSelectedTab] = useState(0);
	const [proofingFrameworks, setProofingFrameworks] = useState<IProofingFramework[]>([]);
	const [supportedDocuments, setSupportedDocuments] = useState<ISupportedDocumentMap>({});

	useEffect(() => {
		KycApiService.getSettings()
			.then((res) => {
				const payload = {
					...res?.features,
				};
				setProofingFrameworks(res.proofingFrameworks || []);
				dispatch(setSettings(payload));
			})
			.catch((err) => {
				dispatch(resetSettings());
			});
	}, []);

	useEffect(() => {
		const fetchSupportedDocuments = async () => {
			await getSupportedDocuments();
		};

		fetchSupportedDocuments();
	}, []);

	const handleTabChange = (event, newValue) => {
		setSelectedTab(newValue);
	};

	const handleIDHealthCheck = (data: any) => {
		return kycApiService.submitIDHealthCheck(data);
	};

	const getSupportedDocuments = async () => {
		try {
			const response = await kycApiService.getSupportedDocuments();
			const uniqueDocumentTypeMap: ISupportedDocumentMap = response.documents.reduce(
				(map, document) => {
					const { documentType, countryCode } = document;
					if (!map[documentType]) {
						map[documentType] = [];
					}
					if (!map[documentType].includes(countryCode)) {
						map[documentType].push(countryCode);
					}
					return map;
				},
				{} as ISupportedDocumentMap
			);

			// Sort country codes within each document type
			Object.keys(uniqueDocumentTypeMap).forEach((documentType) => {
				uniqueDocumentTypeMap[documentType].sort((a, b) => a.localeCompare(b));
			});

			// Sort uniqueDocumentTypeMap alphabetically by documentType
			const sortedUniqueDocumentTypeMap = Object.fromEntries(
				Object.entries(uniqueDocumentTypeMap).sort(([a], [b]) => a.localeCompare(b))
			);

			setSupportedDocuments(sortedUniqueDocumentTypeMap);
		} catch (error) {
			console.error('Failed to fetch supported documents:', error);
		}
	};

	const getBatchRefIDs = useCallback((searchTerm: string = '') => {
		return kycApiService.getBatchRefIDs(searchTerm);
	}, []);

	const onSuccess = () => {
		dispatch(
			setNotification({
				type: NOTIFICATION_STATES.success,
				message: 'Verification submitted successfully',
			})
		);
	};

	const onError = (error) => {
		console.dir(error);
		dispatch(
			setNotification({
				type: NOTIFICATION_STATES.error,
				message: error || 'Failed to submit verification. Please try again!',
			})
		);
	};

	const renderTabContent = () => {
		switch (selectedTab) {
			case 0:
				return (
					<IDHealthCheck
						proofingFrameworks={proofingFrameworks}
						handleIDHealthCheck={handleIDHealthCheck}
						onSuccess={onSuccess}
						onError={onError}
						getBatchRefIDs={getBatchRefIDs}
						settings={settings}
						supportedDocuments={supportedDocuments}
					/>
				);
			default:
				return (
					<IDHealthCheck
						proofingFrameworks={proofingFrameworks}
						handleIDHealthCheck={handleIDHealthCheck}
						onSuccess={onSuccess}
						onError={onError}
						getBatchRefIDs={getBatchRefIDs}
						settings={settings}
						supportedDocuments={supportedDocuments}
					/>
				);
		}
	};

	return (
		<Box className={classes.container}>
			<Card elevation={0}>
				<CardHeader
					title='Create Verifications for ID Health Check'
					subheader='Submit new verification'
				/>

				<Tabs value={selectedTab} onChange={handleTabChange} aria-label='ID Health Check Tabs'>
					<Tab label='Load samples' />
				</Tabs>

				<CardContent>{renderTabContent()}</CardContent>
			</Card>
		</Box>
	);
};

export default IDHealthCheckPage;
