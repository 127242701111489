import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';

import { APIDialog, WebhooksDialog, Integrations } from 'shared_components/src/components/tenants';
import { GENERAL_COOKIES, NOTIFICATION_STATES } from 'shared_components/src/common/constants';
import { TENANT_STATUS } from 'shared_components/src/service/models/tenant';
import { getCookie } from 'shared_components/src/service/common.service';
import { updateMenu } from '../../store/menu/actions';
import { MENU } from '../../common/routes/menu';
import { setLoading, clearLoading, setNotification } from '../../store/common/actions';
import { setTenant } from '../../store/tenant/actions';
import { _getTenant, _getPlan } from '../../store/selectors';
import kycApiService from '../../service/kycApi.service';

const IntegrationPage = () => {
	const dispatch = useDispatch();
	const tenant = _getTenant();
	const tenantAlias = getCookie(GENERAL_COOKIES.userTenant);

	const [integrations, setIntegrations] = useState({
		APIKeys: {},
	} as any);
	const [accessTokens, setAccessTokens] = useState({} as any);
	const [openAPIDialog, setOpenAPIDialog] = useState(false);
	const [APIDialogData, setAPIDialogData] = useState({} as any);
	const [initializeAPIDialog, setInitializeAPIDialog] = useState(false);
	const [APIDialogDisabled, setAPIDialogDisabled] = useState(false);

	const [webhookTypes, setWebhookTypes] = useState<
		{
			code: string;
			name: string;
		}[]
	>([]);
	const [webhooks, setWebhooks] = useState([] as any);
	const [openWebhooksDialog, setOpenWebhooksDialog] = useState(false);
	const [webhookDialogData, setWebhookDialogData] = useState({} as any);
	const [initializeWebhookDialog, setInitializeWebhookDialog] = useState(false);

	useEffect(() => {
		dispatch(updateMenu(MENU.integration));
	}, []);

	useEffect(() => {
		if (isEmpty(tenant)) {
			getTenants();
		}
	}, []);

	useEffect(() => {
		getIntegrations();
		getWebhookTypes();
		getTenantWebHooks();
	}, [tenant]);

	const getTenants = () => {
		dispatch(setLoading());
		return new Promise((resolve) => {
			kycApiService
				.getTenantInfo(tenantAlias)
				.then((res: any) => {
					if (res) {
						dispatch(setTenant(res));
					} else {
						dispatch(setTenant({} as any));
					}
				})
				.catch((err: any) => {
					dispatch(setTenant({} as any));
				})
				.finally(() => {
					dispatch(clearLoading());
					return resolve({ status: 'success' });
				});
		});
	};

	const getWebhookTypes = async () => {
		try {
			const res = await kycApiService.getWebhookTypes();
			if (!res.error) {
				setWebhookTypes(res);
			}
			return { status: 'success' };
		} catch (err) {
			// Handle catch block
			return { status: 'error' };
		}
	};

	const getIntegrations = () => {
		return new Promise((resolve) => {
			kycApiService
				.getTenantIntegrations()
				.then((res: any) => {
					if (!res.error) {
						setIntegrations((prev) => ({
							...prev,
							APIKeys: res,
						}));
					}
				})
				.catch((err: any) => {
					setIntegrations((prev) => ({
						...prev,
						APIKeys: {},
					}));
				})
				.finally(() => {
					dispatch(clearLoading());
					return resolve({ status: 'success' });
				});
		});
	};

	// A generic function for all API related API calls
	const makeAPICall = async (
		apiFunction,
		successMessage,
		errorMessage,
		data = {},
		callback = (response: any) => {}
	) => {
		dispatch(setLoading());
		try {
			const res = await apiFunction(tenant?.alias, data);
			if (!res.error) {
				callback(res);
				displayNotificationSuccess(successMessage);
				getIntegrations();

				return { status: 'success' };
			} else {
				throw new Error('API Error');
			}
		} catch (err) {
			displayNotificationError(errorMessage);
			return { status: 'error' };
		} finally {
			dispatch(clearLoading());
		}
	};

	// Refactored createNewAPI function
	const createNewAPI = (data) => {
		return makeAPICall(
			kycApiService.createNewAPIIntegrations,
			'API Key successfully created.',
			'Failed to create API Key.',
			data,
			(res: any) => {
				setAccessTokens(res);
				setAPIDialogDisabled(true);
			}
		);
	};

	// Refactored updateAPI function
	const updateAPI = (data) => {
		const requestData = {
			...data,
			status: APIDialogData.status,
			accessKey: APIDialogData.accessKey,
		};
		return makeAPICall(
			kycApiService.updateAPIIntegrations,
			'API Key successfully updated.',
			'Failed to update API Key.',
			requestData,
			() => clearAPIDialog()
		);
	};

	// Refactored deleteAPI function
	const deleteAPI = () => {
		return makeAPICall(
			kycApiService.deleteAPIIntegrations,
			'API Key successfully deleted.',
			'Failed to delete API Key.',
			{ accessKey: APIDialogData.accessKey },
			() => clearAPIDialog()
		);
	};

	const handleNewAPI = () => {
		setOpenAPIDialog(true);
		initializeAPIPopup();
	};

	const clearAPIDialog = () => {
		setOpenAPIDialog(false);
		setAPIDialogDisabled(false);
		setAPIDialogData({});
		setAccessTokens({});
		initializeAPIPopup();
	};

	const initializeAPIPopup = () => {
		setInitializeAPIDialog(true);
		setTimeout(() => setInitializeAPIDialog(false), 150);
	};

	/* WEB HOOKS */

	const getTenantWebHooks = () => {
		if (!tenant?.alias) return;

		dispatch(setLoading());
		return new Promise((resolve) => {
			kycApiService
				.getTenantWebhooks(tenant?.alias)
				.then((res: any) => {
					if (!res.error) {
						// Handle successful response
						setWebhooks(res?.items || []);
					} else {
						// Handle error response
						displayNotificationError('Unable to fetch webhooks. Please try again!');
					}
				})
				.catch((err: any) => {
					// Handle catch block
				})
				.finally(() => {
					dispatch(clearLoading());
					return resolve({ status: 'success' });
				});
		});
	};

	/* new function to make an api call to create a webhook */
	const createNewWebhook = (data) => {
		const requestData = {
			...data,
			tenantAlias: tenant?.alias,
		};
		dispatch(setLoading());
		return new Promise((resolve) => {
			kycApiService
				.createTenantWebhook(tenant?.alias, requestData)
				.then((res: any) => {
					console.log('res', res);
					if (!res.error) {
						displayNotificationSuccess('Webhook successfully created.');
						getTenantWebHooks();
						clearWebhookDialog();
					} else {
						// Handle error response
						displayNotificationError('Unable to create webhook. Please try again!');
					}
				})
				.catch((err: any) => {
					// Handle catch block
				})
				.finally(() => {
					dispatch(clearLoading());
					return resolve({ status: 'success' });
				});
		});
	};

	/* function to make an api call to update a webhook */
	const updateWebhook = (data) => {
		const requestData = {
			...data,
			tenantAlias: tenant?.alias,
		};
		dispatch(setLoading());
		return new Promise((resolve) => {
			kycApiService
				.updateTenantWebhook(tenant?.alias, data.subscriptionId, requestData)
				.then((res: any) => {
					if (!res.error) {
						displayNotificationSuccess('Webhook updated successfully.');
						getTenantWebHooks();
						clearWebhookDialog();
					} else {
						// Handle error response
						displayNotificationError('Unable to update webhook. Please try again!');
					}
				})
				.catch((err: any) => {
					// Handle catch block
				})
				.finally(() => {
					dispatch(clearLoading());
					return resolve({ status: 'success' });
				});
		});
	};

	/* function to make an api call to delete a webhook */
	const deleteWebhook = (subscriptionId: string) => {
		dispatch(setLoading());
		return new Promise((resolve) => {
			kycApiService
				.deleteTenantWebhook(tenant?.alias, subscriptionId)
				.then((res: any) => {
					if (!res.error) {
						displayNotificationSuccess('Webhook deleted successfully.');
						getTenantWebHooks();
						clearWebhookDialog();
					} else {
						// Handle error response
						displayNotificationError('Unable to delete webhook. Please try again!');
					}
				})
				.catch((err: any) => {
					// Handle catch block
				})
				.finally(() => {
					dispatch(clearLoading());
					return resolve({ status: 'success' });
				});
		});
	};

	const clearWebhookDialog = () => {
		setWebhookDialogData({});
		setOpenWebhooksDialog(false);
	};

	const handleNewWebhook = () => {
		setOpenWebhooksDialog(true);
		initializeWebhookPopup();
	};

	const initializeWebhookPopup = () => {
		setInitializeWebhookDialog(true);
		setTimeout(() => setInitializeWebhookDialog(false), 150);
	};

	const displayNotificationSuccess = (msg: string) => {
		dispatch(
			setNotification({
				message: msg,
				type: NOTIFICATION_STATES.success,
			})
		);
	};

	const displayNotificationError = (msg: string) => {
		dispatch(
			setNotification({
				message: msg,
				type: NOTIFICATION_STATES.error,
			})
		);
	};

	const handleShowDetails = (type, key) => {
		if (type === 'API') {
			const data = integrations.APIKeys.items.find((item) => item.accessKey === key);
			setAPIDialogData(data);
			setAccessTokens({ accessKey: key });
			setOpenAPIDialog(true);
		} else {
			const data = webhooks.find((item) => item.subscriptionId === key);
			setWebhookDialogData(data);
			setOpenWebhooksDialog(true);
		}
	};

	const disableNewIntegrations = () => {
		return (
			tenant.status === TENANT_STATUS.CANCELLED ||
			tenant.status === TENANT_STATUS['AWAITING-PAYMENT-DETAILS']
		);
	};

	return (
		<>
			<Integrations
				disableNewIntegrations={disableNewIntegrations()}
				data={integrations}
				webhooks={webhooks}
				tenant={tenant}
				handleNewWebhook={handleNewWebhook}
				handleNewAPI={handleNewAPI}
				handleShowDetails={handleShowDetails}
			/>
			<APIDialog
				open={openAPIDialog}
				title={'API Keys'}
				subTitle={
					'Truuth offers both end to end API’s such as identity verification and API’s for more simple use cases including individual steps within our identity verification process.'
				}
				initializeDialog={initializeAPIDialog}
				dialogData={APIDialogData}
				accessTokens={accessTokens}
				disabled={APIDialogDisabled}
				closeDialog={clearAPIDialog}
				createNewAPI={createNewAPI}
				handleDelete={deleteAPI}
				handleUpdate={updateAPI}
			/>
			<WebhooksDialog
				open={openWebhooksDialog}
				title={'Webhooks'}
				subTitle={
					'When the identity verification is complete an HTTP POST request is sent to your specified callback URL containing an application/JSON formatted string with the verification result in the body.'
				}
				initializeDialog={initializeWebhookDialog}
				dialogData={webhookDialogData}
				closeDialog={clearWebhookDialog}
				createNewWebhook={createNewWebhook}
				handleUpdate={updateWebhook}
				deleteWebhook={deleteWebhook}
				webhookTypes={webhookTypes}
			/>
		</>
	);
};

export default IntegrationPage;
