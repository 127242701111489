import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Grid, CardHeader, CardContent } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getCookie } from 'shared_components/src/service/common.service';
import {
	ProductConfiguration,
	Features,
	AddOns,
	PlanIcon,
	Verification,
} from 'shared_components/src/common/icons';
import { updateMenu } from '../../store/menu/actions';
import { MENU } from '../../common/routes/menu';
import { GENERAL_COOKIES, AUTH_ROLE } from 'shared_components/src/common/constants';

const useStyles = makeStyles(() => ({
	productOverview: {
		display: 'flex',
		flexDirection: 'column',

		'& .card-header': {
			'& .MuiCardHeader-title': {
				color: '#515170',
				fontSize: '20px',
				lineHeight: '28px',
				fontWeight: 600,
			},
		},

		'& .card-item': {
			width: '375px',
			paddingBottom: '1.5rem',
			margin: '0 16px 0 8px',

			'& .MuiCardContent-root': {
				padding: '0',
			},
		},

		'& .cards-wrapper': {
			'& .title': {
				color: '#20203A',
				fontWeight: 600,
				fontSize: '16px',
				lineHeight: '24px',
			},

			'& .subTitle': {
				color: '#333333',
				fontWeight: 400,
				fontSize: '14px',
				lineHeight: '24px',
				marginTop: '8px',
			},
		},

		'& .itemButton': {
			width: '100%',
			minHeight: '200px',
			padding: '24px 32px',
			backgroundColor: '#fff',
			border: '1px solid #E8E8E8',
			borderRadius: '8px',
			textAlign: 'left',
			cursor: 'pointer',

			'&:hover': {
				border: '1px solid #B3B3C6',
				boxShadow: '1px 1px 15px 4px #20203A1A',
			},

			'&:active': {
				border: '1px solid #470D82',
				boxShadow: '1px 1px 10px 2px #470D8226',
			},

			'& svg': {
				width: '32px',
				height: '32px',
			},
		},
	},
}));

const ProductOverview = () => {
	const classes = useStyles();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const featureFlags = useFlags();
	const tenant = getCookie('tenant') || {};
	const roles = getCookie(GENERAL_COOKIES.userRoles);

	useEffect(() => {
		dispatch(updateMenu(MENU.productOverview));
	}, []);

	const isClientAdmin = useMemo(() => {
		return roles.split(',').includes(AUTH_ROLE.client) || false;
	}, [roles]);

	const handleSelection = (type) => {
		navigate({
			pathname: `/tenant/${tenant}/${type}`,
		});
	};

	const productConfigurations = [
		{
			key: 'verification',
			title: 'Verifications',
			subTitle: 'Overview of Verifications',
			icon: <Verification fill={'#70708F'} />,
			show: true,
		},
		{
			key: 'features',
			title: 'Features',
			subTitle: 'Overview of core features of your selected product',
			icon: <Features fill={'#70708F'} />,
			show: isClientAdmin && featureFlags['Onboarding-client-v2'],
		},
		{
			key: 'add-ons',
			title: 'Add Ons',
			subTitle: 'Overview of features based on selected plan',
			icon: <AddOns fill={'#70708F'} />,
			show: isClientAdmin && featureFlags['Onboarding-client-v2'],
		},
		{
			key: 'product-configuration',
			title: 'Product Configuration',
			subTitle: 'Overview of ID document selection, Data settings, UI settings and Integrations',
			icon: <ProductConfiguration fill={'#70708F'} />,
			show: isClientAdmin && featureFlags['Onboarding-client-v2'],
		},
		{
			key: 'plan',
			title: 'Plan',
			subTitle: 'Summary of your plan and add ons',
			icon: <PlanIcon fill={'#70708F'} />,
			show: isClientAdmin && featureFlags['Onboarding-client-v1'],
		},
	];

	return (
		<Grid container className={classes.productOverview}>
			<CardHeader className="card-header" title="Product Overview" />
			<Grid container>
				{productConfigurations.map(
					(item) =>
						item.show && (
							<Grid item className="card-item" key={item.key}>
								<CardContent className="cards-wrapper">
									<button
										id={`CARD_${item.key}`}
										className="itemButton"
										onClick={() => handleSelection(item.key)}
									>
										<div>{item.icon}</div>
										<div style={{ marginTop: '8px' }}>
											<div className={'title'}>{item.title}</div>
											<div className={'subTitle'}>{item.subTitle}</div>
										</div>
									</button>
								</CardContent>
							</Grid>
						)
				)}
			</Grid>
		</Grid>
	);
};

export default ProductOverview;
