import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';

import { ProductConfiguration, APIDialog, WebhooksDialog } from 'shared_components/src/components/tenants';
import { GENERAL_COOKIES, NOTIFICATION_STATES } from 'shared_components/src/common/constants';
import { TENANT_STATUS, TENANT_PLAN } from 'shared_components/src/service/models/tenant';
import { getCookie } from 'shared_components/src/service/common.service';
import { updateMenu } from '../../store/menu/actions';
import { MENU } from '../../common/routes/menu';
import { setLoading, clearLoading, setNotification } from '../../store/common/actions';
import { setTenant } from '../../store/tenant/actions';
import { _getTenant, _getPlan } from '../../store/selectors';
import kycApiService from '../../service/kycApi.service';

const ProductConfigurationPage = () => {
	const dispatch = useDispatch();
	const tenant = _getTenant();
	const plan = _getPlan();
	const tenantAlias = getCookie(GENERAL_COOKIES.userTenant);

	const [showProductConfiguration, setShowProductConfiguration] = useState(false);
	const [initialTenantSettingsData, setInitialTenantSettingsData] = useState([] as any);
	const [initialUIConfigurations, setInitialUIConfigurations] = useState([] as any);
	const [IPSettings, setIPSettings] = useState([] as any);
	const [dataSettings, setDataSettings] = useState([] as any);
	const [refreshSettings, setRefreshSettings] = useState(false);
	const [tenantSettings, setTenantSettings] = useState([] as any);
	const [UISettings, setUISettings] = useState({
		channelSettings: {},
		whiteLabelSettings: {},
	} as any);
	const [integrations, setIntegrations] = useState({
		APIKeys: {},
		webhooks: {},
	} as any);
	const [initialIntegrations, setInitialIntegrations] = useState({
		APIKeys: {},
		webhooks: {},
	} as any);
	const [accessTokens, setAccessTokens] = useState({} as any);
	const [openAPIDialog, setOpenAPIDialog] = useState(false);
	const [openWebhooksDialog, setOpenWebhooksDialog] = useState(false);
	const [APIDialogData, setAPIDialogData] = useState({} as any);
	const [webhookDialogData, setWebhookDialogData] = useState({} as any);
	const [initializeAPIDialog, setInitializeAPIDialog] = useState(false);
	const [initializeWebhookDialog, setInitializeWebhookDialog] = useState(false);
	const [APIDialogDisabled, setAPIDialogDisabled] = useState(false);

	useEffect(() => {
		dispatch(updateMenu(MENU.productConfiguration));
	}, []);

	useEffect(() => {
		if (isEmpty(tenant)) {
			getTenants();
		} else {
			const { configurations } = tenant || [];
			setInitialUIConfigurations(tenant);
			if (!isEmpty(configurations)) {
				setShowProductConfiguration(true);
				const portalUIsettings = configurations.find((el) => el.channel === 'Mobile') || {};
				setUISettings((prev) => ({
					...prev,
					whiteLabelSettings: portalUIsettings,
				}));
			} else {
				setShowProductConfiguration(false);
			}
		}
	}, []);

	useEffect(() => {
		function getTenantSettings() {
			dispatch(setLoading());
			return new Promise((resolve) => {
				kycApiService
					.getSettings()
					.then((res: any) => {
						if (!res.error) {
							setTenantSettingsData(res);
							setInitialTenantSettingsData(res);
						}
					})
					.catch((err: any) => {
						// setTenantSettings([]);
					})
					.finally(() => {
						dispatch(clearLoading());
						return resolve({ status: 'success' });
					});
			});
		}

		getTenantSettings();
	}, []);

	useEffect(() => {
		getIntegrations();
	}, []);

	const initializeUISettings = (data) => {
		const { configurations } = data || [];
		setInitialUIConfigurations(data);
		setShowProductConfiguration(true);
		const portalUIsettings = configurations.find((el) => el.channel === 'Mobile') || {};
		setUISettings((prev) => ({
			...prev,
			whiteLabelSettings: portalUIsettings,
		}));
	};

	const getTenants = () => {
		dispatch(setLoading());
		return new Promise((resolve) => {
			kycApiService
				.getTenantInfo(tenantAlias)
				.then((res: any) => {
					if (res) {
						dispatch(setTenant(res));
						initializeUISettings(res);
					} else {
						dispatch(setTenant({} as any));
					}
				})
				.catch((err: any) => {
					dispatch(setTenant({} as any));
				})
				.finally(() => {
					dispatch(clearLoading());
					return resolve({ status: 'success' });
				});
		});
	};

	const updateTenantSettings = (data, showAlert = true) => {
		dispatch(setLoading());
		return new Promise((resolve) => {
			kycApiService
				.updateTenantSettings(data)
				.then((res: any) => {
					if (!res?.error) {
						setTenantSettingsData(res);
						setInitialTenantSettingsData(res);
						setRefreshSettings(true);
						setTimeout(() => setRefreshSettings(false), 150);
						if (showAlert) {
							displayNotificatoinSuccess('Product Configuration successfully updated.');
						}
					} else {
						if (showAlert) {
							displayNotificatoinError('Failed to update Product Configuration.');
						}
					}
				})
				.catch((err: any) => {
					if (showAlert) {
						displayNotificatoinError('Failed to update Product Configuration.');
					}
				})
				.finally(() => {
					dispatch(clearLoading());
					return resolve({ status: 'success' });
				});
		});
	};

	const updateChannelSettings = (request) => {
		dispatch(setLoading());
		return new Promise(async (resolve, reject) => {
			kycApiService
				.updateTenant(request)
				.then((res: any) => {
					dispatch(clearLoading());
					if (res.error) {
						displayNotificatoinError('Failed to update Product Configuration.');
					} else {
						dispatch(setTenant(res));
						displayNotificatoinSuccess('Product Configuration successfully updated.');
					}
				})
				.catch((err: any) => {
					dispatch(clearLoading());
					reject({ status: 'error' });
					displayNotificatoinError('Failed to update Product Configuration.');
				})
				.finally(() => {
					dispatch(clearLoading());
					return resolve({ status: 'success' });
				});
		});
	};

	const getIntegrations = () => {
		return new Promise((resolve) => {
			kycApiService
				.getTenantIntegrations()
				.then((res: any) => {
					if (!res.error) {
						setIntegrations((prev) => ({
							...prev,
							APIKeys: res,
						}));
						setInitialIntegrations((prev) => ({
							...prev,
							APIKeys: res,
						}));
					}
				})
				.catch((err: any) => {
					setIntegrations((prev) => ({
						...prev,
						APIKeys: {},
					}));
				})
				.finally(() => {
					dispatch(clearLoading());
					return resolve({ status: 'success' });
				});
		});
	};

	const createNewAPI = (data) => {
		dispatch(setLoading());
		return new Promise((resolve) => {
			kycApiService
				.createNewAPIIntegrations(tenant?.alias, data)
				.then((res: any) => {
					if (!res.error) {
						setAccessTokens(res);
						setAPIDialogDisabled(true);
						displayNotificatoinSuccess('API Key successfully created.');
						getIntegrations();
					} else {
						displayNotificatoinError('Failed to create API Key.');
					}
				})
				.catch((err: any) => {
					setAccessTokens({});
					displayNotificatoinError('Failed to create API Key.');
				})
				.finally(() => {
					dispatch(clearLoading());
					return resolve({ status: 'success' });
				});
		});
	};

	const updateAPI = (data) => {
		const requestData = {
			...data,
			status: APIDialogData.status,
			accessKey: APIDialogData.accessKey,
		};
		dispatch(setLoading());
		return new Promise((resolve) => {
			kycApiService
				.updateAPIIntegrations(tenant?.alias, requestData)
				.then((res: any) => {
					if (!res.error) {
						displayNotificatoinSuccess('API Key successfully updated.');
						getIntegrations();
						clearAPIDiaglog();
					} else {
						displayNotificatoinError('Failed to update API Key.');
					}
				})
				.catch((err: any) => {
					setAccessTokens({});
					displayNotificatoinError('Failed to update API Key.');
				})
				.finally(() => {
					dispatch(clearLoading());
					return resolve({ status: 'success' });
				});
		});
	};

	const deleteAPI = () => {
		dispatch(setLoading());
		return new Promise((resolve) => {
			kycApiService
				.deleteAPIIntegrations(tenant?.alias, APIDialogData.accessKey)
				.then((res: any) => {
					if (!res.error) {
						displayNotificatoinSuccess('API Key successfully deleted.');
						getIntegrations();
						clearAPIDiaglog();
					} else {
						displayNotificatoinError('Failed to deleted API Key.');
					}
				})
				.catch((err: any) => {
					displayNotificatoinError('Failed to deleted API Key.');
				})
				.finally(() => {
					dispatch(clearLoading());
					return resolve({ status: 'success' });
				});
		});
	};

	const updateWebhook = (data) => {
		initialTenantSettingsData.callBack = data;
		updateTenantSettings(initialTenantSettingsData);
		setOpenWebhooksDialog(false);
	};

	const setTenantSettingsData = (settings) => {
		setDataSettings(settings.dataRetentionPolicy);
		setIPSettings(settings.identityProofing);
		setUISettings((prev) => ({
			...prev,
			channelSettings: settings.identitySubmissionChannel,
		}));
		setIntegrations((prev) => ({
			...prev,
			webhooks: settings.callBack,
		}));
	};

	const saveProductConfiguration = (settingType, data) => {
		if (settingType === 'IPSettings') {
			initialTenantSettingsData.identityProofing = data;
			updateTenantSettings(initialTenantSettingsData);
		} else if (settingType === 'dataSettings') {
			initialTenantSettingsData.dataRetentionPolicy = {
				completedVerifications: { period: data.completedVerifications },
				incompletedVerifications: { period: data.incompletedVerifications },
			};
			updateTenantSettings(initialTenantSettingsData);
		} else if (settingType === 'UISettings') {
			initialTenantSettingsData.identitySubmissionChannel = data.channelSettings;
			initialUIConfigurations.configurations.forEach((configuration) => {
				const { style, logo } = data.whiteLabelSettings;
				if (!isEmpty(logo)) {
					configuration.logo = logo;
				}

				if (configuration.channel === 'Portal') {
					const { light: main, dark } = style.primaryColor;
					configuration.style.primaryColor = { main, dark };
				} else {
					configuration.style.primaryColor = style.primaryColor;
				}
				return configuration;
			});
			updateChannelSettings(initialUIConfigurations);
			updateTenantSettings(initialTenantSettingsData, false);
		}
	};

	const handleNewAPI = () => {
		setOpenAPIDialog(true);
		initializeAPIPopup();
	};

	const clearAPIDiaglog = () => {
		setOpenAPIDialog(false);
		setAPIDialogDisabled(false);
		setAPIDialogData({});
		setAccessTokens({});
		initializeAPIPopup();
	};

	const initializeAPIPopup = () => {
		setInitializeAPIDialog(true);
		setTimeout(() => setInitializeAPIDialog(false), 150);
	};

	const clearWebhookDialog = () => {
		setWebhookDialogData({});
		setOpenWebhooksDialog(false);
	};

	const handleNewWebhook = () => {
		setOpenWebhooksDialog(true);
		initializeWebhookPopup();
	};

	const initializeWebhookPopup = () => {
		setInitializeWebhookDialog(true);
		setTimeout(() => setInitializeWebhookDialog(false), 150);
	};

	const displayNotificatoinSuccess = (msg: string) => {
		dispatch(
			setNotification({
				message: msg,
				type: NOTIFICATION_STATES.success,
			})
		);
	};

	const displayNotificatoinError = (msg: string) => {
		dispatch(
			setNotification({
				message: msg,
				type: NOTIFICATION_STATES.error,
			})
		);
	};

	const handleShowDetails = (type, key) => {
		if (type === 'API') {
			const data = integrations.APIKeys.items.find((item) => item.accessKey === key);
			setAPIDialogData(data);
			setAccessTokens({ accessKey: key });
			setOpenAPIDialog(true);
		} else {
			const data = integrations.webhooks;
			setWebhookDialogData(data);
			setOpenWebhooksDialog(true);
		}
	};

	const disableNewIntegrations = () => {
		return (
			tenant.status === TENANT_STATUS.CANCELLED ||
			tenant.status === TENANT_STATUS['AWAITING-PAYMENT-DETAILS']
		);
	};

	return (
		<>
			<ProductConfiguration
				showProductConfiguration={showProductConfiguration}
				IPSettings={IPSettings}
				dataSettings={dataSettings}
				portalUIsettings={UISettings}
				integrationSettings={integrations}
				refreshSettings={refreshSettings}
				disableDataSetting={plan.code === TENANT_PLAN.PLAN_FREE}
				disableNewIntegrations={disableNewIntegrations()}
				showVerificationUserJourney={false}
				handleNewAPI={handleNewAPI}
				handleNewWebhook={handleNewWebhook}
				saveProductConfiguration={saveProductConfiguration}
				handleShowDetails={handleShowDetails}
			/>

			<APIDialog
				open={openAPIDialog}
				title={'API Keys'}
				subTitle={
					'Truuth offers both end to end API’s such as identity verification and API’s for more simple use cases including individual steps within our identity verification process.'
				}
				initializeDialog={initializeAPIDialog}
				dialogData={APIDialogData}
				accessTokens={accessTokens}
				disabled={APIDialogDisabled}
				closeDialog={clearAPIDiaglog}
				createNewAPI={createNewAPI}
				handleDelete={deleteAPI}
				handleUpdate={updateAPI}
			/>
			<WebhooksDialog
				open={openWebhooksDialog}
				title={'Webhooks'}
				subTitle={
					'When the identity verification is complete an HTTP POST request is sent to your specified callback URL containing an application/JSON formatted string with the verification result in the body.'
				}
				initializeDialog={initializeWebhookDialog}
				dialogData={webhookDialogData}
				closeDialog={clearWebhookDialog}
				createNewWebhook={updateWebhook}
				handleUpdate={updateWebhook}
			/>
		</>
	);
};

export default ProductConfigurationPage;
