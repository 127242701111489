import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { updateMenu } from '../../store/menu/actions';
import { MENU } from '../../common/routes/menu';
import { BillingAndPayment, Chargebee } from 'shared_components/src/components/tenants';
import { NOTIFICATION_STATES } from 'shared_components/src/common/constants';
import { TENANT_PLAN } from 'shared_components/src/service/models/tenant';
import { _getCountryList, _getTenant, _getPlan } from '../../store/selectors';
import kycApiService from '../../service/kycApi.service';
import { setLoading, clearLoading, setNotification } from '../../store/common/actions';
import { setTenant } from '../../store/tenant/actions';

const BillingAndPaymentPage = () => {
	const dispatch = useDispatch();
	const countryList = _getCountryList();
	const tenant = _getTenant();
	const plan = _getPlan();
	const navigate = useNavigate();

	useEffect(() => {
		dispatch(updateMenu(MENU.billingPayment));
	}, [dispatch]);

	const getBillingInfo = React.useCallback(
		(alias) => {
			dispatch(setLoading());
			return new Promise((resolve) => {
				kycApiService
					.getTenantBilling(alias)
					.then((res: any) => {
						if (!res.error) {
							setPaymentMethod(res.paymentMethod);
							setBillingData(res.billingAddress);
							setBillingSystem(res.billingSystem);
							// TODO: Disable the card details if Offline payment selected
							if (res.paymentMethod !== 'OFFLINE') {
								setPaymentData(res.paymentCard);
							}
						}
					})
					.catch((err: any) => {
						console.log('getBillingInfo err', err);
					})
					.finally(() => {
						dispatch(clearLoading());
						return resolve({ status: 'success' });
					});
			});
		},
		[dispatch]
	);

	useEffect(() => {
		if (!isEmpty(tenant)) {
			getBillingInfo(tenant?.alias);
		}
	}, [tenant]);

	const [billingData, setBillingData] = useState({} as any);
	const [paymentData, setPaymentData] = useState({} as any);
	const [billingSystem, setBillingSystem] = useState('');
	const [paymentMethod, setPaymentMethod] = useState('');
	const [updateFailed, setUpdateFailed] = useState(false);
	const [showCardPopup, setShowCardPopup] = useState(false);

	const offlinePayment = useMemo(() => {
		return paymentMethod === 'OFFLINE';
	}, [paymentMethod]);

	const updateBillingInfo = (data) => {
		setUpdateFailed(false);
		dispatch(setLoading());
		return new Promise((resolve) => {
			kycApiService
				.updateTenantBilling(data)
				.then((res: any) => {
					if (!res.error) {
						setBillingData(res);
						displayNotificatoinSuccess('Billing Details successfully updated.');
					} else {
						setUpdateFailed(true);
						displayNotificatoinError('Failed to update Billing Details.');
					}
				})
				.catch((err: any) => {
					setUpdateFailed(true);
					displayNotificatoinError('Failed to update Billing Details.');
				})
				.finally(() => {
					dispatch(clearLoading());
					return resolve({ status: 'success' });
				});
		});
	};

	const updateCardDetails = (tokenId) => {
		const request = {
			productCode: tenant?.product?.code || 'KYC',
			planCode: plan.code,
			currencyCode: tenant.currency,
			billingSystem,
			paymentMethod,
			paymentCard: {
				cardTokenId: tokenId,
			},
		};
		dispatch(setLoading());
		return new Promise((resolve) => {
			kycApiService
				.updateCardDetails(tenant.alias, request)
				.then((res: any) => {
					if (!res.error) {
						setPaymentData(res);
						displayNotificatoinSuccess('Card Details successfully updated.');
						getTenant();
					} else {
						displayNotificatoinError('Failed to update Card Details.');
					}
				})
				.catch((err: any) => {
					displayNotificatoinError('Failed to update Card Details.');
				})
				.finally(() => {
					dispatch(clearLoading());
					return resolve({ status: 'success' });
				});
		});
	};

	const getTenant = () => {
		dispatch(setLoading());
		kycApiService
			.getTenantInfo(tenant.alias)
			.then((res) => {
				dispatch(setTenant(res));
			})
			.catch((err) => {
				console.log('tenant err => ', err);
			})
			.finally(() => {
				dispatch(clearLoading());
			});
	};

	const displayNotificatoinSuccess = (msg: string) => {
		dispatch(
			setNotification({
				message: msg,
				type: NOTIFICATION_STATES.success,
			})
		);
	};

	const displayNotificatoinError = (msg: string) => {
		dispatch(
			setNotification({
				message: msg,
				type: NOTIFICATION_STATES.error,
			})
		);
	};

	const updateBilllingDetails = (billingAddress) => {
		setBillingData(billingAddress);
		updateBillingInfo({
			tenantAlias: tenant?.alias,
			productCode: tenant?.product?.code || 'KYC',
			planCode: plan.code,
			currencyCode: tenant.currency,
			billingSystem,
			billingAddress,
		});
	};

	const onCardSubmit = (data) => {
		setShowCardPopup(false);
		updateCardDetails(data.token);
	};

	const addCardDetails = () => {
		dispatch(setLoading());
		setShowCardPopup(true);
	};

	const onComponentReady = () => {
		dispatch(clearLoading());
	};

	const handleChargebeeError = () => {
		setShowCardPopup(false);
		displayNotificatoinError('Please enter valid Card Number, Expiry and CVV.');
	};

	const showFreePlanInfo = () => {
		return isEmpty(billingData) && tenant?.product?.plan === TENANT_PLAN.PLAN_FREE;
	};

	if (plan?.salesChannel === 'AWS') {
		navigate('/tenant/:tenantAlias/account-overview');
	}

	return (
		<>
			<BillingAndPayment
				countryList={countryList}
				billingData={billingData}
				paymentData={paymentData}
				updateFailed={updateFailed}
				offlinePayment={offlinePayment}
				disableCardButtons={offlinePayment}
				portal='CLIENT'
				showFreePlanInfo={showFreePlanInfo()}
				mandatoryFields={['organisationName', 'addressLine1', 'postCode', 'city', 'country']}
				updateBilllingData={updateBilllingDetails}
				addCard={addCardDetails}
			/>
			{showCardPopup && (
				<Chargebee
					open={showCardPopup}
					onComponentReady={onComponentReady}
					handleError={handleChargebeeError}
					onClose={() => setShowCardPopup(false)}
					onSubmit={onCardSubmit}
				/>
			)}
		</>
	);
};

export default BillingAndPaymentPage;
