import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AccountDetails, StatusMessage } from 'shared_components/src/components/tenants';
import { NOTIFICATION_STATES } from 'shared_components/src/common/constants';
import { TENANT_MODE, TENANT_STATUS } from 'shared_components/src/service/models/tenant';
import { getCookie } from 'shared_components/src/service/common.service';

import kycApiService from '../../service/kycApi.service';
import { _getTenantAlias, _getTenant, _getCountryList } from '../../store/selectors';
import { updateMenu } from '../../store/menu/actions';
import { setLoading, clearLoading, setNotification } from '../../store/common/actions';
import { setTenant } from '../../store/tenant/actions';
import { MENU } from '../../common/routes/menu';

const AccountDetailsPage = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const countryList = _getCountryList();
	const tenant = _getTenant();
	const tenantAlias = getCookie('tenant') || '';

	const [tenantData, setTenantData] = useState({} as any);
	const [modeData, setMode] = useState({ mode: TENANT_MODE.VIEW });

	useEffect(() => {
		dispatch(updateMenu(MENU.accountDetails));
	}, []);

	useEffect(() => {
		setTenantData(tenant);
	}, [tenant]);

	const showSubscribeButton = useMemo(() => {
		return tenantData.status === TENANT_STATUS.CANCELLED;
	}, [tenantData]);

	const handleUpdateTenantDetails = (request) => {
		dispatch(setLoading());
		return new Promise(async (resolve, reject) => {
			kycApiService
				.updateTenant(request)
				.then((res: any) => {
					dispatch(clearLoading());
					if (res.error) {
						displayNotificatoinError(res.error.message);
						const modeData = { mode: TENANT_MODE.EDIT };
						setMode((prev) => ({
							...prev,
							...(modeData as Object),
						}));
					} else {
						resolve({ status: 'success' });
						dispatch(setTenant(res));
						displayNotificatoinSuccess('Tenant successfully updated.');
						const modeData = { mode: TENANT_MODE.VIEW };
						setMode((prev) => ({
							...prev,
							...(modeData as Object),
						}));
					}
				})
				.catch((err: any) => {
					dispatch(clearLoading());
					reject({ status: 'error' });
					displayNotificatoinError('Failed to update Tenant Details');
					const modeData = { mode: TENANT_MODE.EDIT };
					setMode((prev) => ({
						...prev,
						...(modeData as Object),
					}));
				});
		});
	};

	const handleSubscription = () => {
		navigate(`/tenant/${tenantAlias}/plan/subscribe`);
	};

	const displayNotificatoinSuccess = (msg: string) => {
		dispatch(
			setNotification({
				message: msg,
				type: NOTIFICATION_STATES.success,
			})
		);
	};

	const displayNotificatoinError = (msg: string) => {
		dispatch(
			setNotification({
				message: msg,
				type: NOTIFICATION_STATES.error,
			})
		);
	};

	const showStatusMessage = () => {
		return tenantData?.status === TENANT_STATUS['AWAITING-PAYMENT-DETAILS'];
	};

	const statusMessageType = () => {
		switch (tenantData?.status) {
			case TENANT_STATUS['AWAITING-PAYMENT-DETAILS']:
				return 'WARNING';
			default:
				return 'INFO';
		}
	};

	const statusMessageElement = () => {
		return (
			<StatusMessage type={statusMessageType()}>
				<div>
					<div>To create verification and send invitations, you have to activate your account.</div>
					<div>
						Please go to{' '}
						<span
							style={{
								color: '#2160F6',
								cursor: 'pointer',
								textDecoration: 'underline',
							}}
							onClick={() => handleNav(`tenant/${tenantAlias}/billing-payment`)}
						>
							“Billing & Payment”
						</span>{' '}
						and add payment details to activate account.
					</div>
				</div>
			</StatusMessage>
		);
	};

	const handleNav = (page) => {
		navigate(`/${page}`);
	};

	return (
		<AccountDetails
			modeData={modeData}
			tenant={tenantData}
			countryList={countryList}
			showStatusMessage={showStatusMessage()}
			statusMessageElement={statusMessageElement()}
			showSubscribeButton={showSubscribeButton}
			handleSubscription={handleSubscription}
			updateTenantDetails={handleUpdateTenantDetails}
		></AccountDetails>
	);
};

export default AccountDetailsPage;
