import React, { useState, useLayoutEffect } from 'react';
import dayjs from 'dayjs';

import { VerificationFilter } from 'shared_components/src/components/verification';
interface IProps {
	resetFilters: any;
	setFilters: any;
}

const VerificationFilterPage = ({ resetFilters, setFilters }: IProps) => {
	const [selectedVerificationStatus, setSelectedVerificationStatus] = useState([]);
	const [selectedProofingStatus, setSelectedProofingStatus] = useState([]);
	const [selectedProofingTypes, setSelectedProofingTypes] = useState([]);
	const [lastModifiedFrom, setLastModifiedFrom] = useState(dayjs().subtract(7, 'days'));
	const [lastModifiedTo, setLastModifiedTo] = useState(dayjs());
	const [agentGivenName, setAgentGivenName] = useState('');
	const [agentFamilyName, setAgentFamilyName] = useState('');
	const [batchRefId, setBatchRefId] = useState<string>('');
	const [batchRefDescription, setBatchRefDescription] = useState<string>('');
	const [channel, setChannel] = useState([]);

	useLayoutEffect(() => {
		try {
			const filters = sessionStorage.getItem('verificationFilters');

			if (filters) {
				const parsedFilters = JSON.parse(filters);
				setSelectedVerificationStatus(parsedFilters.status);
				setSelectedProofingStatus(parsedFilters.proofingstatus);
				setSelectedProofingTypes(parsedFilters.proofingtype);
				setLastModifiedFrom(dayjs(parsedFilters.modifieddatestart));
				setLastModifiedTo(dayjs(parsedFilters.modifieddateend));
				setAgentGivenName(parsedFilters.agentgivenname);
				setAgentFamilyName(parsedFilters.agentfamilyname);
				setBatchRefId(parsedFilters.batchRefId);
				setBatchRefDescription(parsedFilters.batchRefDescription);
				setChannel(parsedFilters.channel || []);
			}
		} catch (error) {
			console.error('Error retrieving filter data from session storage:', error);
		}
	}, []);

	const setSelectedValue = (type, value) => {
		if (type === 'VERIFICATION_STATUS') {
			setSelectedVerificationStatus(value);
		} else if (type === 'PROOFING_STATUS') {
			setSelectedProofingStatus(value);
		} else if (type === 'PROOFING_TYPES') {
			setSelectedProofingTypes(value);
		} else if (type === 'AGENT_GIVEN_NAME') {
			setAgentGivenName(value);
		} else if (type === 'AGENT_FAMILY_NAME') {
			setAgentFamilyName(value);
		} else if (type === 'BATCH_REF_ID') {
			setBatchRefId(value);
		} else if (type === 'BATCH_REF_DESCRIPTION') {
			setBatchRefDescription(value);
		} else if (type === 'CHANNEL') {
			setChannel(value);
		}
	};

	const deleteSelectedValue = (type, item) => {
		if (type === 'VERIFICATION_STATUS') {
			setSelectedVerificationStatus((status) => status.filter((el) => el !== item));
		} else if (type === 'PROOFING_STATUS') {
			setSelectedProofingStatus((status) => status.filter((el) => el !== item));
		} else if (type === 'PROOFING_TYPES') {
			setSelectedProofingTypes((types) => types.filter((el) => el !== item));
		} else if (type === 'CHANNEL') {
			setChannel((channels) => channels.filter((el) => el !== item));
		}
	};

	const handleDateChange = (type, value) => {
		if (type === 'FROM') {
			setLastModifiedFrom(value);
		} else if (type === 'TO') {
			setLastModifiedTo(value);
		}
	};

	const handleResetFilters = (event) => {
		event.persist();
		setSelectedVerificationStatus([]);
		setSelectedProofingStatus([]);
		setSelectedProofingTypes([]);
		setLastModifiedFrom(dayjs().subtract(7, 'days'));
		setLastModifiedTo(dayjs());
		setAgentGivenName('');
		setAgentFamilyName('');
		setBatchRefId('');
		setBatchRefDescription('');
		setChannel([]);
		resetFilters();
	};

	const handleFilterApply = (event) => {
		const filters = {
			proofingtype: selectedProofingTypes,
			agentgivenname: agentGivenName,
			agentfamilyname: agentFamilyName,
			proofingstatus: selectedProofingStatus,
			status: selectedVerificationStatus,
			modifieddatestart: dayjs(lastModifiedFrom).format('YYYY-MM-DD'),
			modifieddateend: dayjs(lastModifiedTo).format('YYYY-MM-DD'),
			batchRefId: batchRefId,
			batchRefDescription: batchRefDescription,
			channel: channel,
		};

		try {
			sessionStorage.setItem('verificationFilters', JSON.stringify(filters));
		} catch (error) {
			console.error('Error saving verification filters to session storage:', error);
		}

		setFilters(filters);
	};

	return (
		<VerificationFilter
			selectedVerificationStatus={selectedVerificationStatus}
			selectedProofingStatus={selectedProofingStatus}
			selectedProofingTypes={selectedProofingTypes}
			lastModifiedFrom={lastModifiedFrom}
			lastModifiedTo={lastModifiedTo}
			agentGivenName={agentGivenName}
			agentFamilyName={agentFamilyName}
			batchRefId={batchRefId}
			batchRefDescription={batchRefDescription}
			channel={channel}
			handleDateChange={handleDateChange}
			setSelectedValue={setSelectedValue}
			deleteSelectedValue={deleteSelectedValue}
			resetFilters={handleResetFilters}
			handleFilterApply={handleFilterApply}
		/>
	);
};

export default VerificationFilterPage;
