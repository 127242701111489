import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

function IDPResponse() {
	const navigate = useNavigate();
	const location = useLocation();

	React.useEffect(() => {
		// const search = window.location.search;
		// const params = new URLSearchParams(search);
		// const errorDescription = params.get('error_description');
		// console.log(params);
		// console.log(errorDescription);
		navigate('/login', { state: { params: location.search } });
	}, [location]);

	return <div></div>;
}

export default IDPResponse;
