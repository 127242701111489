export const MENU = {
	accountDetails: 'account-details',
	accountOverview: 'account-overview',
	addOns: 'add-ons',
	billingPayment: 'billing-payment',
	features: 'features',
	integration: 'integration',
	myProfile: 'my-profile',
	plan: 'plan',
	productConfiguration: 'product-configuration',
	subcriptionManagement: 'subscription-management',
	productOverview: 'product-overview',
	verificaton: 'verification',
	viewUsers: 'view-users',
	idHeathCheck: 'id-health-check',
};
