import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { map, cloneDeep } from 'lodash';

import { AddOns, DetailDialog } from 'shared_components/src/components/tenants';
import { NOTIFICATION_STATES } from 'shared_components/src/common/constants';
import { TENANT_PLAN } from 'shared_components/src/service/models/tenant';

import kycApiService from '../../service/kycApi.service';
import { setLoading, clearLoading, setNotification } from '../../store/common/actions';
import { updateMenu } from '../../store/menu/actions';
import { _getTenant, _getPlan } from '../../store/selectors';
import { MENU } from '../../common/routes/menu';

const AddOnsPage = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const tenant = _getTenant();
	const plan = _getPlan();
	const [initialTenantSettingsData, setInitialTenantSettingsData] = useState([] as any);
	const [tenantAddOns, setTenantAddOns] = useState([] as any);
	const [openThresholdModal, setOpenThresholdModal] = useState(false);
	const [dialogData, setDialogData] = useState({} as any);

	useEffect(() => {
		dispatch(updateMenu(MENU.addOns));
	}, []);

	useEffect(() => {
		function getTenantSettings() {
			dispatch(setLoading());
			return new Promise((resolve) => {
				kycApiService
					.getSettings()
					.then((res: any) => {
						if (res) {
							setTenantAddOnsData(res.features);
							setInitialTenantSettingsData(res);
						} else {
							setTenantAddOns([]);
						}
					})
					.catch((err: any) => {
						setTenantAddOns([]);
					})
					.finally(() => {
						dispatch(clearLoading());
						return resolve({ status: 'success' });
					});
			});
		}

		getTenantSettings();
	}, [plan]);

	const updateTenantAddOns = (data) => {
		dispatch(setLoading());
		return new Promise((resolve) => {
			kycApiService
				.updateTenantSettings(data)
				.then((res: any) => {
					if (res?.features) {
						displayNotificatoinSuccess('Add Ons successfully updated.');
						setTenantAddOnsData(res.features);
						setInitialTenantSettingsData(res);
					} else {
						displayNotificatoinError('Failed to update Add Ons.');
						setTenantAddOnsData(initialTenantSettingsData.features);
					}
				})
				.catch((err: any) => {
					displayNotificatoinError('Failed to update Add Ons.');
					setTenantAddOnsData(initialTenantSettingsData.features);
				})
				.finally(() => {
					dispatch(clearLoading());
					return resolve({ status: 'success' });
				});
		});
	};

	const setTenantAddOnsData = (features) => {
		const products = map(features, (feature, key) => ({ key, ...feature }));
		const addOns = products
			.filter((product) => product.type === 'addOns')
			.map((addOn) => {
				addOn.isSelectable = addOn.key !== 'extendedDataRetention';
				addOn.hideCurrency = addOn.key !== 'currentAddressProofing';

				if (addOn.key === 'businessVerification') {
					addOn.isSelectable = true;
					addOn.isDetailsButtonRequired = true;
					addOn.editablePerVerification = true;
				} else if (addOn.key === 'sanctionsAndPepVerification') {
					addOn.isSelectable = true;
					addOn.isDetailsButtonRequired = true;
					addOn.editablePerVerification = true;
					addOn.matchThresholdRequired = true;
				}
				return addOn;
			});
		// No need to show current address proofing for client
		const clientAddOns = addOns.filter((addOn) => addOn.key !== 'currentAddressProofing');
		setTenantAddOns(clientAddOns);
	};

	const displayNotificatoinSuccess = (msg: string) => {
		dispatch(
			setNotification({
				message: msg,
				type: NOTIFICATION_STATES.success,
			})
		);
	};

	const displayNotificatoinError = (msg: string) => {
		dispatch(
			setNotification({
				message: msg,
				type: NOTIFICATION_STATES.error,
			})
		);
	};

	const updateDialogData = (type) => {
		const addOn = tenantAddOns.find((product) => type === product.key);
		setDialogData(addOn);
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>, type) => {
		const value = event.target.checked;
		setTenantAddOns((prev) => {
			const addOns = prev.map((addOn) => {
				if (type === addOn.key) {
					addOn.required = value;
					if (!value) {
						addOn.requiredOverridable = false;
					}
				}
				return addOn;
			});
			return addOns;
		});
	};

	const handleShowDetails = (type) => {
		updateDialogData(type);
		setOpenThresholdModal(true);
	};

	const handleAddButtonAction = (type) => {
		setTenantAddOns((prev) => {
			const addOns = prev.map((addOn) => {
				if (type === addOn.key) {
					addOn.required = true;
				}
				return addOn;
			});
			return addOns;
		});
	};

	const handleCancelButtonAction = (type) => {
		setTenantAddOns((prev) => {
			const addOns = prev.map((addOn) => {
				if (type === addOn.key) {
					addOn.required = false;
				}
				return addOn;
			});
			return addOns;
		});
	};

	const saveAddOns = () => {
		const data = cloneDeep(initialTenantSettingsData);
		tenantAddOns.forEach((addOn) => {
			data.features[addOn.key].required = addOn.required;

			if (addOn.key === 'businessVerification' || addOn.key === 'currentAddressProofing') {
				data.features[addOn.key].requiredOverridable = addOn.requiredOverridable;
			} else if (addOn.key === 'sanctionsAndPepVerification') {
				data.features[addOn.key].requiredOverridable = addOn.requiredOverridable;
				data.features[addOn.key].threshold = addOn.threshold;
			}
		});
		updateTenantAddOns(data);
	};

	const cancelAddOnsUpdate = () => {
		navigate(`account-details`);
	};

	const handleDialogSave = ({ modalFor, addOnOverridable, threshold }) => {
		setTenantAddOns((prev) => {
			const addOns = prev.map((addOn) => {
				if (modalFor === addOn.key) {
					addOn.requiredOverridable = addOnOverridable;

					if (addOn.key === 'sanctionsAndPepVerification') {
						addOn.threshold = threshold / 100;
					}
				}
				return addOn;
			});
			return addOns;
		});
		setOpenThresholdModal(false);
	};

	const handleDetailDialogClose = () => {
		setOpenThresholdModal(false);
	};

	return (
		<>
			<AddOns
				addOns={tenantAddOns}
				defaultCurrency={tenant?.currency || 'AUD'}
				disableAddOns={plan.code === TENANT_PLAN.PLAN_FREE}
				handleChange={handleChange}
				handleAddButtonAction={handleAddButtonAction}
				handleCancelButtonAction={handleCancelButtonAction}
				handleShowDetails={handleShowDetails}
				saveAddOns={saveAddOns}
				cancelAddOnsUpdate={cancelAddOnsUpdate}
			/>
			<DetailDialog
				open={openThresholdModal}
				type="addOns"
				checked={dialogData.required}
				editablePerVerification={dialogData.editablePerVerification}
				editableChecked={dialogData.requiredOverridable}
				matchThresholdRequired={dialogData.matchThresholdRequired}
				thresholdLabel={'Match threshold'}
				thresholdValue={Number(`${dialogData.threshold * 100}`)}
				modalFor={dialogData.key}
				title={dialogData.name}
				subTitle={dialogData.description}
				handleSave={handleDialogSave}
				handleClose={handleDetailDialogClose}
			/>
		</>
	);
};

export default AddOnsPage;
