import React, { useState, useEffect, useMemo } from 'react';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Box, Breadcrumbs, Typography } from '@mui/material';
import {
	AccountDetailsPage,
	AccountOverview,
	ProductConfigurationPage,
	AddOnsPage,
	FeaturesPage,
	BillingAndPaymentPage,
	PlanPage,
	PlansPage,
	ProductOverview,
	IntegrationPage,
	IDHealthCheckPage
} from './index';
import { getCookie } from 'shared_components/src/service/common.service';
import { TENANT_STATUS } from 'shared_components/src/service/models/tenant';
import { StatusMessage } from 'shared_components/src/components/tenants';
import { _getTenant } from '../../store/selectors';

const useStyles = makeStyles(() => ({
	tenantlayout: {
		'& .headerNavigation': {
			'& .MuiBreadcrumbs-li': {
				cursor: 'pointer',

				'&:hover': {
					color: '#5D5095',
					textDecoration: 'underline',
				},

				'& .MuiTypography-body1': {
					fontSize: '15px',
				},
			},

			'@media screen and (max-width: 600px)': {
				padding: '16px 0 0',
			},
		},
	},
}));

const TenantLayout = () => {
	const classes = useStyles();
	const navigate = useNavigate();
	const tenantData = _getTenant();
	const tenant = getCookie('tenant') || {};

	const curPath = window.location.pathname;
	const temp = curPath.split('/') || [];

	const [topNav, setTopNav] = useState([] as any);

	useEffect(() => {
		const comp = temp[temp?.length - 1];
		const index = temp.indexOf('kyc');
		const path = temp.slice(index + 1).join('/');
		const productOverview = { path: `tenant/${tenant}/product-overview`, name: 'Product Overview' };
		const accountOverview = { path: `tenant/${tenant}/account-overview`, name: 'Account Overview' };
		if (comp === 'account-details') {
			setTopNav((prev) => {
				const isTabPresent = prev.find((el) => el.name === 'Account Details');
				let tempNav = prev;
				if (!isTabPresent) {
					tempNav = [...prev, ...[accountOverview, { path, name: 'Account Details' }]];
				}
				return tempNav;
			});
		} else if (comp === 'billing-payment') {
			setTopNav((prev) => {
				const isTabPresent = prev.find((el) => el.name === 'Billing & Payment');
				let tempNav = prev;
				if (!isTabPresent) {
					tempNav = [...prev, ...[accountOverview, { path, name: 'Billing & Payment' }]];
				}
				return tempNav;
			});
		} else if (comp === 'product-configuration') {
			setTopNav((prev) => {
				const isTabPresent = prev.find((el) => el.name === 'Product Configuration');
				let tempNav = prev;
				if (!isTabPresent) {
					tempNav = [...prev, ...[productOverview, { path, name: 'Product Configuration' }]];
				}
				return tempNav;
			});
		} else if (comp === 'integration') {
			setTopNav((prev) => {
				const isTabPresent = prev.find((el) => el.name === 'Integration');
				let tempNav = prev;
				if (!isTabPresent) {
					tempNav = [...prev, ...[productOverview, { path, name: 'Integration' }]];
				}
				return tempNav;
			});
		} else if (comp === 'features') {
			setTopNav((prev) => {
				const isTabPresent = prev.find((el) => el.name === 'Features');
				let tempNav = prev;
				if (!isTabPresent) {
					tempNav = [...prev, ...[productOverview, { path, name: 'Features' }]];
				}
				return tempNav;
			});
		} else if (comp === 'add-ons') {
			setTopNav((prev) => {
				const isTabPresent = prev.find((el) => el.name === 'Add Ons');
				let tempNav = prev;
				if (!isTabPresent) {
					tempNav = [...prev, ...[productOverview, { path, name: 'Add Ons' }]];
				}
				return tempNav;
			});
		} else if (comp === 'plan') {
			setTopNav((prev) => {
				const isTabPresent = prev.find((el) => el.name === 'Plan');
				let tempNav = prev;
				if (!isTabPresent) {
					tempNav = [...prev, ...[productOverview, { path, name: 'Plan' }]];
				}
				return tempNav;
			});
		} else if (comp === 'change-plan') {
			setTopNav((prev) => {
				const isTabPresent = prev.find((el) => el.name === 'Changing Plan');
				let tempNav = prev;
				if (!isTabPresent) {
					const plan = { path: temp.slice(index + 1, temp.length - 1).join('/'), name: 'Plan' };
					tempNav = [...prev, ...[productOverview, plan, { path, name: 'Changing Plan' }]];
				}
				return tempNav;
			});
		}
	}, [curPath]);

	const showStatusMessage = useMemo(() => {
		return tenantData?.status === TENANT_STATUS.CANCELLED;
	}, [tenantData]);

	const statusMessageType = useMemo(() => {
		switch (tenantData?.status) {
			case TENANT_STATUS.CANCELLED:
				return 'ERROR';
			default:
				return 'INFO';
		}
	}, [tenantData]);

	const renderPage = () => {
		const comp = temp[temp?.length - 1];
		if (comp === 'product-overview') {
			return <ProductOverview />;
		} else if (comp === 'account-overview') {
			return <AccountOverview />;
		} else if (comp === 'account-details') {
			return <AccountDetailsPage />;
		} else if (comp === 'product-configuration') {
			return <ProductConfigurationPage />;
		} else if (comp === 'integration') {
			return <IntegrationPage />;
		} else if (comp === 'features') {
			return <FeaturesPage />;
		} else if (comp === 'add-ons') {
			return <AddOnsPage />;
		} else if (comp === 'billing-payment') {
			return <BillingAndPaymentPage />;
		} else if (comp === 'plan') {
			return <PlanPage />;
		} else if (comp === 'change-plan') {
			return <PlansPage />;
		} else if (comp == 'id-health-check') {
			return <IDHealthCheckPage />;
		}
	};

	const statusMessageElement = () => {
		return (
			<StatusMessage type={statusMessageType}>
				<div>
					<div>
						Please “Subscribe Again” on{' '}
						<span
							style={{
								color: '#2160F6',
								cursor: 'pointer',
								textDecoration: 'underline',
							}}
							onClick={() => handleNavigation(`tenant/${tenant}/account-details`)}
						>
							“Account Details”
						</span>{' '}
						to use all features.
					</div>
				</div>
			</StatusMessage>
		);
	};

	const handleNavigation = (page) => {
		navigate(`/${page}`);
	};

	return (
		<Box className={clsx(classes.tenantlayout)}>
			{showStatusMessage && statusMessageElement()}
			{/* Might need to add back in future
			<Breadcrumbs aria-label="breadcrumb" style={{ color: '#70708F' }} className="headerNavigation">
				{topNav.map((nav) => (
					<Typography key={nav.path} color="inherit" onClick={() => handleNavigation(nav.path)}>
						{nav.name}
					</Typography>
				))}
			</Breadcrumbs> */}
			{renderPage()}
		</Box>
	);
};

export default TenantLayout;
