import { EmptyPage } from 'shared_components/src/components/common';
import { AUTH_ROLE } from 'shared_components/src/common/constants';

import {
	AccountSettings,
	IDPResponse,
	LoginPage,
	PlansPage,
	RequestLaunch,
	SubscriptionManagement,
	TenantLayout,
	UserDetailPage,
	UsersPage,
	VerificationDetailPage,
	VerificationPage,
	VerificationDetail,
} from '../../pages';

const routes = [
	// No required authentication
	{
		path: '/',
		component: EmptyPage,
		hasAuth: false,
		exact: true,
		hasFlag: false,
		flag: '',
	},
	{
		path: '/login',
		component: LoginPage,
		hasAuth: false,
		exact: true,
		hasFlag: false,
		flag: '',
	},
	{
		path: '/launch',
		component: RequestLaunch,
		hasAuth: false,
		exact: false,
		hasFlag: false,
		flag: '',
	},
	{
		path: '/idp/sso',
		component: EmptyPage,
		hasAuth: false,
		exact: true,
		hasFlag: false,
		flag: '',
	},
	{
		path: '/idp/response',
		component: IDPResponse,
		hasAuth: false,
		exact: true,
		hasFlag: false,
		flag: '',
	},
	// Subscripton
	{
		path: '/subscription-management',
		component: SubscriptionManagement,
		hasAuth: true,
		exact: true,
		hasFlag: false,
		flag: '',
	},
	{
		path: '/tenant/:tenantAlias/product-overview',
		component: TenantLayout,
		hasAuth: true,
		exact: true,
		hasFlag: false,
		flag: '',
		roles: [AUTH_ROLE.client, AUTH_ROLE.agent],
	},
	// Verification
	{
		path: '/tenant/:tenantAlias/verification',
		component: VerificationPage,
		hasAuth: true,
		exact: true,
		hasFlag: false,
		flag: '',
		roles: [AUTH_ROLE.client, AUTH_ROLE.agent],
	},
	{
		path: '/tenant/:tenantAlias/verification-detail/:verId',
		component: VerificationDetail,
		hasAuth: true,
		exact: true,
		hasFlag: false,
		flag: '',
		roles: [AUTH_ROLE.client, AUTH_ROLE.agent],
	},
	// User
	{
		path: '/tenant/:tenantAlias/view-users',
		component: UsersPage,
		hasAuth: true,
		exact: true,
		hasFlag: false,
		flag: '',
		roles: [AUTH_ROLE.client],
	},
	{
		path: '/tenant/:alias/user-detail/:type/:verId',
		component: UserDetailPage,
		hasAuth: true,
		exact: true,
		hasFlag: false,
		flag: '',
		roles: [AUTH_ROLE.client],
	},
	// Tenants
	{
		path: '/tenant/:tenantAlias/account-overview',
		component: TenantLayout,
		hasAuth: true,
		exact: true,
		hasFlag: false,
		flag: '',
		roles: [AUTH_ROLE.client],
	},
	{
		path: '/tenant/:tenantAlias/account-details',
		component: TenantLayout,
		hasAuth: true,
		exact: true,
		hasFlag: true,
		flag: 'Onboarding-client-v1',
		roles: [AUTH_ROLE.client],
	},
	{
		path: '/tenant/:tenantAlias/product-configuration',
		component: TenantLayout,
		hasAuth: true,
		exact: true,
		hasFlag: true,
		flag: 'Onboarding-client-v2',
		roles: [AUTH_ROLE.client],
	},
	{
		path: '/tenant/:tenantAlias/integration',
		component: TenantLayout,
		hasAuth: true,
		exact: true,
		hasFlag: false,
		flag: '',
		roles: [AUTH_ROLE.client],
	},
	{
		path: '/tenant/:tenantAlias/features',
		component: TenantLayout,
		hasAuth: true,
		exact: true,
		hasFlag: true,
		flag: 'Onboarding-client-v2',
		roles: [AUTH_ROLE.client],
	},
	{
		path: '/tenant/:tenantAlias/add-ons',
		component: TenantLayout,
		hasAuth: true,
		exact: true,
		hasFlag: true,
		flag: 'Onboarding-client-v2',
		roles: [AUTH_ROLE.client],
	},
	{
		path: '/tenant/:tenantAlias/billing-payment',
		component: TenantLayout,
		hasAuth: true,
		exact: true,
		hasFlag: true,
		flag: 'Onboarding-client-v1',
		roles: [AUTH_ROLE.client],
	},
	{
		path: '/tenant/:tenantAlias/plan',
		component: TenantLayout,
		hasAuth: true,
		exact: true,
		hasFlag: true,
		flag: 'Onboarding-client-v1',
		roles: [AUTH_ROLE.client],
	},
	{
		path: '/tenant/:tenantAlias/plan/change-plan',
		component: TenantLayout,
		hasAuth: true,
		exact: true,
		hasFlag: true,
		flag: 'Onboarding-client-v1',
		roles: [AUTH_ROLE.client],
	},
	{
		path: '/tenant/:tenantAlias/plan/cancel-subscription',
		component: PlansPage,
		hasAuth: true,
		exact: true,
		hasFlag: true,
		flag: 'cancel-subscription',
		roles: [AUTH_ROLE.client],
	},
	{
		path: '/tenant/:tenantAlias/plan/subscribe',
		component: PlansPage,
		hasAuth: true,
		exact: true,
		hasFlag: true,
		flag: 'cancel-subscription',
		roles: [AUTH_ROLE.client],
	},
	// Account Settings
	{
		path: '/account-settings',
		component: AccountSettings,
		hasAuth: true,
		exact: true,
		hasFlag: false,
		flag: '',
	},
	{
		path: '/tenant/:tenantAlias/id-health-check',
		component: TenantLayout,
		hasAuth: true,
		exact: true,
		hasFlag: true,
		flag: 'Onboarding-client-v1',
		roles: [AUTH_ROLE.client],
	}
];

export default routes;
